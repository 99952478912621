import React, {useState, useEffect} from 'react';
import {Route, Switch, useLocation, useHistory} from 'react-router';
import EarningsPage from '../../pages/EarningsPage/EarningsPage';
import Earnings from '../../pages/Earnings/Earnings';
import Sidebar from '../../components/Sidebar/Sidebar';
import useWindowWidth from '../../hooks/useWindowWidth';
import MobileSidebar from '../../components/MobileSidebar/MobileSidebar';
import Header from '../../components/Header/Header';
import Overview from '../../pages/Overview/Overview';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import layout from '../../style/global.module.scss';
import SocialStats from '../../pages/SocialStats/SocialStats';
import {selectMe, setMe, setNavigatedFromSettings} from '../stores/userSlicer';
import useRequest from '../API/request';
import Music from '../../pages/Music/Music';
import {Redirect} from 'react-router-dom';
import UploadMusic from '../../pages/UploadMusic/UploadMusic';
import Onboarding from '../../pages/Onboarding/Onboarding';
import * as bcrypt from 'bcryptjs';
import PageSpinner from '../../components/PageSpinner/PageSpinner';
import Tools from '../../pages/Tools/Tools';
import Settings from '../../pages/Settings/Settings';
import Help from '../../pages/Help/Help';
import Modal from '../../components/Modal/Modal';

const LoggedSwitch = () => {
  const mobile = useWindowWidth();
  const me = selectMe(useAppSelector(store.getState)).me;
  const updateYtRequest = useRequest({url: '/youtube', method: 'PUT'});
  const updateIgRequest = useRequest({url: '/instagram', method: 'PUT'});
  const meRequest = useRequest({url: '/auth/me', method: 'GET'});
  const totalEngagementUpdateRequest = useRequest({url: '/platforms/checkUpdates', method: 'PUT'});
  const [showSpinner, setShowSpinner] = useState(false);

  const patchAccount = async ()=>{
    if (window.location.href.includes('ytId')) {
      setShowSpinner(true);
      setTimeout(()=>{
        setShowSpinner(false);
      }, 2000);
      const location = window.location.href.split('?ytId=');
      let ytId = location[1];
      if (ytId.includes('#')) ytId = ytId.substring(0, ytId.length-1);
      const userId = me.id;
      // patch with user

      await updateYtRequest.doRequest({id: ytId, userId, isFirstUpdate: true});
      await totalEngagementUpdateRequest.doRequest();
      const newMe = await meRequest.doRequest();
      if (newMe) store.dispatch(setMe({me: newMe, loginStatus: 'LOGGED_IN'}));


      const navigateToHash = window.localStorage.getItem('navigateTo');
      if (navigateToHash && bcrypt.compare('onboarding', navigateToHash) && me.isFirstLogin) window.location.assign('/onboarding');
      else window.history.pushState(null, '', '/');
    }
    if (window.location.href.includes('igId')) {
      setShowSpinner(true);
      setTimeout(()=>{
        setShowSpinner(false);
      }, 2000);
      const location = window.location.href.split('?igId=');
      let igId = location[1];
      if (igId.charAt(igId.length-1) === '_') igId = igId.substring(0, igId.length-1);
      if (igId.includes('#')) igId = igId.substring(0, igId.length-1); // instagram adds a _ to the end?
      const userId = me.id;
      // patch with user
      await updateIgRequest.doRequest({id: igId, userId, isFirstUpdate: true});

      // to refresh follower count
      await totalEngagementUpdateRequest.doRequest();
      const newMe = await meRequest.doRequest();
      if (newMe) store.dispatch(setMe({me: newMe, loginStatus: 'LOGGED_IN'}));

      const navigateToHash = window.localStorage.getItem('navigateTo');
      if (navigateToHash && bcrypt.compare('onboarding', navigateToHash) && me.isFirstLogin) window.location.assign('/onboarding');
      else window.history.pushState(null, '', '/');
    }
  };

  // const checkNavigatingFromSettings = (path: string)=>{
  //   if (path === '/settings') setNavigatedFromSettings({navigatedFromSettings: true});
  // };

  useEffect(()=>{
    patchAccount();
  }, [window.location]);


  return (
  showSpinner ? <PageSpinner />:
  <div style={{display: mobile < 890 ? 'unset' : 'flex'}}>
    {mobile < 890 ? <MobileSidebar /> : <Sidebar />}
    <div style={{display: 'flex'}}>
      <div className={window.location.pathname !== '/onboarding' ? layout.main : layout.onboarding}>
        <Header />
        <div style={{display: 'flex',
          ...(['/onboarding', '/earnings'].includes(window.location.pathname) && {justifyContent: 'center'}),
        }}>
          <div style={{width: '100%'}}>
            <Switch>
              {/* <Route path="/onboarding" component={Onboarding} /> */}
              {/* <Route path="/overview" component={Overview} /> */}
              {/* <Route path="/earnings-old" component={EarningsPage} /> */}
              {/* <Route path="/earnings" component={Earnings} /> */}
              {/* <Route path="/stats" component={SocialStats} /> */}
              <Route exact path="/earnings" component={Earnings} />
              <Route exact path="/" component={Earnings} />
              <Route exact path="/audio-music" component={Music} />
              <Route path="/audio-music/upload" component={UploadMusic} />
              {/* <Route path="/tools" component={Tools} /> */}
              <Route path="/settings" component={Settings} />
              <Route path="/help" component={Help} />
              <Redirect to="/"></Redirect>
              {/* <Redirect exact path="/audio-music/*" to="/audio-music"></Redirect> */}
            </Switch>
          </div>
        </div>
      </div>

    </div>

  </div>
  );
};

export default LoggedSwitch;
