import React, {useEffect, useState} from 'react';
import moment from 'moment';
import style from './uploadMusic.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import MusicNoteSimple from '../../assets/images/MusicNoteSimple.svg';
import dotedLine from '../../assets/images/dotedLine.svg';
import album from '../../assets/images/Album.svg';
import submitIcon from '../../assets/images/submitIcon.svg';
import note from '../../assets/images/Note.svg';
import more from '../../assets/images/More.svg';
import {Route, Switch, useHistory} from 'react-router-dom';
import MusicTrack from './track/MusicTrack';
import MusicAlbum from './album/MusicAlbum';
import MusicSubmit from './submit/MusicSubmit';
import MusicStatus from './status/MusicStatus';
import {QuestionsOne, QuestionsThree, QuestionsTwo} from './questions/questions';
import SubmitMusic from '../SubmitMusic/SubmitMusic';

export type SubmitStates = 'TRACK' | 'ALBUM' | 'SUBMIT' | 'STATUS' | 'QUESTIONS1' | 'QUESTIONS2' | 'QUESTIONS3'

const UploadMusic = () => {
  // upload logic goes here

  const [view, setView] = useState<SubmitStates>('TRACK' as SubmitStates );
  const [musicData, setMusicData] = useState<any>({title: '', artists: '', consumerReleaseDate: moment().add(1, 'd'), consumerReleaseTime: moment().format('HH:mm:ss'), originalReleaseDate: moment(), genre: 'World', subGenre: 'Arabic', artwork: '', song: '', duration: 0, previouslyReleased: 'No', uploadedOnYoutube: 'No', youtubeLink: '', explicit_content: 'Clean', language: 'Arabic', agree: false});
  const size = useWindowWidth(); // <= 365;
  const mobile = useWindowWidth() <= 890;
  const history = useHistory();

  const extended = false;

  const getDerivedComponent = () => {
      switch (view) {
          case 'TRACK':
            return <MusicTrack setView={setView} musicData={musicData} setMusicData={setMusicData} />;
          case 'SUBMIT':
            return <MusicSubmit setView={setView} musicData={musicData} setMusicData={setMusicData} />;
          default:
            return null;        
      }
  };

  return (
    <div className={style.main} style={{...(mobile && {paddingTop: 0})}}>
      {view !== 'STATUS' && <div className={style.progressSection}>
        <div id={'TRACK'} className={`${view === 'TRACK'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
          setView('TRACK');
        //   history.push('/audio-music/upload/track');
        }}>
          <img src={MusicNoteSimple} className={`${style.progressSection__icon} ${style.filter}`}/>
        </div>
        <img src={dotedLine} className={style.progressSection__line}/>
        {/* <div id={'ALBUM'} className={`${state === 'ALBUM'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
          setState('ALBUM');
          history.push('/audio-music/upload/album');
        }}>
          <img src={album} className={`${style.progressSection__icon}`}/>
        </div> */}
        {/* <img src={dotedLine} className={style.progressSection__line}/> */}
        <div id={'SUBMIT'} className={`${view === 'SUBMIT'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
         Object.keys(musicData).some((el: any) => musicData[el] == '') ? '' : setView('SUBMIT');
        //   history.push('/audio-music/upload/submit');
        }}>
          <img src={submitIcon} className={style.progressSection__icon}/>
        </div>
        {/* Aditional screen */}
        {extended && <>
          <img src={dotedLine} className={style.progressSection__line}/>
          <div id={'QUESTIONS1'} className={`${view === 'QUESTIONS1'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
            setView('QUESTIONS1');
            history.push('/audio-music/upload/questions1');
          }}>
            <img src={submitIcon} className={style.progressSection__icon}/>
          </div>
          <img src={dotedLine} className={style.progressSection__line}/>
          <div id={'QUESTIONS2'} className={`${view === 'QUESTIONS2'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
            setView('QUESTIONS2');
            history.push('/audio-music/upload/questions2');
          }}>
            <img src={note} className={`${style.progressSection__icon} ${style.filter}`}/>
          </div>
          <img src={dotedLine} className={style.progressSection__line}/>
          <div id={'QUESTIONS3'} className={`${view === 'QUESTIONS3'? style.selected : ''} ${style.filter__container}`} onClick={(e) => {
            setView('QUESTIONS3');
            history.push('/audio-music/upload/questions3');
          }}>
            <img src={more} className={`${style.progressSection__icon} ${style.filter}`}/>
          </div></>}
      </div>}
      <div>
        {
            getDerivedComponent()
        }
      </div>
    </div>
  );
};

export default UploadMusic;

