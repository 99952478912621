import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {DARKTHEME_BLACK, DARKTHEME_GREY_1} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import useRequest from '../../features/API/request';
import {reduceNumberOfUnreadNotifications, setNotifications} from '../../features/stores/notificationSlicer';
import {selectMe} from '../../features/stores/userSlicer';
import Button from '../Button/Button';
import OutlineButton from '../OutlineButton/OutlineButton';
import styles from './Notification.module.scss';

type NotificationProps = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  sent: string;
  displayType: string;
  isSeen: boolean;
  style?: any;
}

const Notification = ({title, description, icon, sent, isSeen, id, displayType, style}:NotificationProps)=>{
  const [time, setTime] = useState('');
  const [seen, setSeen] = useState(isSeen);
  const updateSeen = useRequest({url: '/notifications', method: 'PUT'});
  const me = selectMe(useAppSelector(store.getState)).me;
  const formatTime = () => {
    const hours = moment().diff(moment(sent), 'hours');
    if (hours > 24) {
      const days = moment().diff(moment(sent), 'days');
      if (days > 7) {
        const weeks = moment().diff(moment(sent), 'weeks');
        if (weeks > 52) {
          const years = moment().diff(moment(sent), 'years');
          setTime(years + 'y');
        } else setTime(weeks + 'w');
      } else setTime(days + 'd');
    } else setTime(hours + 'h');
  };

  const update = async ()=>{
    const res = await updateSeen.doRequest({
      id: id,
      receiver_id: me.id,
      isSeen: true,
    });
    // update notification number
    store.dispatch(reduceNumberOfUnreadNotifications());
  };

  useEffect(() => {
    formatTime();
  }, []);

  return (<div className={`${styles.container} notificationStyle`} style={style}>
    <div className={styles.top}>
      <div className={styles.top__icon}>
        {icon ?
        <img src={icon} style={{width: '48px', height: '48px', borderRadius: '50%', ...(seen && {opacity: 0.5})}}/>:<div className={styles.placeholderIcon}></div>}
      </div>

      <div className={styles.top__timestampcontainer} style={{...seen && {opacity: 0.4}}}>
        <div className={`${styles.top__title} header`}>{title}</div>
        <div className={styles.top__text}>
          <div className={styles.top__type}>{displayType}</div>
          <div>{time}</div>
        </div>
      </div>
    </div>
    <div className={styles.middle} style={{...seen && {opacity: 0.4}}}>
      <div className={`${styles.middle__text} notificationText`}>{description}</div>
      <div>
        <OutlineButton disable={isSeen} text={'Mark read'}
          style={{padding: '6px', fontSize: '10px', boxSizing: 'border-box',
          }} onClick={()=>{
            update();
            setSeen(true);
          }}></OutlineButton> </div>
    </div>
  </div>);
};

export default Notification;
