import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface NotificationState {
    isNotificationBarOpen: boolean;
    notificationList: Array<any> | null;
    numberOfUnreadNotifications: number;
}

const initialState: NotificationState = {
  isNotificationBarOpen: false,
  notificationList: null,
  numberOfUnreadNotifications: 0,
};

const getNotificationNumber = (notifications: Array<any>)=>{
  let count = 0;
  notifications.map((notification) =>{
    if (!notification.isSeen.isSeen) count++;
  });
  return count;
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleOpen: (state, action: PayloadAction<any>) => {
      state.isNotificationBarOpen = action.payload.isNotificationBarOpen;
    },
    setNotifications: (state, action:PayloadAction<any>)=>{
      state.notificationList = action.payload.notificationList;
      state.numberOfUnreadNotifications = getNotificationNumber(action.payload.notificationList);
    },
    addNotification: (state, action:PayloadAction<any>)=>{
      if (state.notificationList) {
        const current = state.notificationList;
        state.notificationList = [action.payload.notification, ...current];
        // number
        if (!action.payload.notification.isSeen.isSeen) state.numberOfUnreadNotifications += 1;
      }
    },
    reduceNumberOfUnreadNotifications: (state)=>{
      if (state.numberOfUnreadNotifications !== 0) state.numberOfUnreadNotifications -= 1;
    },
  },
});

export const {toggleOpen, setNotifications, addNotification, reduceNumberOfUnreadNotifications} = notificationSlice.actions;

export const selectIsNotificationBarOpen = (state: RootState) => state.notifications;
export const selectNotifications = (state: RootState) => state.notifications.notificationList;
export const selectNotificationCount = (state: RootState) => state.notifications.numberOfUnreadNotifications;

export default notificationSlice.reducer;
