import React, {useState, useEffect} from 'react';
import style from './DenseCard.module.scss';
import arrowUp from '../../assets/images/up-arrow2.svg';
import arrowDown from '../../assets/images/down-arrow.svg';
import {ACTIVERED, GREEN_COLOR, GREY_COLOR_11, GREY_COLOR_9, LIGHTGREYCOLOR, RED_COLOR} from '../../constants/colors';
import youtubeIcon from '../../assets/images/01-Youtube-Red.png';
import spotify from '../../assets/images/18- Spotify.png';
import deezer from '../../assets/images/16-Deezer-White.png';
import facebook from '../../assets/images/05-Facebook.png';
import tiktok from '../../assets/images/03-Tiktok.png';
import amazonUnlimited from '../../assets/images/06-AmazonMusic.png';
import tim from '../../assets/images/07-Tim-white.png';
import ytMusic from '../../assets/images/02-YT Music-Red.png';
import napster from '../../assets/images/08-Napster.png';
import anghami from '../../assets/images/04-Anghami.png';
import joox from '../../assets/images/09-Joox.png';
import tidal from '../../assets/images/07-Tim.png';
import saavn from '../../assets/images/11-JioSavaan-White.png';
import imusica from '../../assets/images/12-imusica.png';
import iheart from '../../assets/images/13-IHeart.png';
import appleMusic from '../../assets/images/14-Apple Music.png';
import pandora from '../../assets/images/15-Pandora.png';
import itunes from '../../assets/images/17-ITunes.png';
import lineMusic from '../../assets/images/19-LineMusic-Green.png';
import yandex from '../../assets/images/20-Yandex.png';
import soundCloud from '../../assets/images/21-Soundcloud-Orange.png';
import soundtrack from '../../assets/images/22-SoundTrack Your Brand-White.png';
import music from '../../assets/images/Alfan_Music_Logo_Design.svg';
import instagram from '../../assets/images/Instagram_logo_2016.svg';
import ultimateMusicChina from '../../assets/images/ultimate-music-china.png';

import deezerDark from '../../assets/images/16-DeezerDark.png';
import iheartDark from '../../assets/images/13-IHeart-Black.png';
import imusicaDark from '../../assets/images/12-IMusica-Black.png';
import saavnDark from '../../assets/images/11-JioSavaan.png';
import lineMusicDark from '../../assets/images/19-LineMusic-Black.png';
import yandexDark from '../../assets/images/20-Yandex-Black.png';
import soundCloudDark from '../../assets/images/21-Soundcloud-Black.png';
import soundtrackDark from '../../assets/images/22-SoundTrack Your Brand-Black.png';
import snapchat from '../../assets/images/Snapchat.png';
import nono from '../../assets/images/Nono_Live_Logo.png';
import souq from '../../assets/images/Alfan-Souq-Logo-Design.png';
import lightMoney from '../../assets/images/deal.white.svg';
import darkMoney from '../../assets/images/deal.black.svg';
import twitter from '../../assets/images/TwitterLogoBlue.svg';

import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {THEMES} from '../../constants/types';
import useRequest from '../../features/API/request';
import {parseDailyData, parseMonthlyData} from '../../features/Parsers/YoutubeParsers';
import WhiteRoundButton from '../WhiteRoundButton/WhiteRoundButton';


export const getDenseCardIcons = (sourceName: string, theme = THEMES.LIGHT) => {
  const icons = theme === THEMES.LIGHT ? {
    'Instagram': instagram,
    'YouTube': youtubeIcon,
    'Youtube': youtubeIcon,
    'YouTube CSV': youtubeIcon,
    'Spotify': spotify,
    'TikTok': tiktok,
    "TikTok Inc.": tiktok,
    'Deezer': deezer,
    'Facebook': facebook,
    'Amazon Unlimited': amazonUnlimited,
    'Youtube Ad Supported': youtubeIcon,
    'TIM Italia': tim,
    'Youtube Music': ytMusic,
    'Napster': napster,
    'Anghami': anghami,
    'Joox': joox,
    'Tidal': tidal,
    'JioSaavn': saavn,
    'iMusica': imusica,
    'iHeart Radio US': iheart,
    'Apple Music': appleMusic,
    'Pandora': pandora,
    'iTunes': itunes,
    'Soundcloud': soundCloud,
    'LINE Music': lineMusic,
    'Yandex': yandex,
    'Yandex Music': yandex,
    'Soundtrack Your Brand': soundtrack,
    'Snapchat': snapchat,
    'Nono Live': nono,
    'Alfan Souq': souq,
    'Alfan Music': music,
    'Deals': darkMoney,
    'Twitter': twitter,
    'Ultimate Music China': ultimateMusicChina,
  } : {
    'Instagram': instagram,
    'YouTube': youtubeIcon,
    'Youtube': youtubeIcon,
    'YouTube CSV': youtubeIcon,
    'Spotify': spotify,
    'TikTok': tiktok,
    'TikTok Inc.': tiktok,
    'Deezer': deezerDark,
    'Facebook': facebook,
    'Amazon Unlimited': amazonUnlimited,
    'Youtube Ad Supported': youtubeIcon,
    'TIM Italia': tim,
    'Youtube Music': ytMusic,
    'Napster': napster,
    'Anghami': anghami,
    'Joox': joox,
    'Tidal': tidal,
    'JioSaavn': saavnDark,
    'iMusica': imusicaDark,
    'iHeart Radio US': iheartDark,
    'Apple Music': appleMusic,
    'Pandora': pandora,
    'iTunes': itunes,
    'Soundcloud': soundCloudDark,
    'LINE Music': lineMusicDark,
    'Yandex': yandexDark,
    'Yandex Music': yandexDark,
    'Soundtrack Your Brand': soundtrackDark,
    'Snapchat': snapchat,
    'Nono Live': nono,
    'Alfan Souq': souq,
    'Alfan Music': music,
    'Deals': lightMoney,
    'Twitter': twitter,
    'Ultimate Music China': ultimateMusicChina,
  } as any;


  return icons[sourceName];
};

type DenseCardProps = {
  styles?: object;
  icon: string;
  platform: string;
  amount: number;
  amountMonthBefore?: number;
  total: number;
  type: string;
  hidePct?: boolean;
  numberOfSources?: number;
  sources?: any;
  showSources?: boolean;
  setShowSources?: Function;
  setEarnings?: Function;
  startDate?: string;
  endDate?: string;
  resetDataFunction?: Function;
  disableChildrenClick?: boolean;
}

const Card = ({hidePct, styles, icon, platform, numberOfSources, me, setEarnings, startDate, endDate,
  totalPercentage, displayAmount, sourceData, setShowSources, showSources, onClick, isSelected, disableChildrenClick = false}:any) => {
  const dailyYoutubeEarningsReq = useRequest({method: 'POST', url: '/platforms/finances/daily?youtubeOnly=true'});
  const dailyEarningsReq = useRequest({method: 'POST', url: '/platforms/finances/daily'});

  const fetchYoutubeData = async (options: any)=>{
    const {startDate, endDate, channelId, youtubeOnly} = options;
    let daily;

    if (youtubeOnly) {
      daily = await dailyYoutubeEarningsReq.doRequest({
        startDate,
        endDate,
        channelId,
      }) as any;
    } else {
      daily = await dailyEarningsReq.doRequest({
        startDate,
        endDate,
      }) as any;
    }
    if (daily && daily.rows.length) {
      const dailyData = parseDailyData(daily);
      setEarnings(dailyData);
      return dailyData;
    }
    return [];
  };


  return (
    <div className={`${style.container} card`}
      style={{
        ...(me.theme === THEMES.LIGHT && {borderColor: GREY_COLOR_11}),
        ...styles,
        ...((numberOfSources || sourceData) && {cursor: 'pointer'}),
      }}
      onClick={async ()=>{
        if (sourceData && !disableChildrenClick) {
          // fetch data for this particular youtube channel
          await fetchYoutubeData({startDate, endDate, channelId: sourceData.channelId, youtubeOnly: true});
        }
        if (setShowSources) {
          return setShowSources(true);
        }
        if (onClick) onClick();
      }}
    >
      <div className={style.titlePart}>
        <div className={style.iconPart}>
          <img className={style.icon} src={icon} />
          {sourceData ?
          <div className={`${style.platform} noselect`}>{sourceData.channelName || sourceData.platform}</div> :
          <div className={`${style.platform} noselect`}>
            {platform} {numberOfSources ? <span>({numberOfSources})</span> : ''}
          </div>}
        </div>
        {!hidePct && <div className={`${style.totalPercentage} header`}>{totalPercentage}</div>}
      </div>
      <div className={`${style.total} header`} style={{...(hidePct && {fontSize: '29px', margin: 0})}}>{displayAmount}</div>
      {/* {!hidePct &&<div className={style.bottomPart}>
  <div className={style.previousPercentage} style={{color: (earningPercentage >= 0? GREEN_COLOR : RED_COLOR)}}>
    {earningPercentage+'%'}
    <img src={(earningPercentage >= 0 ? arrowUp : arrowDown)} className={style.arrow} style={{...(earningPercentage < 0 && {transform: 'rotate(180deg)'})}} />
    <div className={style.lastMonth} style={{...(me.theme === THEMES.LIGHT && {color: LIGHTGREYCOLOR})}} >Last Month</div>
  </div>

</div>} */}
    </div >);
};

const DenseCard = ({hidePct, styles, icon, platform, amount, amountMonthBefore, total, type, numberOfSources,
  sources, showSources, setShowSources, setEarnings, startDate, endDate, resetDataFunction, disableChildrenClick = false}: DenseCardProps) => {
  let earningPercentage = 0;
  let totalPercentage = '';

  const displayAmount = type === 'earnings' ? '$' + amount?.toLocaleString() : amount?.toLocaleString();
  if (amount && amountMonthBefore) {
    earningPercentage = (isFinite(Math.round((Math.abs(amount - amountMonthBefore) / amountMonthBefore) * 100)) ? Math.round((amount - amountMonthBefore) / amountMonthBefore * 100) : 0);
    totalPercentage = isFinite(Math.round((amount / total) * 100) || 0) ? (Math.round((amount / total) * 100) || 0) + '%' : '0%';
  }// , ...(hidePct && {padding: '17px 18.5px 0px 18.5px'})

  const me = selectMe(useAppSelector(store.getState)).me;
  const [selectedCard, setSelectedCard] = useState('');

  useEffect(()=>{
    setSelectedCard('');
  }, [startDate, endDate]);

  return (
    <>
      <Card setEarnings={setEarnings} hidePct={hidePct}
        styles={{...styles, ...(showSources && numberOfSources && selectedCard === '' && {border: '1px solid ' + ACTIVERED})}} icon={icon} platform={platform} setShowSources={setShowSources}
        showSources={showSources} numberOfSources={numberOfSources} me={me} totalPercentage={totalPercentage} displayAmount={displayAmount}
        onClick={()=> !disableChildrenClick && setSelectedCard('')} disableChildrenClick = {disableChildrenClick}/>
      {showSources && sources.map((incomeSource:any, index:number) =>
        <Card startDate={startDate} endDate={endDate} setEarnings={setEarnings} key={index+platform} hidePct={hidePct}
          styles={{...styles, ...(selectedCard === index+platform && {border: '1px solid ' + ACTIVERED})}}
          icon={icon} platform={platform} me={me} onClick={()=> !disableChildrenClick && setSelectedCard(index+platform)}
          isSelected={selectedCard === index+platform}
          totalPercentage={totalPercentage} displayAmount={incomeSource?.total && Number(incomeSource.total).toLocaleString() || incomeSource?.amount && Number(incomeSource.amount).toLocaleString()} sourceData={incomeSource}
          disableChildrenClick = {disableChildrenClick}/>,
      )}
      {showSources && <WhiteRoundButton style={{fontSize: '20px'}} onClick={()=>{
        setSelectedCard('');
        if (resetDataFunction) resetDataFunction();
        if (setShowSources) setShowSources(false);
      }}>&#215;</WhiteRoundButton>}
    </>);
};

export default DenseCard;
