import React, {useEffect} from 'react';
import moment from 'moment';
import {toast} from 'react-toastify';
import musicTrackStyle from './musicSubmit.module.scss';
import TextField from '../../../components/TextField/TextField';
import addPhotoDark from '../../../assets/images/addPhotoDark.svg';
import addSongDark from '../../../assets/images/addSongDark.svg';
import MusicCover from '../../../components/MusicCover/MusicCover';

import sampleImage from '../../../assets/images/stockPhoto1.jpg';
import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import SuccessModal from '../../../components/MusicSubmitSuccess/successModal';
import {getSongCategory, secondsToHms} from '../../../features/Helper/genericHelper';
import {useRequestMusic} from '../../../features/API/request';
import Loader from 'react-loader-spinner';
import Modal from '../../../components/Modal/Modal';
import CheckBox from '../../../components/CheckBox/CheckBox';

type MusicProps = {
  setView: React.Dispatch<React.SetStateAction<any>>;
  setMusicData: React.Dispatch<React.SetStateAction<any>>;
  musicData: any;
};

const MusicSubmit = (props: MusicProps) => {
  const history = useHistory();
  const [songType, setSongType] = useState<'ALBUM' | 'SINGLE' | 'EP'>('SINGLE');
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const submitMusicHook = useRequestMusic({method: 'POST', url: '/music'});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {musicData, setMusicData} = props;
  const back = () => {
    props.setView('TRACK');
  };
  const submitSong = async () => {
    try {
      const payload: any = {
        "title": musicData.title,
        "song_url": musicData.song,
        "artists": musicData.artists,
        "consumer_release_datetime": moment(`${moment(musicData.consumerReleaseDate).format('YYYY-MM-DD')} ${moment(musicData.consumerReleaseTime, 'HH:mm:ss').format('HH:mm')}`).utc().format('YYYY-MM-DD HH:mm'),
        "genre": musicData.genre,
        "sub_genre": musicData.subGenre,
        "contributor": musicData.artists,
        "artwork": musicData.artwork,
        "is_released_public": false,
        "content_type": musicData.explicit_content,
        "track_language": musicData.language,
      };
      if (musicData.previouslyReleased == 'Yes') {
        payload.original_release_date = moment(musicData.originalReleaseDate).format('YYYY-MM-DD');
        if (musicData.uploadedOnYoutube == 'Yes') {
          payload.is_released_public = true;
          payload.youtube_url = musicData.youtubeLink;
        }
      }
      await submitMusicHook.doRequest(payload);
    } catch (error) {
      console.log('something went wrong while submitting music');
    }
  };
  const modalBack = () => {
    history.push('/audio-music/upload/status');
  };
  const modalForward = () => {
    history.push('/');
  };
  useEffect(()=>{
    const type: any = getSongCategory(musicData.duration);
    setSongType(type);
  }, [musicData.duration]);
  useEffect(()=>{
    if (submitMusicHook?.data?.id) {
      setSuccessModal(true);
    }
  }, [submitMusicHook.data]);
  useEffect(()=>{
    if (submitMusicHook.hasError) {
      toast.error(submitMusicHook?.error?.message || 'Something went wrong');
    }
  }, [submitMusicHook.hasError]);
  return (
    <div className={musicTrackStyle.container}>
      <div className={musicTrackStyle.header}>
        Final Review
        <span>Review what you entered</span>
      </div>
      <div className={musicTrackStyle.main}>
        <MusicCover
          type={'DETAILED'}
          variety={songType}
          image={musicData.artwork || sampleImage}
          year={moment(musicData.consumerReleaseDate).format('YYYY')}
          title={musicData.title}
          duration={secondsToHms(musicData.duration)}
        ></MusicCover>
      </div>
      <div className={musicTrackStyle.buttons}>
        <button disabled={submitMusicHook.isFetching} onClick={(e) => back()}>Back</button>
        <button disabled={submitMusicHook.isFetching} onClick={(e) => setOpenConfirmModal(true)}>Submit</button>
      </div>
      {openConfirmModal && <Modal title="Submition Confirmation" show={openConfirmModal} handleClose={() => setOpenConfirmModal(false)} setShow={setOpenConfirmModal}>
      <div className={musicTrackStyle.modal}>
        <div className={musicTrackStyle.modal__main}>
          <div className={musicTrackStyle.modal__text}>
            <div style={{display: 'flex'}}>
              <CheckBox style={{marginRight: '20px'}} object={musicData} setObject={setMusicData} name='agree' />
              By submitting this you are agreeing of Alfan&apos;s terms and services
            </div>
          </div>
          <div className={musicTrackStyle.modal__buttons}>
              <button className={musicTrackStyle.modal__buttons__button} onClick={() => setOpenConfirmModal(false)}>Cancel</button>
              <button disabled={!musicData.agree} className={musicTrackStyle.modal__buttons__button__save} onClick={()=>{
                setOpenConfirmModal(false);
                submitSong();
                }}>Submit</button>
          </div>
        </div>
      </div>
      </Modal>}
      <SuccessModal
        isModalOpen={successModal}
        handleClose={() => {
          setSuccessModal(false);
          modalForward();
        }}
        back={modalBack}
        forward={modalForward}></SuccessModal>
       {submitMusicHook.isFetching ? <div className={musicTrackStyle.submitLoader}><Loader type="TailSpin" color="#EC1E24" height={100} width={100} /></div> : null}
    </div>
  );
};

export default MusicSubmit;
