import React from 'react';
import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  return (
    <div className='main__wrapper'>
        <div className='header'>
            <div className='header__content'>
              <h2>Alfan</h2>
              <p>Terms & Conditions</p>
            </div>
        </div>
        <div className='content'>
            <div className='content__wrapper'>
                <div className='privacy__policy'><h1>Terms & Conditions</h1></div>
                <br />
                <div className='privacy__description'><h5>UPDATED: 20 SEPTEMBER 2022</h5></div>
                <br />
                <div className='privacy__description'><h5>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</h5></div>
                <br />

                <div className='privacy__description'><h3>Who we are and how to contact us?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                    <a href="https://alfan.link">https://alfan.link</a> and <a href="https://alfangroup.com">https://alfangroup.com</a> and any other software, platforms, functionalities or features (including, without limitations, accounts) provided in connection thereto (&quot;our site&quot;) is a website operated by Alfan Digital Media Group FZ-LLC (&quot;we&quot;, &quot;our&quot;, &quot;us&quot;). We are registered in Dubai Media City, Dubai, UAE and our principal place of business is office is Office 406, CNN Building, 2 King Salman Bin Abdulaziz Al Saud St., Media City, Dubai, UAE.
                  </div>
                  <div className='privacy__para textColor'>
                    To contact us, please email info@alfangroup.com.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>By using our site you accept these Terms</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                    These terms and conditions set out the rules for using our site. By using our site, you confirm that you accept these terms and conditions, and all other terms and conditions linked in, or referred to by, our site (including, without limitation, the terms set out under &quot;There are other terms that apply to you&quot; below) (&quot;Terms&quot;). You agree you will comply with, and are solely responsible and liable for your compliance with, these Terms.
                  </div>
                  <div className='privacy__para textColor'>
                    If you do not agree to these Terms, you must not use our site.
                  </div>
                  <div className='privacy__para textColor'>
                    We recommend that each time you use our site, you check these Terms for updates.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>There are other terms that apply to you</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                    These Terms refer to the following additional terms, which also apply to your use of our site and you agree to comply with:
                  </div>
                  <div className='desc__points'>
                      <ul>
                      <li><a href="/privacy-policy">Our Privacy Policy.</a> See further under &quot;How we may use your personal information?&quot; below.</li>
                      <li><a href="/standards-of-use">Our Standards of Use</a>. See further under &quot;What rules apply to your content?&quot; below.</li>
                      <li><a href="/cookie-policy">Our Cookie Policy</a>.</li>
                      <li><a href="/sales-and-purchase-term">Our Sale and Purchase Terms</a>. See further under &quot;What rules apply to selling and purchasing under our site?&quot; below.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                    The additional terms above form part of, and are incorporated into, these Terms.
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>We may make changes to these Terms</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                    We may amend these Terms from time to time. The amended version of the Terms replace and supersede the previous Terms immediately upon publishing on our site. You confirm that you accept the amended Terms and you agree to comply with them. Every time you wish to use our site, please check these Terms to ensure you understand the Terms that apply at that time.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>We may make changes to our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                    We may update and change our site from time to time to reflect changes to our services, products, our user&apos;s needs, regulatory or legal requirements, our business priorities and / or for any other reason whatsoever.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>We may suspend or withdraw our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Our site is made available to view free of charge, although charges may apply to various site access plans or products and / or services referred to in our site.
                  </div>  
                  <div className='privacy__para textColor'>
                  We do not guarantee:
                  </div>
                  <div className='desc__points'>
                      <ul>
                      <li>that our site will remain free of charge to view;</li>
                      <li>any charges that currently apply will be kept at the same rate;</li>
                      <li>that our site, or any content on it, will always be available or be uninterrupted.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  We may suspend or withdraw or restrict the availability of all or any part of our site for business and / or operational reasons at any time without notice.  We may change any charges on our site at any time, without notice, and in our absolute discretion.
                  </div>
                  <div className='privacy__para textColor'>
                  You are also solely responsible and liable for ensuring that all persons who access our site through your internet connection, or on your behalf, are aware of these Terms and other applicable terms, conditions and laws, and that they comply with them
                  </div>
                  <div className='privacy__para textColor'>
                  For the purposes of these Terms, &quot;person&quot; has the broadest possible meaning and includes, without limitation, natural persons, companies and other incorporated entities, unincorporated entities and government bodies.
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>We may transfer this agreement to someone else</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We may transfer our rights and obligations under these Terms to another person. 
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>Location of use of our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Our site is designed to be appropriate for use in the UAE and may be appropriate for use in other locations. However, we do not represent that content available on or through our site is appropriate for use or available in other locations.  By continuing to access, view or make use of our site and any related content, services and products, you hereby warrant and represent to us that you are permitted to do so in accordance with the laws of your location.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>Your account</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  If you are a natural person, you must be 18 years old or older to create an account on, and use, our site.  By using our site you represent and warrant that:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                      <li>if you are a natural person, you are over 18 years old; and</li>
                      <li>you will not permit or suffer any person under the age of 18 years old to use our site or your account.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that if you are between 18 and 21 years of age, your use of our site may be subject to additional limitations than for users over 21 years old (for example, pursuant to our Sale and Purchase Terms).
                  </div>
                  <div className='privacy__para textColor'>
                  You must provide us with true and correct information about yourself when you create an account on our site.  You must update your account immediately if any of your information changes.
                  </div> 
                  <div className='privacy__para textColor'>
                  You are solely responsible and liable for anything that happens on or in relation to your account.  We are not responsible or liable for your account or anything in relation to your account.
                  </div> 
                  <div className='privacy__para textColor'>
                  You must not assign or transfer your account to any third party.  For the purposes of these Terms your account profile, username and password are &quot;content&quot; as referred to under ”What rules apply to your content&quot; below and your account profile, username and password must comply with such Terms.
                  </div> 
                  <div className='privacy__para textColor'>
                  You username must be unique and related to you, and not express or imply a connection with, or endorsement by, us or any third party (except to the extent you have prior written approval from us or such third party).  We may reject, or require that you change, any element of your account profile, username or password (in our absolute discretion).  If you are not regularly using your account, in our absolute discretion, or your account is dormant for 6 months or more, we may delete your account and all associated information or data and / or reclaim or reassign your username.
                  </div> 
                  <div className='privacy__para textColor'>
                  You must have permission from any person you are creating an account on behalf of, and you agree, warrant and represent that you are duly authorised by that person to act and agree to these Terms on their behalf.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>You must keep your account details safe</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  If you choose, or you are provided with, a username, password or any other piece of information as part of our security procedures (such as a security or identification code), you must treat such information as confidential and keep it safe. You must not disclose it to any third party or allow any third party to access or use your account (except to the extent any third party is your duly appointed representative or employee and is acting in accordance with you express directions and consent).
                  </div> 
                  <div className='privacy__para textColor'>
                  We have the right to disable any username, password or other information as part of our security procedures, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms.
                  </div>
                  <div className='privacy__para textColor'>
                  If you know or suspect that anyone other than you knows your username, password or other information as part of our security procedures, you must immediately take all steps necessary to secure your account and promptly notify us at info@alfangroup.com
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>What rules apply to your content?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Your use of our site, your account and / or any content that you post on our site must:
                  </div>
                  <div className='desc__points'>
                      <ul>
                      <li>comply with our <a href="/standards-of-use">Standards of Use</a>;</li>
                      <li>not, in our absolute discretion, damage us, our site or the reputation of us or our site, or bring us or our site into disrepute;</li>
                      <li>not infringe any applicable laws and regulations, and / or the traditional values, morals and cultural norms of the UAE and other GCC countries;</li>
                      <li>not infringe our, or any third party&apos;s, rights including, without limitation, intellectual property rights and privacy rights.</li>
                      <li>be owned by you or, if it is third party content, you have all of the rights needed to post it on our site, and for the content to be used by you and us in accordance with these Terms;</li>
                      <li>not portray any other person in a manner which would defame, slander or otherwise diminish them or their reputation;</li>
                      <li>not be misleading or deceptive, or intended or designed to misinform, or be likely to misinform another person in our absolute discretion;</li>
                      <li>whether you are aware or not, be free of viruses or other harmful codes, files, or programs referred to below in the section &quot;We are not responsible for viruses and you must not introduce them&quot;; and</li>
                      <li>not contain any unauthorised advertising, promotional material, solicitations or endorsements.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  Your responsibility and liability under this section includes, without limitation, your responsibility and liability not to permit or suffer any use or posting of content to our site or your account that will breach these Terms.
                  </div>
                  <div className='privacy__para textColor'>
                  For the avoidance of doubt, “content” includes, without limitation, text, images, graphics, videos, links, comments and any other materials you post to our site or add to your account.
                  </div>
                  <div className='privacy__para textColor'>
                  You warrant that any of your content does comply with these Terms, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be solely responsible and liable for any loss or damage we suffer as a result of your breach of warranty.
                  </div>
                  <div className='privacy__para textColor'>
                  Any content you post to our site will be considered non-confidential. 
                  </div>
                  <div className='privacy__para textColor'>
                  We have the right to disclose your identity to any third party who is claiming that any content posted by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.
                  </div>
                 </div> 
                <br />
                <div className='privacy__description'><h3>How we may use your content?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  You grant us an irrevocable, perpetual, worldwide, transferrable and royalty-free licence to:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                      <li>use, reproduce, copy, show, share, display in public, distribute, deal in and transact; and</li>
                      <li>modify, adapt and create derivative works in relation to;</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  your content and your name, image, video, voice, photograph, likeness and your other personal attributes of whatsoever nature on our site or other sites, platforms or social media or any other media channel of whatsoever nature, whether for the purposes of our site or such other similar or related purposes including, without limitation, entertainment, advertising, promotional or monetization purposes.
                  </div>
                  <div className='privacy__para textColor'>
                  We may transfer, assign or sub-licence the above licence to our affiliates, partners or advertisers.
                  </div>
                  <div className='privacy__para textColor'>
                  Without limiting the foregoing, we are not required to pay any fee or other consideration in respect of the licence granted to us above, or any transferring, assignment or sub-licensing thereof, subject to any written agreement between us to the contrary.
                  </div>
                  <div className='privacy__para textColor'>
                  You represent and warrant that you hold all rights, title and interests necessary (including, without limitation, third party rights) in order to grant us the licence above.
                  </div>
                  <div className='privacy__para textColor'>
                  Subject to the licence granted to us above, nothing under these Terms transfers or assigns to us ownership of your content and your name, image, video, voice, photograph, likeness and your other personal attributes of whatsoever nature. 
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>Content security</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that our site, your content, account profile and your name, image, video, voice, photograph, likeness and your other personal attributes of whatsoever nature:
                  </div>   
                  <div className='desc__points'>
                      <ul>
                      <li>are publically accessible and available; and</li>
                      <li>may be viewed and shared by the general public (including, without limitation, across the internet globally).</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  You are solely responsible and liable for ensuring that any personal or other information you do not wish to be publically available is not posted to or otherwise displayed on our site.
                  </div> 
                  <div className='privacy__para textColor'>
                  You are solely responsible and liable for ensuring that you do not post or otherwise display on our site any personal or other information of third parties, without obtaining their prior written consent.  You shall provide us with a copy of such prior written request upon demand.
                  </div> 
                  <div className='privacy__para textColor'>
                  You are solely responsible for the security and maintenance of your content, data and other information on, and in relation to, our site including, without limitation, for securing and backing up all such content, data and other information.  We do not represent, warrant or guarantee that such content, data and other information will be saved and / or preserved on our site or otherwise, and we are not responsible or liable for the deletion, loss or irretrievability of the same.
                  </div> 
                  <div className='privacy__para textColor'>
                  We are not responsible or liable for any use, showing, sharing, displaying, distribution, dealing, transacting, modifying, adapting and creation of derivative works in relation to, your content and your name, image, video, voice, photograph, likeness and your other personal attributes of whatsoever nature, by third parties (whether in accordance with, or in breach of, your rights).
                  </div> 
                  <div className='privacy__para textColor'>
                  We are not required to monitor or police the quality, accuracy, reliability, morality or legality of your content, or any other user of our site, but we may do so in our absolute discretion.  We are not responsible or liable for any content, products or services posted to our site, or otherwise made available on our site, by other users of our site.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>How you may use material on our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We are the owner of our site and the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                  </div> 
                  <div className='privacy__para textColor'>
                  Nothing under these Terms grants you any right, title or interest in our site other than a limited, revocable, non-exclusive and non-transferable right to use our site for posting and sharing your content and profile information and viewing and interacting with other users and their content.  The foregoing use rights may be revoked by us immediately, without notice, for any reason whatsoever.
                  </div>
                  <div className='privacy__para textColor'>
                  You must not, without our prior written approval:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                      <li>download, reproduce, photograph or print our site or extracts of any page(s) from our site;</li>
                      <li>modify our site or extracts of any page(s) from our site (whether in digital or printed form);</li>
                      <li>use any illustrations, photographs, video or audio sequences or any graphics from our site (other than viewing them on our site);</li>
                      <li>use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors; or</li>
                      <li>use our trade marks, brand names or logos for any purpose including, without limitation, implying a partnership with, or endorsement from, us.</li>
                      </ul>
                  </div>  
                  <div className='privacy__para textColor'>
                  Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged (except where the content is user-generated).
                  </div>
                  <div className='privacy__para textColor'>
                  You must not remove, obscure or change any proprietary notices or trade marks on our site, or make unauthorised copies of, reproduce, distribute, licence, sell, resell, modify, translate, disassemble, decompile, decrypt, reverse engineer, create any derivative works from, or attempt to derive the source code of, our site or any part of it.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>What rules apply to selling and purchasing under our site?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  If you are selling or purchasing goods or services on our site including, without limitation, purchasing site use plans from us, or otherwise facilitating or linking to the sale or purchase of goods or services you must comply with our <a href="/sales-and-purchase-term">Sale and Purchase Terms</a>.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>No text or data mining, or web scraping</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):
                  </div> 
                  <div className='desc__points'>
                      <ul>
                      <li>Any &quot;robot&quot;, &quot;bot&quot;, &quot;spider&quot;, &quot;scraper&quot; or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.</li>
                      <li>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</li>
                      </ul>
                  </div>  
                  <div className='privacy__para textColor'>
                  The provisions in this clause should be treated as an express reservation of our rights in this regard.
                  </div> 
                  <div className='privacy__para textColor'>
                  This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Do not rely on information on this site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  The content on our site is provided for general information and use only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.
                  </div>
                  <div className='privacy__para textColor'>
                  Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>We are not responsible for websites linked to</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.
                  </div>
                  <div className='privacy__para textColor'>
                  We have no control over, or responsibility or liability for, the contents of those sites or resources.
                  </div>   
                </div>
                <br />
                <div className='privacy__description'><h3>User-generated content is not approved by us</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  This website may include profiles, information, services, products, materials and / or other data or whatsoever nature uploaded by other users of the site, including without limitation links to stores, bulletin boards and chat rooms. These items have not been verified or approved by us. We are not responsible or liable for these items or your use or reliance thereon. The views expressed by other users on our site do not represent our views or values.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>How to complain about content uploaded by other users?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  If you wish to complain about content uploaded by other users, please contact us on info@alfangroup.com
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Our responsibility for loss or damage suffered by you</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  The exclusions and limitations set out in this section are in addition to any other exclusions, limitations, releases and indemnities set out under these Terms.  To the extent there is any inconsistency between the exclusions and limitations set out in this section and any other exclusions, limitations, releases and indemnities set out under these Terms, the inconsistency shall be resolved by applying the provision which gives the greatest protection and / or benefit to us.
                  </div>
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that you and / or other users of our site are not our employees, agents or subcontractors (except where so appointed by us and our site expressly identifies another user as an employee, agent or subcontractor).
                  </div>
                  <div className='privacy__para textColor'>
                  We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
                  </div>
                  <div className='privacy__para textColor'>
                  We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it to the fullest extent permitted by law.
                  </div>
                  <div className='privacy__para textColor'>
                  We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                      <li>use of, or inability to use, our site;</li>
                      <li>use of or reliance on any content displayed on our site; or</li>
                      <li>any damage to a device, server, other hardware, application, platform, software or digital content belonging to you.</li>
                      </ul>
                  </div>  
                  <div className='privacy__para textColor'>
                  In particular, we will not be liable for:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                      <li>loss of profits, sales, business, or revenue;</li>
                      <li>business interruption;</li>
                      <li>loss of anticipated savings;</li>
                      <li>loss of business opportunity, goodwill or reputation; or</li>
                      <li>any indirect or consequential loss or damage.</li>
                      </ul>
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>How we may use your personal information</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We will only use your personal information as set out in our <a href="/privacy-policy">privacy policy</a>.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>We are not responsible for viruses and you must not introduce them</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We do not guarantee that our site will be secure or free from bugs or viruses.
                  </div>
                  <div className='privacy__para textColor'>
                  You are solely responsible and liable for configuring your information technology, computer programmes, applications and / or platforms to access our site. You must use your own virus protection software.
                  </div>
                  <div className='privacy__para textColor'>
                  You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected or related to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you may be committing a criminal offence. We will report any such activity or offence to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity and other information to them. You consent to the fullest extent permissible by law to us reporting to, co-operating with and disclosing to the authorities in relation to your use of our site, your activities on our site and your identity and other information. In the event of any offence or other breach of these Terms, your right to use our site will cease immediately.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Rules about linking to our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
                  </div>
                  <div className='privacy__para textColor'>
                  You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
                  </div>
                  <div className='privacy__para textColor'>
                  You must not establish a link to our site in any website that is not owned by you.
                  </div> 
                  <div className='privacy__para textColor'>
                  Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.
                  </div> 
                  <div className='privacy__para textColor'>
                  We reserve the right to withdraw linking permission without notice.
                  </div> 
                  <div className='privacy__para textColor'>
                  The website in which you are linking must comply in all respects with the content standards set out in our <a href="/standards-of-use">Standards of Use</a>.
                  </div> 
                  <div className='privacy__para textColor'>
                  If you wish to link to or make any use of content on our site other than that set out in these Terms, please contact info@alfangroup.com.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Our trade marks are registered</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Alfan, Alfan.Link are our registered trade marks. You are not permitted to use our trade marks without our prior written approval.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Our powers and remedies</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  If you breach these Terms, or we suspect that you may have breached or are about to breach these Terms, we may without notice, and at any time, take any action in relation to such breach as we see fit in our absolute discretion, including without limitation:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                      <li>removing or deleting any or all of your content from our site;</li>
                      <li>modifying your content in line with these Terms or applying a warning to your content;</li>
                      <li>suspending or terminating your account;</li>
                      <li>suspending or terminating your use of our site;</li>
                      <li>restricting your access to our site;</li>
                      <li>restricting the access of other users to your account profile or your content;</li>
                      <li>terminating any or all of the rights granted to you under these Terms;</li>
                      <li>terminating any other agreement between us and you relating to our site; and / or</li>
                      <li>disabling your username or password, or any other information as part of our security procedures.</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that our powers and remedies above are not exhaustive, and we do not guarantee your right to post content or continue your use of, or your account on, our site.  We reserve our right to take any action we deem necessary in our absolute discretion in an emergency, or to satisfy the requirement or request of any government authority.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Releases and indemnities</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Without limitation, you fully and irrevocably release us from all costs, expenses, liabilities, claims and damages in relation to any matter referred to under these Terms which:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                      <li>we are not responsible and / or liable for;</li>
                      <li>you are responsible and / or liable for; and / or</li>
                      <li>you have an obligation for under these Terms.</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  You indemnify and hold us harmless from any and all liabilities, claims and damages connected with your breach of these Terms or the negligence, willful misconduct or criminal act of you or your representatives, employees or agents.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Which laws apply to our site?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Any dispute arising out of or in connection with these Terms or this site, including any question relating to their existence, validity or termination, shall be referred to and finally resolved by arbitration. The number of arbitrators shall be one and the language to be used in the arbitral proceedings shall be English. The law governing the contract shall be the substantive law of England and Wales. The seat (the legal place) of the arbitration shall be the Abu Dhabi Global Market and the Arbitration Regulations of the Abu Dhabi Global Market in force at the date of the referral to arbitration shall apply. The arbitration shall be conducted under the rules of the Abu Dhabi Global Market Arbitration Centre which are accordingly incorporated by reference into this arbitration clause.
                  </div>
                </div>
                <br />
            </div>         
        </div>
    </div>
  );
};

export default TermsAndConditions;
