import React from 'react';
import {useState} from 'react';
import Modal from '../Modal/Modal';
import style from './successModal.module.scss';
import GoldCup from '../../assets/images/GoldCup.svg';
import closeCircle from '../../assets/images/close-circle.svg';

interface IProps{
  isModalOpen: boolean;
  handleClose: ()=>void;
  back: ()=>void;
  forward: ()=>void;
}

const SuccessModal = (props: IProps) => {
  const {isModalOpen, handleClose, back, forward} = props;
  return (
    <Modal show={isModalOpen} title={''}>
      <div className={style.container}>
        <div className={style.closeButton}>
          <img src={closeCircle} onClick={handleClose}/>
        </div>
        <img src={GoldCup} className={style.container__image}></img>
        <div className={style.textContainer}>
          <span>Congratulations!</span>
          <span>Your track was submitted and it is pending approval</span>
        </div>
        <div className={style.buttons}>
          {/* <button onClick={(e) => back()}>Track Status</button> */}
          <button onClick={(e) => forward()}>Finish</button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
