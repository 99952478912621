import React from 'react';
import {useSelector} from 'react-redux';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {ACTIVERED, DARKTHEME_BLACK_2, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './Button.module.scss';

interface ButtonProps{
    onClick: (e:any)=>void;
    children?: JSX.Element | JSX.Element[];
    style?: object;
    gradient?:boolean;
    disabled?:boolean;
    inverted?:boolean;
}

const Button = ({onClick, children, gradient=false, style, disabled, inverted}:ButtonProps)=>{
  const me =selectMe(useAppSelector(store.getState)).me;
  return (<button type='button' disabled={disabled} className={inverted ? styles.inverted : styles.button}
    style={{
      ...(inverted && me && me.theme === THEMES.DARK && {color: ACTIVERED}),
      borderColor: me ? me.theme === THEMES.LIGHT ? '#ebcecf' : ACTIVERED : '#ebcecf',
      ...style, ...(!gradient && {backgroundImage: 'none'}),

    }}
    onClick={onClick}>
    {disabled && <div className={styles.overlay}
      style={{
        ...(me && me.theme === THEMES.DARK ? {backgroundColor: DARKTHEME_BLACK_2}:{backgroundColor: '#d1dce8'}),
      }}></div>}
    {children}</button>);
};

export default Button;
