import React, {useRef, useState} from 'react';
import {getCounterIcon} from '../../constants/counterTypes';
import {THEMES} from '../../constants/types';
import addTooltip from '../../features/Helper/addTooltipHOC/addTooltipHOC';
import {toK} from '../AreaChart/CustomAreaChart';
import OptionsButton from '../OptionsButton/OptionsButton';
import style from './connectedNetworks.module.scss';
import trash from '../../assets/images/trash.svg';
import eye from '../../assets/images/eye-slash.svg';
import warning from '../../assets/images/warning-2.svg';
import useRequest from '../../features/API/request';
import {refreshMe} from '../../features/Login/login';
import Modal from '../Modal/Modal';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import Button from '../Button/Button';

interface IProps{
  createdAt: string;
  followers: string;
  id: string;
  views: string;
  type: string
  theme: string;
  username?: string;
  channelName?: string;
  visible: boolean;
  token?: string;
}

// as we add more networks this should be updated
const TypeString: {[key: string]: string} = {
  youtube: 'subscribers',
  instagram: 'followers',
  tiktok: 'followers',
  twitter: 'followers',
};

interface ITooltip {
  id: string;
  visible: boolean;
  type: string;
  username?: string;
  channelName?: string;
}

const ConnectedNetworkTooltip = ({id, visible, type, username, channelName} : ITooltip) => {
  const deleteNetworkReq = useRequest({method: 'POST', url: '/platforms/removeConnectedAccount'});
  const updateNetworkReq = useRequest({method: 'POST', url: '/platforms/hideShowConnectedAccount'});
  const totalEngagementUpdateRequest = useRequest({url: '/platforms/checkUpdates', method: 'PUT'});
  const icon = getCounterIcon(type, THEMES.LIGHT);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef, setShowModal);
  const handleDelete = async () => {
    // await deleteNetworkReq.doRequest({id: id, type: type});
    // refreshMe();
    setShowModal(true);
  };
  const handleConfirmDelete = async () => {
    setShowModal(false);
    await deleteNetworkReq.doRequest({id: id, type: type});
    await totalEngagementUpdateRequest.doRequest();
    refreshMe();
  };
  const handleHideShow = async () => {
    setShowModal(false);
    await updateNetworkReq.doRequest({id: id, type: type, visible: !visible});
    await totalEngagementUpdateRequest.doRequest();
    refreshMe();
  };
  return <div className={style.tooltip}>
    <div className={style.tooltip__row} onClick={handleDelete}>
      <img src={trash}/>
      <span>Delete account</span>
    </div>
    <div className={style.tooltip__row} onClick={handleHideShow}>
      <img src={eye}/>
      <span>{visible? 'Hide' : 'Show'} data</span>
    </div>
    {showModal &&
    <Modal show={showModal}>
      <div className={style.modal}>
        <div className={style.modal__main}>
          <img className={style.modal__icon} src={warning}></img>
          <div className={style.modal__text} ref={modalRef}>
            <div>
              <span className={style.modal__span}>Are you sure you want to delete </span>
              <span className={style.modal__spanIcon}><img src={icon}/></span>
              <span className={style.modal__bolded}> {username || channelName}</span>
              <span className={style.modal__span}> account from dashboard?</span>
            </div>
            <div className={style.modal__buttons}>
              <button className={style.modal__buttons__button} onClick={() => setShowModal(false)}>Cancel</button>
              <button className={style.modal__buttons__button} onClick={handleConfirmDelete}>Delete</button>
            </div>
          </div>
        </div>

      </div>
    </Modal>}
  </div>;
};

const ConnectedNetwork = (props: IProps) => {
  const icon = getCounterIcon(props.type, props.theme);
  const OptionsButtonWithTooltip = addTooltip(OptionsButton, () => <ConnectedNetworkTooltip id={props.id} visible={props.visible} type={props.type} channelName={props.channelName} username={props.username}/>, 'CLICK', 'TOP');
  return <div className={style.container}>
    <img className={style.img} src={icon}/>
    <div className={style.textContainer}>
      <span className={'header'}>{props?.username || props?.channelName} {!props.visible && '(Hidden)'} {props.token === '-1' && <span className={style.warning}> - reconnect</span>}</span>
      <span className={'subtitle'}>{toK(props.followers)} {TypeString[props.type]}</span>

    </div>
    <div className={style.container__last}>
      <OptionsButtonWithTooltip onClick={() => {}}></OptionsButtonWithTooltip>
    </div>
  </div>;
};


export default ConnectedNetwork;
