import React, {useEffect, useRef} from 'react';
import moment from 'moment';
import Select from '../Select/Select';
import {useState} from 'react';
import calendar from '../../assets/images/calendar.svg';
import OutlineButton from '../OutlineButton/OutlineButton';
import style from './multiDatePicker.module.scss';
import {DayPickerSingleDateController} from 'react-dates';
import {DARKTHEMEMAINGREY, DARKTHEME_BLACK, GREY_COLOR_11, GREY_COLOR_9, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import i from '../../assets/images/icons8-info.svg';

interface IOption{
  value: {
    name: string | 'Custom';
    start: Date | null | string;
    end: Date | null | string;
  };
  name: string;
  previous: {
    start: Date | null | string;
    end: Date | null | string;
  };
}

interface IProps{
  startDate: Date | undefined | string;
  endDate: Date | undefined | string;
  setStartDate: any;
  setEndDate: any;
  customOptions?: IOption[];
  dateFormat?: string;
  style?: any;
  dropDownStyle?: any;
  setOption?: Function;
  setPreviousStartDate?: any;
  setPreviousEndDate?: any;
  defaultOption?: string;
  allowFirstInMonth?: boolean;
  setMessage?: Function;
}

export const getOptions = (dateFormat='YYYY-MM-DD')=>{
  return {
    LAST_30_DAYS: {
      start: moment().add(-30, 'days').format(dateFormat), end: moment().format(dateFormat), name: 'Last 30 days', previousName: 'Last 60 days',
      previous: {
        start: moment().add(-60, 'days').format(dateFormat),
        end: moment().add(-30, 'days').format(dateFormat),
      },
    },
    THIS_MONTH: {
      start: moment().startOf('month').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), name: 'This Month', previousName: 'Last Month',
      previous: {
        start: moment().add(-1, 'month').startOf('month').format(dateFormat),
        end: moment().add(-1, 'month').endOf('month').format(dateFormat),
      },
    },
    LAST_MONTH: {
      start: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), end: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD'), name: 'Last Month', previousName: '2 Months Before',
      previous: {
        start: moment().add(-2, 'month').startOf('month').format(dateFormat),
        end: moment().add(-2, 'month').endOf('month').format(dateFormat),
      },
    },
    LAST_QUARTER: {
      start: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'), end: moment().endOf('month').format('YYYY-MM-DD'), name: 'Last Quarter', previousName: 'Previous Quarter', previous: {
        start: moment().add(-6, 'month').startOf('month').format(dateFormat),
        end: moment().add(-3, 'month').endOf('month').format(dateFormat),
      },
    },
    THIS_YEAR: {
      start: moment().startOf('year').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), name: 'This Year', previousName: 'Last Year',
      previous: {
        start: moment().add(-1, 'year').startOf('year').format(dateFormat),
        end: moment().add(-1, 'year').endOf('year').format(dateFormat),
      },
    },
    LAST_YEAR: {
      start: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), end: moment().add(-1, 'year').endOf('year').format('YYYY-MM-DD'), name: 'Last Year', previousName: '2 Years Before',
      previous: {
        start: moment().add(-2, 'year').startOf('year').format(dateFormat),
        end: moment().add(-2, 'year').endOf('year').format(dateFormat),
      },
    },
    ALL_TIME: {
      start: moment('2001-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), name: 'All time', previousName: '',
      previous: {
        start: null,
        end: null,
      },
    },
  };
};

const MultiDatePicker = (props: IProps) => {
  const {startDate, endDate, setStartDate, setEndDate, setOption, setPreviousStartDate, setPreviousEndDate,
    defaultOption, allowFirstInMonth} = props;
  const dateFormat = props.dateFormat || 'YYYY-MM-DD';
  const DEFAULT_OPTIONS = [
    {value: {start: moment().add(-30, 'days').format(dateFormat), end: moment().format(dateFormat), name: 'Last 30 days'}, name: 'Last 30 days',
      previous: {
        start: moment().add(-60, 'days').format(dateFormat),
        end: moment().add(-30, 'days').format(dateFormat),
      }},
    {value: {start: moment('2001-01-01', 'YYYY-MM-DD').format(dateFormat), end: moment().format(dateFormat), name: 'All time'}, name: 'All time',
      previous: {
        start: null,
        end: null,
      }},
    {value: {start: moment().startOf('month').format(dateFormat), end: moment().format(dateFormat), name: 'This Month'}, name: 'This Month',
      previous: {
        start: moment().add(-1, 'month').startOf('month').format(dateFormat),
        end: moment().add(-1, 'month').endOf('month').format(dateFormat),
      }},
    {value: {start: moment().add(-1, 'month').startOf('month').format(dateFormat), end: moment().add(-1, 'month').endOf('month').format(dateFormat), name: 'Last Month'}, name: 'Last Month',
      previous: {
        start: moment().add(-2, 'month').startOf('month').format(dateFormat),
        end: moment().add(-2, 'month').endOf('month').format(dateFormat),
      }},
    {value: {start: moment().add(-3, 'month').startOf('month').format(dateFormat), end: moment().endOf('month').format(dateFormat), name: 'Last Quarter'}, name: 'Last Quarter',
      previous: {
        start: moment().add(-6, 'month').startOf('month').format(dateFormat),
        end: moment().add(-3, 'month').endOf('month').format(dateFormat),
      }},
    {value: {start: moment().startOf('year').format(dateFormat), end: moment().format(dateFormat), name: 'This Year'}, name: 'This Year',
      previous: {
        start: moment().add(-1, 'year').startOf('year').format(dateFormat),
        end: moment().add(-1, 'year').endOf('year').format(dateFormat),
      }},
    {value: {start: moment().add(-1, 'year').startOf('year').format(dateFormat), end: moment().add(-1, 'year').endOf('year').format(dateFormat), name: 'Last Year'}, name: 'Last Year',
      previous: {
        start: moment().add(-2, 'year').startOf('year').format(dateFormat),
        end: moment().add(-2, 'year').endOf('year').format(dateFormat),
      }},
    {value: {start: moment(startDate).format(dateFormat), end: moment(endDate).format(dateFormat), name: 'Custom'}, name: 'Custom', previous: {
      start: null,
      end: null,
    }},

  ];


  let options = props.customOptions || DEFAULT_OPTIONS as any;

  if (defaultOption) {
    const firstOption = options.filter((option:any)=>option.name === defaultOption);
    if (firstOption.length) {
      const newOptions = [firstOption[0]];
      options.map((option:any)=>{
        if (option.name !== defaultOption) newOptions.push(option);
      });

      options = newOptions;
    }
  }

  const [selected, setSelected] = useState<any>(options[0]);
  const [open, setOpen] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const [shouldBeDisabled, setShouldBeDisabled] = useState(false);


  const onClick = () => {
    if (showPicker) {
      setShowPicker(!showPicker);
      return;
    }
    setOpen(!open);
  };

  const onStartDateChange = (date:any) => {
    if (date.month() <= moment().month()) {
      if (moment(date) >= moment(endDate)) {
        if (moment(date).date === moment().date) {
          setEndDate(moment(date.clone()).format(dateFormat));
        } else {
          setEndDate(moment(date.clone()).add(1, 'day').format(dateFormat));
        }
      }
      setStartDate(date.clone().format(dateFormat));
    } else {
      setStartDate(moment().format(dateFormat));
    }
  };

  const onEndDateChange = (date:any) => {
    if (date.month() <= moment().month()) {
      setEndDate(date.clone().format(dateFormat));
    } else {
      setEndDate(moment().format(dateFormat));
    }
  };

  const onFocusChange = ({focused}:any)=>{
    setIsFocused(focused);
  };

  const handlePrevDate = ()=>{
    // console.log('evo prev dates');
  };

  const handleNextDate = (setDate:Function, date:any)=>{
    const nextDate = moment(date).add(1, 'month').startOf('month');
    if (nextDate.isSameOrBefore(moment())) {
      setDate(nextDate.clone());
    } else {
      setDate(moment());
    }
  };

  useEffect(()=>{
    const option = DEFAULT_OPTIONS.filter((option:any)=>option.name === 'This Month')[0];
    const diff = moment(option.value.end, dateFormat).diff(moment(option.value.start, dateFormat), 'days');
    if (diff <= 2) {
      setShouldBeDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setShowPicker(false);
    }
  }, [open]);

  useEffect(() => {
    if (selected.name === 'Custom') {
      setShowPicker(true);
      setStartDate(selected.value.start);
      setEndDate(selected.value.end);
    } else if (selected.name === 'This Month' && !allowFirstInMonth) {
      const option = DEFAULT_OPTIONS.filter((option:any)=>option.name === selected.name)[0];
      const diff = moment(option.value.end, dateFormat).diff(moment(option.value.start, dateFormat), 'days');
      if (diff > 2) {
        setStartDate(selected.value.start);
        setEndDate(selected.value.end);
        if (setPreviousStartDate)setPreviousStartDate(selected.previous.start);
        if (setPreviousEndDate) setPreviousEndDate(selected.previous.end);
      }
    } else {
      setStartDate(selected.value.start);
      setEndDate(selected.value.end);
      if (setPreviousStartDate)setPreviousStartDate(selected.previous.start);
      if (setPreviousEndDate) setPreviousEndDate(selected.previous.end);
    }
  }, [selected]);

  useEffect(()=>{
    if (selected.name === 'Custom' && setPreviousStartDate && setPreviousEndDate) {
      // calculate previous start and end date
      const timeFrame = moment(endDate, dateFormat).clone().diff(moment(startDate, dateFormat).clone(), 'days');
      setPreviousStartDate(moment(startDate, dateFormat).add(-timeFrame, 'days').format(dateFormat));
      setPreviousEndDate(moment(endDate, dateFormat).add(-timeFrame, 'days').format(dateFormat));
    }
  }, [startDate, endDate, selected]);

  const node = useRef(null);
  useOutsideAlerter(node, setOpen);
  const me = selectMe(useAppSelector(store.getState)).me;

  return (
    <div className={style.container} style={{color: 'unset'}} ref={node}>
      <OutlineButton
        className={`${(open? style.buttonOpen : '')} ${style.button}`}
        style={props.style}
        onClick={onClick}
        text={selected?.name === 'Custom'? `${moment(props.startDate).format('MMM DD')} - ${moment(props.endDate).format('MMM DD')}` : selected.name}
        icon={calendar}
      />
      <div style={{
        color: 'unset',
        ...(me.theme === THEMES.DARK ?
          {backgroundColor: DARKTHEME_BLACK, borderColor: DARKTHEMEMAINGREY} :
          {backgroundColor: WHITE_COLOR, borderColor: GREY_COLOR_11}),
        visibility: (open? 'visible' : 'hidden'),
        ...props.dropDownStyle,
      }}
      className={style.dropDown}>

        {options && options?.map((el: any, index: number) =>

          <div

            style={{
              ...(me.theme === THEMES.LIGHT && {color: GREY_COLOR_9}),
              ...(shouldBeDisabled && el.name === 'This Month' && index === options.length-1 && {borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px'}),
            }}

            className={shouldBeDisabled && el.name === 'This Month' ? `${style.info} disabledDiv noselect` : ''}
            key={index}
            onClick={() => {
              if (!(shouldBeDisabled && el.name === 'This Month')) {
                setSelected(el);
                if (setOption) setOption(el.name);
                setOpen(false);
              }
            }}>
            {el.name}

            {el.name === 'This Month' &&
              <span className={`${style.infoText} noselect card`}>This feature is currently disabled because it is the beginning of the month. Please check back in a few days.</span>
            }
          </div>,

        )}
      </div>
      {showPicker ? <div className={style.datePicker}>
        <div className={style.datePicker__calendar}>
          <span>Start Date</span>
          <DayPickerSingleDateController
            onDateChange={onStartDateChange}
            onFocusChange={onFocusChange}
            focused={isFocused}
            date={moment(startDate).clone()}
            initialVisibleMonth={()=>moment(startDate).clone().startOf('month')}
            hideKeyboardShortcutsPanel
            isDayHighlighted={(day) => day.isBetween(startDate, endDate)}
            onPrevMonthClick={handlePrevDate}
            onNextMonthClick={()=>handleNextDate(setStartDate, startDate)}
            // navNext={moment(startDate).clone().month() >= moment().month() ? <button hidden={true}>Next</button> : '' }
            isOutsideRange={(momentDate)=>{
              if (momentDate.isAfter(moment()) || moment(startDate).isAfter(endDate)) return true;
              return false;
            }}
          />
        </div>
        <div className={style.datePicker__calendar}>
          <span>End Date</span>
          <DayPickerSingleDateController
            onDateChange={onEndDateChange}
            onFocusChange={onFocusChange}
            focused={isFocused}
            date={moment(endDate).clone()}
            initialVisibleMonth={()=>moment(endDate).clone().startOf('month')}
            hideKeyboardShortcutsPanel
            isDayHighlighted={(day) =>
              day.isBetween(moment(startDate), moment(endDate))
            }
            onPrevMonthClick={handlePrevDate}
            onNextMonthClick={()=>handleNextDate(setEndDate, endDate)}
            // navNext={moment(endDate).clone().month() >= moment().month() ? <button hidden={true}>Next</button> : '' }
            isOutsideRange={(momentDate)=>{
              if (momentDate.isAfter(moment())) {
                return true;
              }
              return false;
            }}
          />
        </div>
      </div> : <></>}
    </div>
  );
};

export default MultiDatePicker;
