import styles from './PaddedCard.module.scss';
import React, {useState, useEffect} from 'react';
import {DARKTHEMEMAINGREY, DARKTHEMEMAINWHITE, GREEN_COLOR, GREY_COLOR_11, GREY_COLOR_7, GREY_COLOR_9, RED_COLOR} from '../../constants/colors';
import redArrow from '../../assets/images/down-arrow.svg';
import greenArrow from '../../assets/images/up-arrow.svg';
import {Moment} from 'moment';
import useWindowWidth from '../../hooks/useWindowWidth';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {THEMES} from '../../constants/types';
import OutlinedIcon from '../OutlinedIcons/OutlinedIcon';
import {calculatePct} from '../../features/Parsers/parsers';
import {getOptions} from '../MultiDatePicker/multiDatePicker';
type PaddedCardProps = {
    totalEarnings: number;
    previousEarnings: number;
    platformIconList: Array<string>;
    earningsTimestamp: Moment;
    style?:object;
    timePeriod:string;
    showGrowth?: boolean;
    currency?: string;
}

const PaddedCard = ({totalEarnings, platformIconList, showGrowth=true, previousEarnings, earningsTimestamp, style, timePeriod, currency='$'}:PaddedCardProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  const mobile = useWindowWidth() <= 890;
  const pcts = calculatePct(totalEarnings, previousEarnings);
  const percentage = pcts.formattedPct;
  const [previous, setPrevious] = useState('');

  useEffect(()=>{
    for (const [key, value] of Object.entries(getOptions())) {
      if (value.name === timePeriod) setPrevious(value.previousName);
    }
  }, [timePeriod]);


  return (<div className={`${styles.container} card`} style={{...(me.theme === THEMES.LIGHT && {borderColor: GREY_COLOR_11}), ...style, ...(mobile && {padding: '39px 0px 39px 0px'})}}>
    <div className={styles.subtitle}
      style={{
        ...(me.theme === THEMES.LIGHT ? {color: GREY_COLOR_7} : {color: DARKTHEMEMAINGREY}),
        ...(mobile && {fontSize: '12px'})}}>EARNINGS {timePeriod.toLocaleLowerCase()}</div>
    <div className={`${styles.total} header`} style={{...(mobile && {fontSize: '36px'})}}>{currency+totalEarnings?.toLocaleString()}</div>
    <div className={styles.iconContainer} style={{...(mobile && {marginTop: '5px'})}}>
      {platformIconList.map((platform, index)=>
        <OutlinedIcon icon={platform} key={platform + index} />,
      )}
    </div>
    <div>

      {showGrowth && <div className={styles.lowerSection}>
        <span className={styles.percentage} style={{...(Number(percentage.slice(0, percentage.length-1)) < 0 ? {color: RED_COLOR}:{color: GREEN_COLOR})}}>
          {percentage}
          {Number(percentage.slice(0, percentage.length-1)) < 0 ?
    <img className={styles.arrow} src={redArrow} style={{transform: 'rotate(180deg)'}} /> :
    <img className={styles.arrow} src={greenArrow} />
          }
        </span>{pcts.pct < 0 ? 'Less ' : 'More '} than {previous}
      </div>}

    </div>
  </div>);
};

export default PaddedCard;
