import axios, {Method} from 'axios';
import {useCallback, useState} from 'react';
import {baseUrl} from '../EnvironmentVariables/enviromentVariables';
import {getSession, logOut} from '../Login/login';
interface IUseRequest {
  url: string;
  method: Method;
  type?: string;
}
export interface ErrorResponse {
  message?: string;
  fields?: string[];
  statusCode?: number;
}
export interface Response {
  message?: string;
  data?: unknown;
  s3_url?: string;
  id?: any;
}
interface IRequestState {
  data?: Response;
  error?: ErrorResponse;
  isFetching: boolean;
  isFetched: boolean;
  doRequest: (body?: unknown, params?: unknown) => Promise<unknown>;
  hasError: boolean;
}
const INITAL_STATE = {
  data: undefined,
  error: undefined,
  isFetching: false,
  isFetched: false,
  hasError: false,
};
const FETCHING_STATE = {
  isFetching: true,
  isFetched: false,
  hasError: false,
  error: undefined,
};
const FETCHED_STATE = {
  isFetching: false,
  isFetched: true,
  error: undefined,
  hasError: false,
};
const ERROR_STATE = {
  isFetching: false,
  isFetched: true,
  hasError: true,
  data: undefined,
};
const makeUrl = ({url, apiType}: any) => {
  const path = apiType == 'music' ? '/music-platform/api' : apiType == 'finance' ? '/finance/api' : '';
  const baseUrlBackend = `${baseUrl(!!path.length)}${path}`;
  return baseUrlBackend + url;
};
const handleError = async (requestState: any, setRequestState: any, error: any) => {
  if (JSON.parse(JSON.stringify(error))?.message?.includes('401')) {
    const newState: any = {...requestState, ...ERROR_STATE, error: {message: 'Refreshing token'}};
    setRequestState(newState);
    const session: any = await getSession();
    if (session) {
      window.location.reload();
    } else {
      logOut();
    }
  } else {
    const newState = {...requestState, ...ERROR_STATE, error: error?.response?.data};
    setRequestState(newState);
    return;
  }  
};
const useRequest = ({url, method = 'GET', type}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  // console.log('prije try catch', baseUrl()+url);
  const doRequest = useCallback(async (body = {}, params = {}) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = localStorage.getItem('token');
      const config = {
        headers: {Authorization: `Bearer ${token}`},
        params,
      };
      const requestOptions = {method, data: body};

      const {data} = await axios(makeUrl({url, apiType: type}), {...requestOptions, ...(token? config : {})});
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error);
      handleError(requestState, setRequestState, error);
    }
  }, []);
  return {...requestState, doRequest};
};

export const useUploadRequest = ({url, method='POST'}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  const doRequest = useCallback(async (body) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = localStorage.getItem('token');
      const config = {
        headers: {Authorization: `Bearer ${token}`},
      };

      const fd = new FormData();
      fd.append(body.file.key, body.file.value, body.file.fileName);
      fd.append(body.linksUser.key, body.linksUser.id);

      const requestOptions = {method, data: fd};

      const {data} = await axios(makeUrl({url}), {
        ...requestOptions,
        ...(token ? config : {}),
      });
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error); 
      handleError(requestState, setRequestState, error);
    }
  }, []);
  return {...requestState, doRequest};
};

export const useUploadRequestForMusic = ({url, method='POST'}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  const doRequest = useCallback(async (body) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = localStorage.getItem('token');
      // const config = {};
      const config = {
        headers: {Authorization: `Bearer ${token}`},
      };

      const fd = new FormData();
      fd.append('file', body.file);

      const requestOptions = {method, data: fd};

      const {data} = await axios(makeUrl({url, apiType: 'music'}), {
        ...requestOptions,
        ...(token ? config : {}),
      });
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error);
      handleError(requestState, setRequestState, error);
    }
  }, []);
  return {...requestState, doRequest};
};

export const useRequestMusic = ({url, method = 'GET'}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  // console.log('prije try catch', baseUrl()+url);
  const doRequest = useCallback(async (body) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = localStorage.getItem('token');
      // const config = {};
      const config = {
        headers: {Authorization: `Bearer ${token}`},
      };
      const requestOptions = {method, data: body};

      const {data} = await axios(makeUrl({url, apiType: 'music'}), {...requestOptions, ...(token? config : {})});
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error);
      handleError(requestState, setRequestState, error); 
    }
  }, []);
  return {...requestState, doRequest};
};

// Interceptor for API 
axios.interceptors.response.use((response: any) => {
  return response;
}, (error: any) => {
  return Promise.reject(error);
});

export default useRequest;
