export const baseUrl = (shortened=false) => {
  // this comment is really importantt
  const local = false; // change to false for remote backend URL
  if (local && !shortened) return process.env.REACT_APP_BASE_URL_LOCAL;
  else if (!local && !shortened) return process.env.REACT_APP_BASE_URL;
  else if (local && shortened) return process.env.REACT_APP_BASE_SHORT;
  else if (!local && shortened) return process.env.REACT_APP_BASE_SHORT_REMOTE;
};

export const cognitoConfig = () => {
  return {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
  };
};
