import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../features/Helper/imageCropper';
import styles from '../ImageCrop/ImageCrop.module.scss';
import {useSelector} from 'react-redux';
import {useUploadRequest} from '../../features/API/request';

interface IProps{
    imgSrc: any
    setIsOpen: (arg:boolean) => void,
    type: string,
    setImageUrl?: (arg:any) => void,
  }


const ImageCrop = ({imgSrc, setIsOpen, type, setImageUrl}: IProps) => {
  const [imageSrc, setImageSrc] = useState(imgSrc);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCropppedAreaPixels] = useState({});

  const aspect= 2 / 2;
  const minZoom = 0.44;
  const maxZoom = 5;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [cropShape, setCropShape] = useState<any>();

  useEffect(() => {
    if (type === 'profile') {
      setWidth(200);
      setHeight(200);
      setCropShape('round');
    } else if (type === 'theme') {
      setWidth(247);
      setHeight(425);
      setCropShape('rect');
    } else if (type === 'video') {
      setWidth(248);
      setHeight(248);
      setCropShape('rect');
    }
  }, [type]);

  const cropSize = {
    width: width,
    height: height,
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropChange = (crop:any) => {
    setCrop(crop);
  };
  const onZoomChange = (zoom:any) => {
    setZoom(zoom);
  };

  const onRotationChange = (rotation:any) => {
    setRotation(rotation);
  };

  const increaseZoom = () =>{
    zoom < maxZoom ? setZoom(zoom + 1) : ' ';
  };

  const descreaseZoom = () =>{
    zoom > minZoom ? setZoom(zoom - minZoom) : ' ';
  };

  const onUploadAvatar = async (event: any) => {
    try {
      const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation,
      );
      if (setImageUrl) {
        setImageUrl(croppedImage);
        setIsOpen(false);
      } else {

      }
    } catch (e) {
      // console.error(e);
    }
  };

  return (
    <div className={styles.container}>
      {imageSrc && (
        <Fragment>
          <div className={styles.crop_container}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
              aspect={aspect}
              cropShape={cropShape}
              rotation={rotation}
              showGrid={false}
              cropSize={cropSize}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              onRotationChange={onRotationChange}
              classes={{cropAreaClassName: styles.cropArea, containerClassName: styles.cropContainer, mediaClassName: styles.image}}
              style={{cropAreaStyle: {border: '3px solid #fff'}}}
              objectFit={'horizontal-cover'}
            />
          </div>
          <div className={styles.controls}>
            <div className={styles.plus}>
              <p onClick={increaseZoom}>+</p>
            </div>
            <div className={styles.minus}>
              <p onClick={descreaseZoom}>-</p>
            </div>
          </div>
        </Fragment>
      )}
      <div className={styles.buttons_wrapper}>
        <div className={styles.cancel_button} onClick={() => setIsOpen(false)}>
            Cancel
        </div>
        <div className={styles.save_button}
          onClick={onUploadAvatar}
        >
            Accept
        </div>
      </div>
    </div>
  );
};

export default ImageCrop;
