import React, {useState, useEffect} from 'react';
import styles from './NotificationButton.module.scss';
import bell from '../../assets/images/bell-icon.svg';
import WhiteRoundButton from '../WhiteRoundButton/WhiteRoundButton';
import {selectNotificationCount} from '../../features/stores/notificationSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {selectMe} from '../../features/stores/userSlicer';
import {THEMES} from '../../constants/types';
import {ReactComponent as Bell} from '../../assets/images/bell-icon.svg';
import {BLUE_COLOR, DARKTHEMEMAINGREY} from '../../constants/colors';

type NotificationButtonProps = {
  onClick: ()=>void;
  style?: object;
}// #091d60
const NotificationButton = ({onClick, style}:NotificationButtonProps)=>{
  const notificationCount = selectNotificationCount(useAppSelector(store.getState));
  const me = selectMe(useAppSelector(store.getState)).me;

  return (<WhiteRoundButton onClick={onClick} style={{...style, position: 'relative', ...(me.theme === THEMES.DARK && {boxShadow: 'none'})}}>
    {notificationCount !== 0 && <div className={styles.badge}>{notificationCount}</div>}
    <Bell fill={me.theme === THEMES.LIGHT ? BLUE_COLOR : DARKTHEMEMAINGREY}
      stroke={me.theme === THEMES.LIGHT ? BLUE_COLOR : DARKTHEMEMAINGREY}></Bell>
  </WhiteRoundButton>);
};
export default NotificationButton;
