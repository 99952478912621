import React, {useState} from 'react';
import {onChangeField} from '../../features/util';
import useWindowWidth from '../../hooks/useWindowWidth';
import style from './TextField.module.scss';
import 'font-awesome/css/font-awesome.min.css';


type TextFieldProps = {
    type: string;
    object?: any;
    setObject?: Function;
    name: string;
    placeholder?: string;
    styles?: object;
    inputStyles?: object;
    isNumber?:boolean;
    value?: any;
    onKeyPress? :(e:any)=>void;
    onChange?: Function;
    eyeStyle?: any;
    children?: JSX.Element;
    disabled?: boolean;
    onBlur?: (e:any)=>void;
}
const TextField = ({type, children, object, eyeStyle, setObject, name, placeholder, styles, inputStyles, value, isNumber=false, onKeyPress, onChange, disabled=false, onBlur}:TextFieldProps)=>{
  const isMobile = useWindowWidth() <= 767;
  const [isShowClicked, setIsShowClicked] = useState(false);
  return (
    <div className={style.fieldContainer}
      style={{...(isMobile && {height: '48px'}), ...(disabled && {background: '#F3F3F3'}), ...styles}}>
      {type !== 'multiline'?

      <>

        <input style={{
          ...inputStyles,
        }} className={style.field}
        disabled={disabled}
        type={type === 'password' ? (isShowClicked ? 'text' : 'password') : type}
        name={name}
        value={value}
        onChange={(e)=>{
          setObject && onChangeField(e, setObject, isNumber);
          onChange && onChange(e.target.value);
        }}
        onBlur={(e)=>{
          onBlur && onBlur(e.target.value);
        }}
        placeholder={placeholder}
        onKeyPress={(e) => {
          if (onKeyPress) {
            onKeyPress(e);
          }
        }}/>
        {type === 'password' ? <button type='button' style={{...eyeStyle, fontSize: '20px'}} className={`${isShowClicked ?'fa fa-eye fa-eye-slash' :'fa fa-eye'} ${style.eye}`} onClick={()=>setIsShowClicked(!isShowClicked)}></button> : <></>}
      </> :
        <textarea disabled={disabled} style={{...inputStyles}} className={style.field} name={name} value={value} onChange={(e)=> setObject && onChangeField(e, setObject)} placeholder={placeholder}></textarea>}
      {children}
    </div>
  );
};

export default TextField;
