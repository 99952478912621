import React, {useEffect} from 'react';
import Amplify, {Auth} from 'aws-amplify';
import useRequest from '../API/request';
import {store} from '../../app/store';
import {removeMe, setMe} from '../stores/userSlicer';
import nonHookRequest from '../API/nonHookRequest';
import {transpileModule} from 'typescript';
import {shouldModalOpen} from '../util';
import {cognitoConfig} from './../EnvironmentVariables/enviromentVariables';
import * as bcrypt from 'bcryptjs';

Amplify.configure({
  Auth: {
  userPoolId: cognitoConfig().userPoolId,
  region: 'eu-west-1',
  userPoolWebClientId: cognitoConfig().userPoolWebClientId,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
}});

const checkLogin = () => {
  if (window.location.href.includes('bearerToken')) {
    // const location = window.location.href.split('?bearerToken=');


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('bearerToken');
    // let token = location[1];
    if (token && token.includes('#')) token = token.split('#')[0];
    window.localStorage.setItem(
        'token',
        token ? token : '',
    );

    const navigateTo = urlParams.get('fe_redirect');
    if (navigateTo) window.location.assign(navigateTo);
    else window.history.pushState(null, '', '/');

    return true;
  } else if (localStorage.getItem('token')) {
    const result = getSession(); 
    return result;
  } else {
    return false;
  }
};

const getSession = async () => {
  try {
    const tokens: any = await Auth.currentSession();
    if (tokens && tokens.idToken) {
      window.localStorage.setItem('token', tokens.idToken.jwtToken);
    }
    return true;
  } catch (error: any) {
    return false;
  }  
};

const fetchMe = async () => {
  store.dispatch(setMe({me: null, loginStatus: 'FETCHING'}));
  const request = await nonHookRequest({method: 'GET', url: '/auth/me'});
  if (request) {
    store.dispatch(setMe({me: request, loginStatus: 'LOGGED_IN'}));
  } else {
    store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
    logOut();
  }
};

const refreshMe = async () => {
  const request = await nonHookRequest({method: 'GET', url: '/auth/me'});
  if (request) {
    store.dispatch(setMe({me: request, loginStatus: 'LOGGED_IN'}));
  } else {
    store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
  }
};

const logOut = () => {
  store.dispatch(removeMe());
  localStorage.removeItem('token');
  localStorage.removeItem('navigateTo');
  logoutCognito();
};

const logoutCognito = async () => {
  try {
     await Auth.signOut();
    window.location.href = '/';
  } catch (error: any) {
      console.log('error logout', error);
  }
};


export interface ILogin{
    Bearer: string,
}

const logIn = async (data: ILogin, options:{ to?:string, emailCallback?:Function}) => {
  const {to, emailCallback} = options;
  if (data) {
    localStorage.setItem('token', data.Bearer);
    const request = await nonHookRequest({method: 'GET', url: '/auth/me'});

    if (request) {
      const engagement = await nonHookRequest({method: 'PUT', url: '/platforms/checkUpdates'});
      // temporary
      // if (request.isFirstLogin) {
      //   // update firstLogin field
      //   // const updateUserRequest = await nonHookRequest({method: 'PUT', url: '/user/'+request.id, body: {
      //   //   isFirstLogin: false,
      //   // }});

      //   window.location.assign('/onboarding');
      // }
      // do not delete
      const navigateToHash = window.localStorage.getItem('navigateTo');
      // if (navigateToHash && bcrypt.compare('onboarding', navigateToHash)) window.location.assign('/onboarding');
      // else if (to) window.location.href = to;


      store.dispatch(setMe({me: request, loginStatus: 'LOGGED_IN'}));
    // } 
    // else if (request && !request.confirmed) {
    //   store.dispatch(setMe({me: null, loginStatus: 'EMAIL_UNCONFIRMED'}));
    //   // remove bearer
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('navigateTo');
    //   if (emailCallback) emailCallback();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('navigateTo');
      store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
    }
  }
};

const loginCognito = async (payload: any) => {
  try {
    const user = await Auth.signIn(payload.email, payload.password);
    return user;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

export {checkLogin, logIn, logOut, fetchMe, refreshMe, loginCognito, getSession};
