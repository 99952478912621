import React from 'react';
import styles from './WhiteRoundButton.module.scss';

type Props = {
    style?:object;
    children?:any;
    onClick?: ()=>void;
}

const WhiteRoundButton = (props: Props)=>{
  const {children, style, onClick} = props;
  return (<div onClick={onClick} style={{...style}} className={`${styles.container} card`} >
    {children}
  </div>);
};

export default WhiteRoundButton;
