import React, {useState, useEffect} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './Datepicker.module.scss';
import {DayPickerSingleDateController, DayPicker} from 'react-dates';
import moment from 'moment';
import OutlineButton from '../OutlineButton/OutlineButton';
import Select from '../Select/Select';
import {BLUE_COLOR} from '../../constants/colors';

type DatepickerProps = {
    date: any;
    setDate: Function;
    onClick?: ()=>void;
    icon?: string;
    style?: object;
    buttonText?: string;
    pickerStyle?: object;
    disable?: boolean;
    format?: string;
    showYear?: boolean;
    allowFuture?: boolean;
    initialDate?: any;
    afterClosePicker?: Function;
    minDate?: any;
}

const Datepicker = ({initialDate, date, setDate, onClick, icon, style, buttonText, pickerStyle, disable, format,
  showYear, allowFuture, afterClosePicker, minDate=null}:DatepickerProps)=>{
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(buttonText ? buttonText : 'This Month');
  const originalDate = initialDate.format(format ? format : 'MMM YYYY');

  useEffect(() => {
    setText(date.format(format ? format : 'MMM YYYY'));
  }, [date]);

  const onDateChange = (date:any) => {
    if (allowFuture) {
      setDate(date.clone());
    } else if (date.month() <= moment().month()) {
      setDate(date.clone());
    } else {
      setDate(moment());
    }
  };

  const handlePrevDate = ()=>{
    if (moment(date.clone()).subtract(1, 'month').isSameOrAfter(moment())) {
      setDate(moment(date.clone()).subtract(1, 'month').startOf('month'));
    }
  };

  const handleNextDate = ()=>{
    const nextDate = moment(date.clone()).add(1, 'month').startOf('month');

    if (allowFuture) {
      setDate(date.clone());
    } else if (nextDate.isSameOrBefore(moment())) {
      setDate(nextDate.clone());
    } else {
      setDate(moment());
    }
  };

  const returnYears = () => {
    const years = [];
    for (let i = moment().year() - 50; i <= moment().add(10, 'years').year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({month, onMonthSelect, onYearSelect}:any) =>{
    const selectStyles={height: '40px', paddingTop: '0', top: '-5px', width: '90px'};
    return ( <div style={{display: 'flex', justifyContent: 'center', position: 'relative', marginBottom: '23px'}}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
          style={{...selectStyles, position: 'absolute', left: '50px'}}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select style={{...selectStyles, position: 'absolute', left: '140px'}} value={month.year()} onChange={(e) => {
          onYearSelect(month, e.target.value);
          const monthPadding = month.month().toString().length === 1 ? '0' : '';
          const dayPadding = month.date().toString().length === 1 ? '0' : '';
          const newDate = dayPadding + month.date() + '-' + monthPadding + month.month() + '-' +e.target.value;
          //  console.log('date s godinom', moment(newDate, 'DD-MM-YYYY'));
          setDate(moment(newDate, 'DD-MM-YYYY'));
        }}>
          {returnYears()}
        </select>
      </div>
    </div>);
  };

  const onFocusChange = ({focused}:any)=>{
    setIsFocused(focused);
  };

  return (
    <div onClick={()=>{
      if (afterClosePicker && isPickerOpen && initialDate.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
        afterClosePicker();
      }
      setIsPickerOpen(!isPickerOpen);
    }}>
      <OutlineButton onClick={onClick}
        text={text}
        icon={isPickerOpen ? '' : icon}
        style={{...style}}
        textStyle={{...(originalDate !== text && {color: BLUE_COLOR})}}
        disable={disable}
      >
        <>{isPickerOpen && <div style={{fontSize: '12px', textAlign: 'right'}}>Save</div>}</>
      </OutlineButton>
      <div className={styles.pickerWrapper} style={{
        ...pickerStyle,
        ...(isPickerOpen && !disable ? {visibility: 'visible'}:{visibility: 'hidden'}),

      }}>
        <DayPickerSingleDateController
          onDateChange={onDateChange}
          onFocusChange={onFocusChange}
          focused={isFocused}
          date={date.clone()}
          initialVisibleMonth={()=>date.clone().startOf('month')}
          hideKeyboardShortcutsPanel
          onPrevMonthClick={handlePrevDate}
          onNextMonthClick={handleNextDate}
          renderMonthElement={renderMonthElement}
          navNext={date.clone().add(1, 'month').isSameOrAfter(moment()) && !allowFuture ? <button hidden={true}>Next</button> : '' }
          isOutsideRange={(momentDate)=>{
            if ((momentDate.isAfter(moment()) && !allowFuture) || (momentDate.isBefore(moment(minDate)))) {
              return true;
            }
            return false;
          }}
        />
      </div>
    </div>
  );
};

export default Datepicker;
