import React from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import style from './PublicFooter.module.scss';

const PublicFooter = ()=>{
  const isMobile = useWindowWidth() <= 767;

  const handleClick = (type: string) => {
      window.location.href = type == 'privacy' ? 'https://app.al.fan/privacy-policy' : 'https://app.al.fan/terms-and-condition';
  };

  return (
    <div className={style.container}>
      <div onClick={() => handleClick('privacy')}>
          Privacy Policy
      </div>
      <div onClick={() => handleClick('terms')}>
          Terms & Conditions 
      </div>
    </div>
  );
};

export default PublicFooter;
