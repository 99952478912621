import {configureStore, ThunkAction, Action, getDefaultMiddleware} from '@reduxjs/toolkit';
import userReducer from '../features/stores/userSlicer';
import notificationReducer from '../features/stores/notificationSlicer';
import websocketReducer from '../features/stores/websocketSlicer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notifications: notificationReducer,
    websocket: websocketReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
