import React, {useEffect, useState} from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {PURPLE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import useRequest from '../../features/API/request';
import {parseAgeGroupGender} from '../../features/Parsers/YoutubeParsers';
import {selectMe, updateDemographics} from '../../features/stores/userSlicer';
import {capitalizeFirstLetter} from '../../features/util';

type BarChartProps = {
  data: any;
  height: number;
  isAnimated: boolean;
  tooltip: boolean;
  grey?: boolean;
  showLegend?: boolean;
  barWidth?: number;
  showPercentage?: boolean;
  handleFormater?: (value: any) => void;
  currency?: string;
}


const RechartBar = ({data, height, isAnimated, tooltip, grey, showLegend=true, barWidth, showPercentage=true, handleFormater, currency=''}:BarChartProps) => {
  const showBar = false;
  const yTickOffset=55;
  const me = selectMe(useAppSelector(store.getState)).me;

  return (
    <ResponsiveContainer height={height} width={'100%'} minWidth={290}>
      <BarChart
        data={data}
        margin={{
          top: 45,
        }}
      >
        <XAxis dataKey="name" dy={6} axisLine={false} style={{fontSize: 12}} tick={{fill: '#bbbfd2'}} tickLine={false}/>
        <YAxis
          dx={-yTickOffset+30}
          style={{fontSize: 12}}
          tick={{fill: '#bbbfd2'}}
          tickLine={false}
          axisLine={false}
          tickMargin={-20}
          tickFormatter={(tick)=>tick + (showPercentage ? '%' : '')}
        />

        {tooltip && <Tooltip cursor={{fill: '#ffffff'}} formatter={(value: any) => handleFormater ? `${currency} ${handleFormater(value)}` : `${currency} ${value}`} />}

        {showLegend && <Legend wrapperStyle={{top: 10, left: 10}} layout='horizontal' align='left' formatter={(value, entry, index) => {
          return <span style={{color: 'black'}}>{capitalizeFirstLetter(value)}</span>;
        }}></Legend>}

        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Bar
          barSize={barWidth || 12}
          radius={[10, 10, 10, 10]}
          dataKey="value" background={{fill: me.theme === THEMES.LIGHT ? '#f2f3f6' : PURPLE_COLOR, ...(me.theme === THEMES.DARK && {opacity: 0.2})}} fill={grey ? '#101111': '#ec1e24d6'} isAnimationActive={isAnimated} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RechartBar;
