import React, {useRef, useState} from 'react';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import style from './addtooltip.module.scss';

const addTooltip = (
    Component: React.ElementType,
    Tooltip: React.ElementType,
    activation: 'HOVER' | 'CLICK',
    position?: 'TOP' | 'BOTTOM' | 'LEFT' | 'RIGHT') => {
  const MyComp = ({...props}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipPosition = (position === 'BOTTOM' && {top: 'calc(100% + 10px)'}) || (position === 'LEFT' && {right: 'calc(100% + 10px)'}) || (position === 'RIGHT' && {left: 'calc(100% + 10px)'}) || {bottom: 'calc(100% + 10px)'};
    const node = useRef(null);
    if (setShowTooltip) useOutsideAlerter(node, setShowTooltip);
    return (
      activation && <div className={style.container} ref={node}>
        {showTooltip && <div className={style.tooltip} style={tooltipPosition} ><Tooltip></Tooltip></div>}
        <div onClick={ () => {
          activation === 'CLICK' && setShowTooltip(!showTooltip);
        }}
        onMouseEnter={() => {
          activation === 'HOVER' && setShowTooltip(true);
        }}
        onMouseLeave={() => {
          activation === 'HOVER' && setShowTooltip(false);
        }} >
          <Component {...props} />
        </div>
      </div>
    );
  };
  return MyComp;
};

export default addTooltip;
