/* eslint-disable require-jsdoc */
import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAppSelector} from './app/hooks';
import LoggedSwitch from './features/Login/loggedSwitch';
import PublicSwitch from './features/Login/publicSwitch';
import {store} from './app/store';
// import './style/global.module.scss';
import {selectMe, updateLinkAccountModal} from './features/stores/userSlicer';
import {checkLogin, fetchMe} from './features/Login/login';
import WebSocket from './features/Websocket/websocket';
import {baseUrl} from './features/EnvironmentVariables/enviromentVariables';
import {shouldModalOpen} from './features/util';
import useRequest from './features/API/request';
import {selectNotifications, setNotifications} from './features/stores/notificationSlicer';
import {selectWebSocket} from './features/stores/websocketSlicer';

import {ThemeProvider} from 'styled-components';
import {GlobalStyles} from './components/Themes/GlobalStyles';
import {lightTheme, darkTheme} from './components/Themes/Themes';
import {THEMES} from './constants/types';
import {isTemplateHead} from 'typescript';

function App() {
  const user = selectMe(useAppSelector(store.getState));
  const notificationRequest = useRequest({url: '/notifications', method: 'GET'});
  const notifications = selectNotifications(useAppSelector(store.getState));
  const socket = selectWebSocket(useAppSelector(store.getState));
  const checkForUpdatesRequest = useRequest({url: '/platforms/checkUpdates', method: 'PUT'});
  const isThemeChanged = selectMe(useAppSelector(store.getState)).isThemeChanged;

  if (checkLogin() && !user.me && user.loginStatus === 'LOGGED_OUT') {
    fetchMe();
  }

  const fetchUserNotifications = async ()=>{
    const result = await notificationRequest.doRequest();
    store.dispatch(setNotifications({notificationList: result}));
  };

  const checkIfEngagementChanged = async ()=>{
    // const res = await checkForUpdatesRequest.doRequest({
    //   name: null,
    //   options: {
    //     userId: user.me.id,
    //   },
    // }) as any;
    await checkForUpdatesRequest.doRequest();
  };


  useEffect(()=>{
    if (user && user.me) {
      const tokens = {
        instagramRefreshToken: user.me.instagramRefreshToken,
        googleRefreshToken: user.me.googleRefreshToken,
      };
      // checkIfEngagementChanged();
      // if (!socket.websocket) WebSocket.initializeSocketConnection(baseUrl(true), user.me.id);
      // if (!notifications) fetchUserNotifications();
      store.dispatch(updateLinkAccountModal({linkAccountModalOpen: shouldModalOpen(tokens)}));
    }
  }, [user]);

  useEffect(()=>{
    if (isThemeChanged) fetchMe();
  }, [isThemeChanged]);

  return (
    <ThemeProvider theme={user && user.me ? user.me.theme === THEMES.LIGHT ? lightTheme : darkTheme :lightTheme}>
      <>
        <GlobalStyles/>
        <main>
          {user.loginStatus !== 'FETCHING' && <>
            {user.me ?
        <LoggedSwitch /> : <PublicSwitch />}
          </>}
        <ToastContainer position="bottom-right" autoClose={6600} />
        </main>
      </>
    </ThemeProvider>

  );
};

export default App;
