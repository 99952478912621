import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import Button from '../../components/Button/Button';
import CheckBox from '../../components/CheckBox/CheckBox';
import Datepicker from '../../components/Datepicker/Datepicker';
import TextField from '../../components/TextField/TextField';
import WhiteCard from '../../components/WhiteCard/WhiteCard';
import {selectMe, setIsThemeChanged, setNavigatedFromSettings} from '../../features/stores/userSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from './Settings.module.scss';
import ReactFlagsSelect from 'react-flags-select';
import CustomAutocomplete from '../../components/CustomAutocomplete/CustomAutocomplete';
import useRequest from '../../features/API/request';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import {compressImage} from '../../features/Parsers/parsers';
import {ACTIVERED, BLUE_COLOR, DARKTHEMEMAINGREY, DARKTHEME_GREY_1, GREEN_COLOR, GREY_COLOR_13, GREY_COLOR_9, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import PhoneInput from 'react-phone-input-2';
import {fetchMe} from '../../features/Login/login';
import Modal from '../../components/Modal/Modal';
import Switch from 'react-switch';
import darkArrow from '../../assets/images/darkArrow.svg';
import arrow from '../../assets/images/blueArrow.svg';
import * as bcrypt from 'bcryptjs';
import {Country, State, City} from 'country-state-city';
import ConnectedNetwork from '../../components/ConnectedNetwork/ConnectedNetowk';
import Select from '../../components/Select/Select';
import Brands from '../../components/Brands/Brands';
import i from '../../assets/images/icons8-info.svg';
import {determinePrice} from '../../features/util';
import RouteLeavingGuard from '../../components/RouteLeavingGuard/RouteLeavingGuard';

const currency = require('currency-codes');

const Settings = ()=>{
  const PAY_OPTIONS = {
    WIRE: 'wire transfer',
    PAYPAL: 'paypal',
  };
  const me = selectMe(useAppSelector(store.getState)).me;
  const interestRequest = useRequest({url: '/interest', method: 'GET'});
  const updateRequest = useRequest({url: '/user/' + me.id, method: 'PUT'});
  const campaignRequest = useRequest({url: '/campaign', method: 'GET'});

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const textFieldStyle = {...(me.theme === THEMES.LIGHT ? {border: '1px solid ' +GREY_COLOR_13} : {border: '0.5px solid ' + DARKTHEMEMAINGREY}), marginTop: '8px', backgroundColor: 'transparent'};
  const isMobile = useWindowWidth() <= 890;
  const cancelButtonStyle = {
    boxSizing: 'border-box', width: isMobile ? '50%' : '150px', height: '56px', fontSize: '15px',
    ...(me.theme === THEMES.LIGHT ?
      {border: 'solid 1px '+ACTIVERED} : {border: '0px', backgroundColor: DARKTHEME_GREY_1}
    ),
    borderRadius: '8px'};
  const buttonStyle = {...cancelButtonStyle, backgroundColor: ACTIVERED};
  const buttonChildrenStyle={
    ...(me.theme === THEMES.LIGHT ? {color: WHITE_COLOR}:{color: WHITE_COLOR}),
  };
  const pricesStyle = {...textFieldStyle, ...(isMobile ? {height: '35px', width: '100px', padding: '10px'} : {height: '50px', width: '108px'}), marginTop: '14px'};

  const [autocompleteStyle, setAutocompleteStyle] = useState({borderRadius: '8px', ...(isMobile && {height: '56px'})}) as any;

  const [selectedInterest, setSelectedInterest] = useState('');
  const [allInterests, setAllInterests] = useState([]) as any;

  //
  const [selectedBrand, setSelectedBrand] = useState('');
  const [brands, setBrands] = useState([]) as any;

  //
  const [allCampaigns, setAllCampaigns] = useState([]) as any;
  const [campaigns, setCampaigns] = useState(me?.campaigns || []) as any;
  //
  const [age, setAge] = useState(moment(me.dob));
  const [payOption, setPayOption] = useState(PAY_OPTIONS.WIRE);
  const [avatar, setAvatar] = useState([]);

  const [cities, setCities] = useState([]) as any;

  const [validation, setValidaton] = useState({
    message: '',
    color: GREEN_COLOR,
    position: 0,
  });

  const initialBrandData = {
    location: me.location,
    interests: me.interests || [],
    shareWithPartners: me.shareWithPartners,
    brands: me.brands || [],
    campaigns: [],
    prices: me.prices || {},
  };

  const initialBankingData = {
    country: me.country,
    street: me.street,
    unit: me.unit,
    city: me.city,
    postalCode: me.postalCode,
    province: me.province,
    IBAN: me.IBAN,
    bankName: me.bankName,
    swiftCode: me.swiftCode,
    bankAddress: me.bankAddress,
    bankLocation: me.bankLocation,
    currency: me.currency,
    legalName: me.firstName + ' ' + me.lastName,
  } as any;

  const initialPaypalData = {
    firstNamePaypal: '',
    lastNamePaypal: '',
    addressPaypal: '',
    countryPaypal: '',
    phonePaypal: '',
    emailPaypal: '',
  };

  const initialProfileData = {
    firstName: me.firstName,
    lastName: me.lastName,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    dob: me.dob,
    gender: me.gender,
  };

  const [profile, setProfile] = useState(initialProfileData);
  const [wireTransferData, setWireTransferData] = useState(initialBankingData);
  const [brandData, setBrandData] = useState(initialBrandData);
  const [paypalData, setPaypalData] = useState(initialPaypalData);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmLeavePageModalOpen, setConfirmLeavePageModalOpen] = useState(false);

  const compareFn = (a:any, b:any)=>{
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  };

  const fetchData = async ()=>{
    const interests = await interestRequest.doRequest() as any;
    const campaigns = await campaignRequest.doRequest() as any;
    if (interests) setAllInterests(interests.sort(compareFn));
    if (campaigns) {
      setAllCampaigns(campaigns);
    }
  };

  const handleNewInterest = (value:string)=>{
    const element = allInterests.filter((el:any) => el.title === value) as any;
    if (element.length) {
      const isInArray = brandData.interests.filter((b:any)=>b.id === element.id);
      if (!isInArray.length) {
        // interests.push(element[0]);
        const newArray = [...brandData.interests, element[0]];
        setBrandData((prev)=>({
          ...prev,
          interests: newArray,
        }));
      }
    }
  };

  const handleInterestChange = (e:any)=>{
    setSelectedInterest(e.target.value);
  };

  const removeNewInterest = (id:string)=>{
    const newArray = brandData.interests.filter((i:any)=>i.id !== id);
    setBrandData((prev)=>({
      ...prev,
      interests: newArray,
    }));
    // setInterests(newArray);
  };

  const handleCurrencyChange = (e:any)=>{
    // setSelectedCurrency(e.target.value);
    setWireTransferData((prev:any)=>({
      ...prev,
      currency: e.target.value,
    }));
  };

  const handleNewCurrency = (value: string)=>{
    const element = currency.data.filter((el:any) => el.code ===value) as any;
    if (element.length) {
      // setSelectedCurrency(element[0].code);
      setWireTransferData((prev:any)=>({
        ...prev,
        currency: element[0].code,
      }));
    }
  };

  const handleNewCity = (value:string)=>{
    const element = cities.filter((el:any) => el.name ===value) as any;
    if (element.length) {
      setWireTransferData((prev:any)=>({
        ...prev,
        city: element[0].name,
      }));
    }
  };

  const handleCampaignChecked = (e:any, allCampaigns:[], chosenCampaigns: [], setChosenCampaigns: Function)=>{
    const selectedCampaign = e.target.name;
    const campaign = allCampaigns.filter((c:any)=>c.title === selectedCampaign);
    let newArray = [] as any;
    if (e.target.checked) {
      // add to chosenCampaigns
      if (campaign.length) {
        newArray = [...chosenCampaigns, campaign[0]];
        setChosenCampaigns(newArray);
      }
    } else {
      // remove from chosenCampaigns
      newArray = campaigns.filter((c:any)=>c.title !== selectedCampaign);
      setChosenCampaigns(newArray);
    }

    setBrandData((prev:any)=>({
      ...prev,
      campaigns: newArray,
    }));
  };

  const sendProfileData = async ()=>{
    const data = new FormData();

    if (profile.newPassword) {
      // cases
      if (!profile.oldPassword || !profile.confirmPassword) {
        setValidaton({
          message: 'You must fill all password fields in order to change the password.',
          color: ACTIVERED,
          position: 0,
        });
        return;
      } else if (profile.oldPassword && !bcrypt.compareSync(profile.oldPassword, me.password)) {
        setValidaton({
          message: 'Wrong old password.',
          color: ACTIVERED,
          position: 0,
        });
        return;
      } else if (profile.confirmPassword && profile.newPassword !== profile.confirmPassword) {
        setValidaton({
          message: 'Confirmed password and new password do not match.',
          color: ACTIVERED,
          position: 0,
        });
        return;
        // passwords dont match
      } else if (profile.oldPassword && profile.newPassword === profile.oldPassword) {
        // same password
        setValidaton({
          message: 'Your new and old passwords cannot be the same.',
          position: 0,
          color: ACTIVERED,
        });
        return;
      } else {
        data.append('password', profile.oldPassword);
        data.append('newPassword', profile.newPassword);
        data.append('confirmPassword', profile.confirmPassword);
        setValidaton({
          message: '',
          position: 0,
          color: GREEN_COLOR,
        });
      }
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    await Promise.all(
        avatar.map(async (image, index) => {
          // compress
          const compressedImage = await compressImage(image, options);
          data.append('file', compressedImage);
        }),
    );
    data.append('firstName', profile.firstName);
    data.append('lastName', profile.lastName);
    data.append('gender', profile.gender);
    data.append('dob', profile.dob);

    await updateRequest.doRequest(data);
    setShowConfirmModal(true);
  };

  const sendPaypalData = ()=>{
    const finalPaypalData = {...paypalData};
  };

  const sendBrandData = async ()=>{
    const finalBrandData = {...brandData, brands: [...initialBrandData.brands, ...brands]} as any;
    await updateRequest.doRequest(finalBrandData);
    setShowConfirmModal(true);
  };

  const sendBankingData = ()=>{
    delete wireTransferData.city;
    delete wireTransferData.currency;
    const finalBankingData = {
      ...wireTransferData,
    };
    // temporarily remove legalname
    delete finalBankingData.legalName;
    updateRequest.doRequest(finalBankingData);
    setShowConfirmModal(true);
  };

  const changeTheme = async ()=>{
    const newTheme = me.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
    await updateRequest.doRequest({theme: newTheme}).then(() => {
      store.dispatch(setIsThemeChanged({theme: true}));
    });
  };

  const handleNewBrand = ()=>{
    // check for duplicates
    const allBrands = [...initialBrandData.brands, ...brands];
    const isInBrand = allBrands.filter((item)=>item.toUpperCase() === selectedBrand.toUpperCase());
    if (isInBrand.length) {
      // raise error
      setValidaton({
        position: 3,
        color: ACTIVERED,
        message: 'You have already added this brand!',
      });
    } else {
      // add to brands
      setBrands([...brands, selectedBrand]);
      // reset value
      setSelectedBrand('');
    }
  };

  useEffect(()=>{
    if (wireTransferData.country) {
      setCities(City.getCitiesOfCountry(wireTransferData.country));
    }
  }, [wireTransferData]);

  useEffect(()=>{
    fetchData();
    store.dispatch(setIsThemeChanged({theme: false}));
  }, []);

  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(()=>{
    if ((brandData !== initialBrandData || selectedBrand !== '' || profile !== initialProfileData ||
    avatar.length !== 0 || paypalData !== initialPaypalData)) {
      setIsBlocking(true);
    }
  }, [brandData, brands, selectedBrand, profile, avatar, paypalData]);


  return (<div className={styles.container}>
    {/* <RouteLeavingGuard when={isBlocking} navigate={(path:string)=>{
      window.location.assign(path);
    }} shouldBlockNavigation={(location)=>{
      return true;
    }} /> */}
    <div className={styles.upperContainer}>
      <WhiteCard
        style={{padding: isMobile ? '30px 20px 28px 20px' : '44px 32px 32px 32px',
          width: isMobile ? '100%' : '48%', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

        <div>
          <div className={`${styles.title} header`}>Profile Details</div>
          <div className={`${styles.subtitle} subtitle`}>All your profile related info are mentioned below</div>
          <div className={styles.divider}></div>

          <ImageUploader
            multiple={false}
            value={avatar}
            setValue={setAvatar}
            uploadText={'Upload picture'}
            deleteText={'Remove'}
            updateText={'lala'}
          />
          <div className={styles.formContainer}>
            <div className={styles.textfieldLabel}>Your Name</div>
            <TextField
              placeholder={me.firstName}
              styles={textFieldStyle}
              type='text'
              setObject={setProfile}
              name={'firstName'}
            />

            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Your Surname</div>
            <TextField
              placeholder={me.lastName}
              styles={textFieldStyle}
              type='text'
              setObject={setProfile}
              name={'lastName'}
            />


            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Date of birth</div>
            <Datepicker format={'DD/MM/YYYY'}
              style={{
                ...(isMobile ? {fontSize: '13px', width: '100%'}:{fontSize: '15px', width: '100%'}),
                marginTop: '8px', borderRadius: '8px', border: '1px solid #d3d6de', height: '56px',
              }}
              initialDate={moment(me.dob)}
              pickerStyle={{...(isMobile && {left: '50%', marginLeft: '-140px'})}}
              date={age}
              setDate={setAge}
              buttonText={'Your birthday'}
              afterClosePicker={()=>setProfile((prev)=>({
                ...prev,
                dob: age,
              }))}
            />

            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Gender</div>
            <Select
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedOption={profile.gender}
              icon={me.theme === THEMES.LIGHT ? arrow : darkArrow}
              setSelectedOption={(value:any)=>{
                setProfile((prev)=>({
                  ...prev,
                  gender: value,
                }));
              }}
              innerStyle={{
                ...(isMobile && {top: '55px'}),
                width: '100%',
              }}
              style={{
                marginTop: '8px',
                height: '56px',
                fontSize: isMobile ? '13px' : '15px',

              }}
              defaultOptionStyle={{
                ...(profile.gender === me.gender && me.theme === THEMES.LIGHT && {color: GREY_COLOR_9}),
                ...(profile.gender === me.gender && me.theme === THEMES.DARK && {color: DARKTHEMEMAINGREY}),
              }}
              options={[{value: 'male', name: 'Male'}, {value: 'female', name: 'Female'}]}
              title={'Select gender'}
            />


            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Old Password</div>
            <TextField
              placeholder={'Your old password'}
              styles={textFieldStyle}
              type='password'
              setObject={setProfile}
              name={'oldPassword'}
              eyeStyle={{...(me.theme === THEMES.LIGHT ? {color: BLUE_COLOR} : {color: DARKTHEMEMAINGREY})}}
            />

            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>New Password</div>
            <TextField
              placeholder={'Your new password'}
              styles={textFieldStyle}
              type='password'
              setObject={setProfile}
              name={'newPassword'}
              eyeStyle={{...(me.theme === THEMES.LIGHT ? {color: BLUE_COLOR} : {color: DARKTHEMEMAINGREY})}}
            />

            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Confirm Password</div>
            <TextField
              placeholder={'Re-type new password'}
              styles={textFieldStyle}
              type='password'
              setObject={setProfile}
              name={'confirmPassword'}
              eyeStyle={{...(me.theme === THEMES.LIGHT ? {color: BLUE_COLOR} : {color: DARKTHEMEMAINGREY})}}
            />

            <div style={{color: validation.color}}>{validation.message}</div>
            <div style={{display: 'flex', alignItems: 'center', columnGap: 10, marginTop: 10}}>
              <label>Dark Mode</label>
              <Switch
                checked={me.theme === THEMES.DARK}
                onChange={changeTheme}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#ec1e24">
              </Switch>
            </div>
          </div>
        </div>

        <div className={styles.upperRightButtonContainer}>
          <Button
            style={{...buttonStyle}}
            onClick={sendProfileData}
          >
            <div style={buttonChildrenStyle}>Save details</div>
          </Button>
        </div>

      </WhiteCard>

      <WhiteCard
        style={{
          marginLeft: isMobile ? '0px' : '33px',
          marginTop: isMobile ? '24px' : '0px',
          padding: isMobile ? '30px 20px 32px 20px' : '42px 32px 32px 32px',
          width: isMobile ? '100%' : '49%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <div>
          <div className={`${styles.title} header`}>Answer the below questions for brands to better discover your profile.</div>
          <div className={styles.divider}></div>

          <div className={styles.checkboxContainer}>
            <CheckBox style={{marginRight: '20px'}} object={brandData} setObject={setBrandData} name='shareWithPartners' />
            <div className='header' style={{...(isMobile && {maxWidth: '70%'})}}>I accept that you share my data with 3rd party partners for potential advertising &amp; commercial opportunities</div>
          </div>

          <div style={{marginTop: '40px'}}>
            <div className={styles.textfieldLabel} style={{marginTop: '10px'}}>Location</div>
            <ReactFlagsSelect
              selected={brandData.location}
              searchable
              searchPlaceholder={'Enter your location'}
              onSelect={(code) => setBrandData((prev:any)=>({
                ...prev,
                location: code,
              }))}
              className={`
              bank
              ${styles.bankCountry}
              ${brandData.location === me.location && me.theme === THEMES.LIGHT ? styles.autocompletePlaceholder : ''}
              ${brandData.location === me.location && me.theme === THEMES.DARK ? styles.darkAutocompletePlaceholder : ''}
              `}
              placeholder={'Enter your location'}
            />

            <div className={styles.textfieldLabel} style={{marginTop: '23px'}}>Brands you have worked with before</div>
            <div style={{display: 'flex', marginTop: '10px', flexWrap: 'wrap'}}>
              {initialBrandData.brands.map((brand:any)=>
                <Button
                  onClick={()=>{}}
                  inverted
                  style={{marginRight: '6px', width: 'auto', fontSize: '13px', marginTop: '8px', padding: isMobile ? '7px' : '10px',
                    borderRadius: '8px'}} key={brand + 'brand'}>
                  <span>
                    {brand}
                  </span>
                </Button>) }
            </div>

            <div className={styles.textfieldLabel} style={{marginTop: '23px'}}>Add more brands:</div>
            <TextField
              placeholder={'Brand name'}
              styles={{...textFieldStyle, position: 'relative'}}
              type='text'
              name={'selectedBrand'}
              onChange={(value:any)=>{
                setSelectedBrand(value);
                setValidaton({
                  position: 0,
                  message: '',
                  color: GREEN_COLOR,
                });
              }}
              value={selectedBrand}
            >

              <Button style={{border: 'none', width: 'auto', position: 'absolute', right: '10px', top: '19px'}}
                inverted onClick={handleNewBrand}>
                <div style={{color: ACTIVERED}}>Add</div>
              </Button>
            </TextField>
            {validation.position === 3 && validation.message ? <div style={{color: validation.color, marginBottom: '5px', marginTop: '5px'}}>{validation.message}</div> : <></>}
            {brands.map((brand:any)=>
              <Button onClick={()=>{
                const newArray = brands.filter((b:any)=>b !== brand);
                setBrands([...newArray]);
              }}
              inverted
              style={{marginRight: '6px', width: 'auto', fontSize: '13px', marginTop: '8px', padding: isMobile ? '7px' : '10px',
                borderRadius: '8px'}} key={brand}>
                <span>
                  {brand}
                  <span className={styles.close}></span>
                </span>
              </Button>)}

            <div className={`header`} style={{marginTop: '13px'}}>Types of campaigns that you would like shared with you</div>
            <div className={styles.checkboxes}>
              {allCampaigns.map((campaign:any)=>
                <div className={styles.checkbox} key={campaign.id}>
                  <CheckBox object={campaigns}
                    customOnChange={(e)=>handleCampaignChecked(e, allCampaigns, campaigns, setCampaigns)}
                    setObject={setCampaigns}
                    name={campaign.title}
                    customChecked={me?.campaigns?.filter((c:any)=>c.title === campaign.title).length}
                    style={{...(isMobile && {transform: 'scale(0.9)'})}}
                  />
                  <div className={styles.campaign}>
                    <div>{campaign.title.split('_').join(' ')}</div>

                    <div className={`${styles.info}`}>
                      <img key={campaign.id} className={`${styles.i}`} src={i} />
                      <span className={`${styles.infoText} noselect card`}
                        style={{...(me.theme === THEMES.LIGHT && {boxShadow: '1px 7px 15px #e3e6ec'})}}
                      >{campaign.description}</span>
                    </div>
                  </div>

                </div>)}
            </div>

            <div className={`header`} style={{marginTop: '23px'}}>Price per post</div>
            {Object.keys(brandData.prices).map((el, index)=>
              <div className={styles.priceRecommendations} key={index+'price'}>
                <div style={{...(isMobile && {maxWidth: '124px'})}}>{determinePrice(el)}</div>
                <div className={styles.currencyContainer}>
                  <TextField type="text" isNumber={true}
                    value={brandData.prices[el]}
                    name={el}
                    object={brandData.prices}
                    onChange={(value:any)=>
                      setBrandData((prev)=>({
                        ...prev,
                        prices: {
                          ...prev.prices,
                          [el]: value,
                        },
                      }))}
                    placeholder={me.prices[el] ? me.prices[el] : '00.00'}
                    styles={pricesStyle} inputStyles={{fontWeight: 'bold', fontSize: '14px'}}
                  />
                  <div className={styles.currency}>{me.currency ? me.currency : wireTransferData.currency}</div>
                </div>
              </div>,

            )}


            <div className={styles.textfieldLabel} style={{marginTop: '23px'}}>Interests</div>
            {validation.position === 4 && validation.message ? <div style={{color: validation.color, marginBottom: '5px'}}>{validation.message}</div> : <></>}
            <CustomAutocomplete value={selectedInterest} style={{...autocompleteStyle, ...(isMobile && {height: '56px'})}} setStyle={setAutocompleteStyle}
              onChange={(e)=>handleInterestChange(e)} onSelect={(val)=>handleNewInterest(val)}
              items={allInterests} placeholder='Your interests' canSelectMultiple={true} allSelectedItems={brandData.interests}
              setPopUpMessage={setValidaton} validationPosition={4} />

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {brandData.interests.map((interest:any)=><Button onClick={()=>removeNewInterest(interest.id)} inverted
                style={{marginRight: '6px', width: 'auto', fontSize: '13px', marginTop: '8px', padding: isMobile ? '7px' : '10px',
                  borderRadius: '8px'}} key={interest.id}>
                <span>
                  {interest.title}
                  <span className={styles.close}></span>
                </span>
              </Button>)}
            </div>


          </div>
        </div>

        <div className={styles.upperRightButtonContainer}>
          <Button
            style={buttonStyle}
            onClick={sendBrandData}
          >
            <div style={buttonChildrenStyle}>Save details</div>
          </Button>

        </div>
      </WhiteCard>
    </div>

    <WhiteCard
      style={{
        padding: isMobile ? '30px 20px 32px 20px' : '44px 32px 48px 32px',
        boxSizing: 'border-box',
      }}>
      <div className={`${styles.title} header`}>Banking Info</div>
      <div className={`${styles.subtitle} subtitle`}>How would you like to get paid?</div>

      <div className={styles.optionContainer}>
        {Object.values(PAY_OPTIONS).map((option)=>
          <div onClick={()=>setPayOption(option)} key={option} className={payOption === option ? styles.selectedOption : styles.option}>
            {option}
            {payOption === option && <div className={styles.selectedUnderline}></div>}
          </div>,
        )}
      </div>

      <div className={styles.divider}></div>

      {payOption === PAY_OPTIONS.WIRE && <div >

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
            Account Number / IBAN
            <TextField
              placeholder={initialBankingData.IBAN ? initialBankingData.IBAN : 'Enter your account no. '}
              styles={{...textFieldStyle}}
              type='text'
              name={'IBAN'}
              setObject={setWireTransferData}
            />
          </div>
          <div className={styles.lowerTextfieldLabel} style={{
            marginLeft: isMobile ? '0px' : '23px',
          }}>

              Legal Name
            <TextField
              placeholder={initialBankingData.legalName ? initialBankingData.legalName : 'Enter your legal name'}
              styles={{...textFieldStyle}}
              type='text'
              name={'legalName'}
              setObject={setWireTransferData}
            />
          </div>
        </div>


        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
         Street
            <TextField
              placeholder={initialBankingData.street ? initialBankingData.street : 'Enter your street name.'}
              styles={{...textFieldStyle}}
              type='text'
              name={'street'}
              setObject={setWireTransferData}
            />
          </div>

          <div className={styles.lowerTextfieldLabel}
            style={{marginLeft: isMobile ? '0px' : '23px'}}
          >
         Unit number
            <TextField
              placeholder={initialBankingData.unit ? initialBankingData.unit : 'Enter your unit number.'}
              styles={{...textFieldStyle}}
              type='text'
              name={'unit'}
              setObject={setWireTransferData}
            />
          </div>
        </div>


        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
          Province/State
            <TextField
              placeholder={initialBankingData.province ? initialBankingData.province : 'Enter your province.'}
              styles={{...textFieldStyle}}
              type='text'
              name={'province'}
              setObject={setWireTransferData}
            />
          </div>
          <div className={styles.lowerTextfieldLabel} style={{marginLeft: isMobile ? '0px' : '23px'}}>
          Postal code
            <TextField
              placeholder={initialBankingData.postalCode ? initialBankingData.postalCode : 'Enter your postal code.'}
              styles={{...textFieldStyle}}
              type='text'
              name={'postalCode'}
              setObject={setWireTransferData}
            />

          </div>
        </div>

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>

          Country
            <ReactFlagsSelect
              selected={wireTransferData.country}
              searchable
              searchPlaceholder={'Select your country'}
              onSelect={(code) => setWireTransferData((prev:any)=>({
                ...prev,
                country: code,
              }))}
              className={`
              bank
              ${styles.bankCountry}
              ${wireTransferData.country === me.country && me.theme === THEMES.LIGHT ? styles.autocompletePlaceholder : ''}
              ${wireTransferData.country === me.country && me.theme === THEMES.DARK ? styles.darkAutocompletePlaceholder : ''}
              `}
              placeholder={'Select your country'}
            />
          </div>

          <div className={styles.lowerTextfieldLabel} style={{
            marginLeft: isMobile ? '0px' : '23px',
          }}>
            City
            <CustomAutocomplete
              value={wireTransferData.city || ''}
              style={{
                ...autocompleteStyle,
                width: '100%',
              }}
              setStyle={setAutocompleteStyle}
              onChange={(e) => {
                setWireTransferData((prev:any)=>({
                  ...prev,
                  city: e.target.value,
                }));
              }}
              onSelect={(val)=>handleNewCity(val)}
              items={cities}
              placeholder={initialBankingData.city ? initialBankingData.city : 'Select your city.'}
              itemTitle='name'
            />

          </div>
        </div>

        <div className={styles.divider}></div>

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
       Bank Name
            <TextField placeholder={initialBankingData.bankName ? initialBankingData.bankName : 'Enter bank name'} styles={{...textFieldStyle}} type='text' name={'bankName'} setObject={setWireTransferData} />
          </div>
          <div className={styles.lowerTextfieldLabel}
            style={{
              marginLeft: isMobile ? '0px' : '23px',
            }}>
         Swift Code
            <TextField placeholder={initialBankingData.swiftCode ? initialBankingData.swiftCode : 'Enter your swift code'} styles={{...textFieldStyle}} type='text' name={'swiftCode'} setObject={setWireTransferData} />
          </div>
        </div>


        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
       Bank Address
            <TextField placeholder={initialBankingData.bankAddress ? initialBankingData.bankAddress : 'Enter bank address'} styles={{...textFieldStyle}} type='text' name={'bankAddress'} setObject={setWireTransferData} />
          </div>
          <div className={styles.lowerTextfieldLabel}
            style={{
              marginLeft: isMobile ? '0px' : '23px',

            }}>
              Bank Country
            <ReactFlagsSelect
              selected={wireTransferData.bankLocation}
              searchable
              searchPlaceholder={'Select your country'}
              onSelect={(code) => setWireTransferData((prev:any)=>({
                ...prev,
                bankLocation: code,
              }))}
              className={`
              bank
              ${styles.bankCountry}
              ${wireTransferData.bankLocation === me.bankLocation && me.theme === THEMES.LIGHT ? styles.autocompletePlaceholder : ''}
              ${wireTransferData.bankLocation === me.bankLocation && me.theme === THEMES.DARK ? styles.darkAutocompletePlaceholder : ''}
              `}
              placeholder={initialBankingData.bankLocation ? initialBankingData.bankLocation : 'Select or enter'}
            />
          </div>

        </div>

        <div className={styles.divider}></div>

        <div className={styles.textfieldLabel} style={{marginTop: '23px'}}>Currency</div>
        <CustomAutocomplete
          value={wireTransferData.currency || ''}
          style={{
            ...autocompleteStyle,
            width: isMobile ? '100%' : 'calc(50% - 10px)',
          }} setStyle={setAutocompleteStyle}
          onChange={(e) => handleCurrencyChange(e)}
          onSelect={(val)=>handleNewCurrency(val)}
          items={currency.data}
          placeholder={initialBankingData.currency ? initialBankingData.currency : 'Select your currency'}
          itemTitle='code' />

        <div className={styles.lowerLeftButtonContainer}>

          <Button
            style={buttonStyle}
            onClick={sendBankingData}
          >
            <div style={buttonChildrenStyle}>Save details</div>
          </Button>

        </div>

      </div>}


      {payOption === PAY_OPTIONS.PAYPAL && <div>

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
              First name
            <TextField
              placeholder={'Enter first name'}
              styles={{...textFieldStyle}}
              type='text'
              name={'firstNamePaypal'}
              setObject={setPaypalData}
            />
          </div>
          <div className={styles.lowerTextfieldLabel} style={{
            marginLeft: isMobile ? '0px' : '23px',
          }}>
              Last Name
            <TextField
              placeholder={'Enter last name'}
              styles={{...textFieldStyle}}
              type='text'
              name={'lastNamePaypal'}
              setObject={setPaypalData}
            />
          </div>
        </div>

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
         Paypal email
            <TextField
              placeholder={'Your paypal email '}
              styles={{...textFieldStyle}}
              type='text'
              name={'emailPaypal'}
              setObject={setPaypalData}
            />
          </div>
          <div className={styles.lowerTextfieldLabel}
            style={{
              marginLeft: isMobile ? '0px' : '23px',
            }}
          >
         Address
            <TextField placeholder={'Select or enter your address'}
              styles={{...textFieldStyle}} type='text' name={'addressPaypal'} setObject={setPaypalData} />
          </div>
        </div>

        <div className={styles.lowerSectionPart}>
          <div className={styles.lowerTextfieldLabel}>
       Phone number
            <div style={{marginTop: '8px'}}>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                specialLabel=''
                country={'us'}
                value={paypalData.phonePaypal}
                onChange={(p)=>
                // setPhone(p)
                  setPaypalData((prev)=>({
                    ...prev,
                    phonePaypal: p,
                  }))
                }
                inputStyle={{
                  width: '100%',
                  background: 'transparent',
                  border: me.theme === THEMES.DARK ? '1px solid ' + DARKTHEMEMAINGREY : '1px solid ' + GREY_COLOR_13,
                  height: isMobile ? '48px':'56px',
                  borderRadius: '10px',
                  paddingLeft: '58px',
                }}

                buttonStyle={{borderRadius: '10px', borderColor: 'transparent', backgroundColor: 'transparent', paddingLeft: '15px'}}
                buttonClass={styles.phoneButton}
                enableAreaCodes
              />
            </div>
          </div>
          <div className={styles.lowerTextfieldLabel}
            style={{
              marginLeft: isMobile ? '0px' : '23px',

            }}>
             Country
            <ReactFlagsSelect
              selected={paypalData.countryPaypal}
              searchable
              searchPlaceholder={'Select your country'}
              onSelect={(code) => setPaypalData((prev:any)=>({
                ...prev,
                countryPaypal: code,
              }))}
              className={`${styles.bankCountry} bank`}
              placeholder={'Select or enter'}
            />
          </div>
        </div>

        <div className={styles.lowerLeftButtonContainer}>

          <Button
            style={buttonStyle}
            onClick={sendPaypalData}
          >
            <div style={buttonChildrenStyle}>Save details</div>
          </Button>

        </div>
      </div>}

    </WhiteCard>
    <WhiteCard
      style={{
        padding: isMobile ? '30px 20px 32px 20px' : '44px 32px 48px 32px',
        boxSizing: 'border-box',
        marginTop: 30,
      }}>
      <div className={`${styles.title} header`}>Connected Accounts</div>
      <div className={styles.divider}></div>
      <div className={styles.connectedNetworkContainer}>
        {me?.counters.map((el: any, index: number) => {
          return <ConnectedNetwork key={index}
            type={el.type}
            views={el.views}
            followers={el.followers}
            id={el.id}
            createdAt={el.createdAt}
            theme={me.theme}
            username={el.username}
            channelName={el.channelName}
            visible={el.visible}
            token={el.token}></ConnectedNetwork>;
        })}

      </div>
    </WhiteCard>
    <Modal handleClose={()=>{
      fetchMe();
      setShowConfirmModal(false);
    }} show={showConfirmModal} title={''} >
      <div>Settings successfully changed!</div>
    </Modal>
    <Modal show={confirmLeavePageModalOpen} handleClose={()=>{
      setConfirmLeavePageModalOpen(false);
    }}>
      <div>If you leave this page, the changes you have made will not be saved. What would you like to do?</div>
    </Modal>
  </div>);
};

export default Settings;
