import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {ACTIVERED, DARKTHEMEMAINGREY, DARKTHEME_BLACK, DARKTHEME_GREY_1, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import Button from '../Button/Button';
import DefaultIcon from '../DefaultIcon/DefaultIcon';
import ImageCrop from '../ImageCrop/ImageCrop';
import ModalCrop from '../ModalCrop/ModalCrop';
import TextField from '../TextField/TextField';
import styles from './ImageUploader.module.scss';

type ImageUploaderProps = {
    multiple: boolean;
    value: any[];
    setValue: Function;
    uploadText?: string;
    deleteAllText?: string;
    updateText?: string;
    deleteText?:string;
    icon?: string;
}

const ImageUploader = ({
  multiple,
  value,
  setValue,
  uploadText,
  deleteAllText,
  updateText,
  deleteText,
  icon,
}:ImageUploaderProps) => {
  const me = selectMe(useAppSelector(store.getState)).me;

  const onUploadImages = (imageList:any[]) => {
    setValue(imageList);
    setImgSrc(imageList[0].data_url);
    setFileName(imageList[0].file.name);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [fileName, setFileName] = useState('');
  const isMobile = useWindowWidth() <= 890;

  useEffect(() => {
    setImgSrc(value[0]?.data_url || '');
  }, []);


  const dataURLtoFile = (dataurl: any, filename: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; // to make eslint happy
    }
    return new File([u8arr], filename, {type: mime});
  };

  useEffect(() => {
    if (imgSrc && fileName) {
      onUploadImages([{file: dataURLtoFile(imgSrc, fileName), data_url: imgSrc}]);
    }
  }, [imgSrc]);

  return (
    <ImageUploading
      multiple={false}
      value={value}
      onChange={(e: any) => {
        onUploadImages(e); setIsOpen(true);
      }}
      maxNumber={10}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
      }) => (
        <div>
          {me.avatar && !imageList.length && <img className={styles.icon} src={me.avatar} /> }
          {!me.avatar && !imageList.length && <DefaultIcon style={{height: '104px', width: '104px', borderRadius: '50%', marginTop: '32px', fontSize: '40px'}} name={me.firstName} surname={me.lastName} color={me.defaultAvatar} />}

          {imageList.map((image, index) => (
            <div key={index}>
              <img src={image.data_url} alt="" className={styles.icon} />
                &nbsp;
            </div>
          ))}

          <div className={styles.buttonContainer}>
            <Button style={{marginRight: isMobile ? '15px' : '20px', width: isMobile ? '133px' : '150px', fontSize: isMobile ? '14px' : '15px', height: '48px', borderRadius: '8px'}}
              onClick={onImageUpload}>
              <div style={{...(me.theme === THEMES.DARK ? {color: WHITE_COLOR}:{color: WHITE_COLOR})}}>{uploadText}</div>
            </Button>
            <Button
              style={{
                fontSize: isMobile ? '14px' : '15px', width: isMobile ? '133px' : '150px', height: '48px',
                ...(me.theme === THEMES.DARK && {backgroundColor: DARKTHEME_GREY_1}),
                borderRadius: '8px'}}
              onClick={()=>{
                onImageRemove(0);
                setValue([]);
              }}
              inverted={me.theme === THEMES.LIGHT}
            >
              <div style={{...(me.theme === THEMES.DARK ? {color: WHITE_COLOR}:{color: ACTIVERED})}}>Remove</div>
            </Button>
            {isOpen && <ModalCrop setIsOpen={setIsOpen} imgSrc={imgSrc} type={'profile'} setImageUrl={setImgSrc}/>}
          </div>
        </div>

      )}
    </ImageUploading>
  );
};

export default ImageUploader;
