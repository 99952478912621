import {ACTIVERED, BLACK_COLOR_3, BLUE_COLOR, DARKTHEMEMAINGREY, DARKTHEME_BLACK, DARKTHEME_BLACK_1, DARKTHEME_BLACK_2, DARKTHEME_GREY_1, DARKTHEME_GREY_2, GREYTEXTFIELDCOLOR2, GREY_COLOR_10, GREY_COLOR_11, GREY_COLOR_13, GREY_COLOR_17, GREY_COLOR_9, WHITE_COLOR} from '../../constants/colors';

export const lightTheme = {
  background: WHITE_COLOR,
  bodyTextColor: BLUE_COLOR,
  divLinkColor: BLUE_COLOR,
  aHoverColor: BLUE_COLOR,
  nestedDivColor: BLUE_COLOR,
  placeholderColor: GREYTEXTFIELDCOLOR2,
  inputTextColor: BLUE_COLOR,
  headerColor: BLUE_COLOR,
  chipTitleColor: '#808191',
  cardBackground: WHITE_COLOR,
  cardBorder: GREY_COLOR_10,
  switchButtonColor: '#eeeff4',
  switchButtonBorder: '#eeeff4',
  bottomTitle: '#69BBF8',
  subtitleColor: GREY_COLOR_9,
  flagsSelectBorder: GREY_COLOR_17,
  flagsSelectArrow: BLUE_COLOR,
  flagsSelectText: GREY_COLOR_9,
  flagsSelectListBackground: WHITE_COLOR,
  pickerBackground: WHITE_COLOR,
  highlightedDateColor: '#ee5656',
  blockedDateColor: GREY_COLOR_17,
  buttonDisabled: '#d1dce8',
  disabledTextColor: GREY_COLOR_10,
  musicHeader: WHITE_COLOR,
  scrollbarColor: GREY_COLOR_10,
  notificationBackground: '#f7f8fa',
  notificationFont: GREY_COLOR_9,
};
export const darkTheme = {
  background: '#191919',
  bodyTextColor: '#fafafa',
  divLinkColor: '#9c9c9c',
  aHoverColor: '#9c9c9c',
  nestedDivColor: '#9c9c9c',
  placeholderColor: '#fafafa',
  inputTextColor: '#fafafa',
  headerColor: '#fafafa',
  chipTitleColor: 'white',
  cardBackground: '#1d1d1d',
  cardBorder: '#1d1d1d',
  switchButtonColor: '#222222',
  switchButtonBorder: '#313131',
  bottomTitle: '#918ef7',
  subtitleColor: '#9c9c9c',
  flagsSelectBorder: DARKTHEMEMAINGREY,
  flagsSelectArrow: DARKTHEMEMAINGREY,
  flagsSelectText: DARKTHEMEMAINGREY,
  flagsSelectListBackground: DARKTHEME_BLACK,
  pickerBackground: DARKTHEME_BLACK,
  blockedDateColor: DARKTHEME_GREY_1,
  highlightedDateColor: '#a21c1c',
  buttonDisabled: DARKTHEME_BLACK_2,
  disabledTextColor: DARKTHEME_BLACK_1,
  musicHeader: WHITE_COLOR,
  scrollbarColor: '#000000',
  notificationBackground: '#222222',
  notificationFont: '#9c9c9c',
};
