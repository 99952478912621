export const secondsToHms = (second: any) => {
    second = Number(second);
    const h = Math.floor(second / 3600);
    const m = Math.floor(second % 3600 / 60);
    const s = Math.floor(second % 3600 % 60);

    const hDisplay = h > 0 ? h + ":" : '';
    const mDisplay = m > 0 ? m + ":" : h > 0 ? ':00' : '';
    const sDisplay = s > 0 ? s : (h > 0 || m > 0 ? ':00' : '');
    return hDisplay + mDisplay + sDisplay + (h > 0 ? 'hour' : m > 0 ? 'min' : 'sec' ); 
};

export const getSongCategory = (duration: any) => {
    duration = Number(duration);
    let type = 'SINGLE';
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration % 3600 / 60);
    const s = Math.floor(duration % 3600 % 60);
    if (h > 0 || (h == 0 && m >= 30)) {
        type = 'ALBUM';
    } else if (h == 0 && m > 10 && m < 30) {
        type = 'EP';
    } else {
        type = 'SINGLE';
    }
    return type;
};
