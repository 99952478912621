import React, {useRef} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {selectMe} from '../../features/stores/userSlicer';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import styles from './Modal.module.scss';

type ModalProps = {
    handleClose?: ()=>void;
    show: boolean;
    children?: any;
    title?:string;
    setShow?: Function;
}

const Modal = ({handleClose, show, children, title, setShow}:ModalProps) => {
  const node = useRef(null);
  if (setShow) useOutsideAlerter(node, setShow);
  return (
      show ?
    <>
      <div style={{...(show ? {display: 'block'} : {display: 'none'})}} className={styles.modal}>
      </div>
      <section ref={node} className={`${styles.modalMain} card`}>
        <div className={styles.title}>
          <div>
            {title}
          </div>
          {handleClose && <div onClick={handleClose} className={styles.close}>x</div>}
        </div>
        <div className={styles.childrenContainer}>{children}</div>
      </section>
    </> : <></>
  );
};

export default Modal;
