import React from 'react';
import google from '../../assets/images/GoogleLogo.svg';
import styles from './GoogleSignIn.module.scss';

type GoogleProps = {
    onGoogleLink: (e?: any) => void;
}
const GoogleSignIn = (props: GoogleProps)=>{
  return (
    <div className={styles.social__container}>
    <button type='button' className={styles.social__button} onClick={props.onGoogleLink}>
        <div className={styles.social}>
          <img src={google} />
          <span>Sign in with Google</span>
        </div>
    </button>
  </div>
  );
};

export default GoogleSignIn;
