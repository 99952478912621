import imageCompression from 'browser-image-compression';

export const parseNotificationType = (type:string)=>{
  const words = type.split('_');
  let parsedType = '';
  words.map((t)=>{
    parsedType += t.toLowerCase() + ' ';
  });
  parsedType.charAt(0).toUpperCase();
  parsedType.slice(parsedType.length-1);

  return parsedType;
};

export const blobToFile = (theBlob:any, fileName:string, fileType:string)=> {
  return new File([theBlob], fileName, {
    type: fileType,
    lastModified: Date.now(),
  });
};

export const compressImage = async (image:any, options:any) => {
  let compressedImage = await imageCompression(image.file, options);
  compressedImage = blobToFile(
      compressedImage,
      image.file.name,
      image.file.type,
  );
  return compressedImage;
};

export const calculatePct = (number:number, oldNumber:number)=>{
  // console.log(oldNumber);
  const temp = (number-oldNumber)/Math.abs(number) * 100;
  const percentage = isFinite(temp)? (temp.toFixed(1) + '%') : '0%';
  return {
    pct: temp,
    formattedPct: percentage,
  };
};
