import {createGlobalStyle} from 'styled-components';
import {ACTIVERED, RED_COLOR} from '../../constants/colors';
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({theme}:any) => theme.background};
    color: ${({theme}) => theme.bodyTextColor};
    font-family: EudoxusSans;
    transition: all 0.50s linear;
  }
  div a {
    color: ${({theme}:any)=>theme.divLinkColor}
  }

  
  button:disabled{
    position: relative;
    display: flex;
    align-items: center;
  }

  button:disabled:hover .comingSoon{
    visibility: visible;
  }

  .comingSoon{
    visibility: hidden;
    background: ${({theme}:any) => theme.background};
    box-shadow: 1px 7px 15px $lightGrey-color-3;
    width: 100px;
    top: -100%;
    color: $earnings-color;
    text-align: left;
    padding: 7px;
    border-radius: 6px;
    font-size: 12px;
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    border: 1px solid ${({theme}:any)=>theme.switchButtonBorder};
  }

  a:hover{
    color: ${({theme}:any)=>theme.aHoverColor}
  }
  div div{
    color: ${({theme}:any)=>theme.nestedDivColor}
  }

  input::placeholder{
    color: ${({theme}:any)=>theme.placeholderColor}
  }
  input {
    color: ${({theme}:any)=>theme.inputTextColor}
  }

  .header{
    color: ${({theme}:any)=>theme.headerColor}
  }
  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $black-color3;
    background: $white-color;
  }
  body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px $black-color5;
    background: ${({theme}:any)=>theme.scrollbarColor};
 
  }

  .chipTitle{
    color: ${({theme}:any)=>theme.chipTitleColor}
  }

  .card{
    background-color: ${({theme}:any)=>theme.cardBackground};
    border-color: ${({theme}:any)=>theme.cardBorder};
  }

  .notificationStyle{
    background-color: ${({theme}:any)=>theme.notificationBackground};
  }

  .notificationText{
    color:  ${({theme}:any)=>theme.notificationFont};
  }

  .switchContainer{
    background-color: ${({theme}:any)=>theme.switchButtonColor};
    border: 1px solid ${({theme}:any)=>theme.switchButtonBorder};
  }

  .bottomTitle{
    color: ${({theme}:any)=>theme.bottomTitle}
  }
  .subtitle{
    color: ${({theme}:any)=>theme.subtitleColor}
  }

  .bank button[aria-expanded="false"] span:first-child span:nth-child(2),  
  .flags button[aria-expanded="false"] span:first-child span:nth-child(2){
    color: ${({theme}:any)=>theme.headerColor};
  }

  .flags, .bank{
    ul{
      border: 1px solid ${({theme}:any)=>theme.flagsSelectBorder};
      background-color: ${({theme}:any)=>theme.flagsSelectListBackground};
     div{
      background-color: ${({theme}:any)=>theme.flagsSelectListBackground};
      input{
        background-color: ${({theme}:any)=>theme.flagsSelectListBackground};
      }
     }
    }
    button{
      font-size: 14px !important;
      border: 1px solid ${({theme}:any)=>theme.flagsSelectBorder};
    }
    button::after{
      border: solid ${({theme}:any)=>theme.flagsSelectArrow};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px 3px 5px 5px;
      margin-bottom: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    button[aria-expanded="true"]::after{
      border: solid ${({theme}:any)=>theme.flagsSelectArrow};
      border-width: 0 2px 2px 0;
      display: inline-block;
      margin-top: 4px;
      padding: 3px 3px 5px 5px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }

    button[aria-expanded="false"]{
      span:first-child{
          color: ${({theme}:any)=>theme.flagsSelectText};
      }
    }
  }

  .DayPicker_transitionContainer, .DayPicker_transitionContainer_1, .CalendarMonth, .CalendarMonthGrid,
   .DayPicker_transitionContainer__horizontal, .DayPickerNavigation_button {
    background-color: ${({theme}:any)=>theme.pickerBackground};
  
    select{
      background-color: ${({theme}:any)=>theme.pickerBackground};
      color: ${({theme}:any)=>theme.bodyTextColor};
      border: 1px solid ${({theme}:any)=>theme.flagsSelectBorder};
    }
    select:focus {outline:0;}

}
.DayPicker_transitionContainer,  .DayPicker_transitionContainer__horizontal{
  width: 320px !important;
}

.CalendarDay{
  background: transparent;
  color: ${({theme}:any)=>theme.bodyTextColor}
}

.CalendarDay__highlighted_calendar{
  background: ${({theme}:any)=>theme.highlightedDateColor};
 
}

.CalendarMonth_caption{
  color: ${({theme}:any)=>theme.headerColor};
}


.CalendarDay__selected{
  background: ${ACTIVERED};
  border: 1px solid ${ACTIVERED};
}
.CalendarDay__selected:hover{
  background: ${ACTIVERED};
  border: 1px solid ${ACTIVERED};
}

.CalendarDay__blocked_out_of_range{
  color: ${({theme}:any)=>theme.blockedDateColor}
}

button:disabled{
  background-color: ${({theme}:any)=>theme.buttonDisabled};
}

.disabledDiv{
  background-color: ${({theme}:any)=>theme.buttonDisabled};
  
}

[type="radio"]:not(:checked) + label:before{
  background-color: ${({theme}:any)=>theme.cardBackground};
  border: 1px solid ${({theme}:any)=>theme.bodyTextColor};
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.musicTitle{
  color: ${({theme}:any)=>theme.musicHeader};
}

.disabledDiv{
  background-color: ${({theme}:any)=>theme.buttonDisabled};
  cursor: default !important;
  color: ${({theme}:any)=>theme.disabledTextColor};
}

.disabledDiv:hover{
  background-color: ${({theme}:any)=>theme.buttonDisabled};
}

.react-tel-input .country-list{
  background-color: ${({theme}:any)=>theme.cardBackground};
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}
.ant-picker {
  background: ${({theme}:any) => theme.background};
  input {
    color: ${({theme}:any)=>theme.inputTextColor};
  }
}
  `;

