export const MONTH = [
    {label: 'Jan', value: '01'},
    {label: 'Feb', value: '02'},
    {label: 'Mar', value: '03'},
    {label: 'Apr', value: '04'},
    {label: 'May', value: '05'},
    {label: 'Jun', value: '06'},
    {label: 'Jul', value: '07'},
    {label: 'Aug', value: '08'},
    {label: 'Sep', value: '09'},
    {label: 'Oct', value: '10'},
    {label: 'Nov', value: '11'},
    {label: 'Dec', value: '12'},
  ];
export const DATE_RANGE_EARNING = [
  {label: 'This Month', value: 'This Month'},
  {label: 'Last Month', value: 'Last Month'},
  {label: 'Last Quater', value: 'Last Quater'},
  {label: 'This Year', value: 'This Year'},
  {label: 'Last Year', value: 'Last Year'},
  {label: 'Custom', value: 'Custom'},
];
