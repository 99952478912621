import React, {useState, useEffect, useRef} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {selectMe} from '../../features/stores/userSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import {toK} from '../AreaChart/CustomAreaChart';
import Button from '../Button/Button';
import NotificationButton from '../NotificationButton/NotificationButton';
import CustomSearch from '../Search/CustomSearch';
import SocialModal from '../SocialModal/SocialModal';
import styles from './Header.module.scss';
import plus from '../../assets/images/Chart.svg';
import logo from '../../assets/images/logo.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import DefaultIcon from '../DefaultIcon/DefaultIcon';
import {THEMES} from '../../constants/types';

import {useHistory} from 'react-router-dom';
import {BLUE_COLOR, WHITE_COLOR} from '../../constants/colors';
import CounterModal from '../CounterModal/CounterModal';
import useRequest from '../../features/API/request';
import NotificationBar from '../NotificationBar/NotificationBar';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

const Header = ()=>{
  const request = useRequest({url: '/auth/me', method: 'GET'});
  const user = selectMe(useAppSelector(store.getState));
  const [me, setMe] = useState(user.me);
  const isCounterClicked = selectMe(useAppSelector(store.getState)).isCounterClicked;

  const mobile = useWindowWidth() < 890;
  const tablet = useWindowWidth() < 1000;
  const followers = toK(selectMe(useAppSelector(store.getState)).me.followersFromLinkedAccounts);
  const [open, setOpen] = useState(false);
  const [openCounterModal, setOpenCounterModal] = useState(false);

  const [isOnSettings, setIsOnSettings] = useState(false);
  const history = useHistory();

  const getMe = async ()=>{
    const res = await request.doRequest();
    if (res) {
      setMe(res);
    }
  };

  useEffect(()=>{
    getMe();
  }, [isCounterClicked]);


  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname ==='/settings') setIsOnSettings(true);
      else setIsOnSettings(false);
    });
  }, [history]);

  return (
    isOnSettings && mobile ? <></> :
    <>
      <div className={styles.container} style={{
        ...window.location.pathname === '/onboarding' && !mobile && {justifyContent: 'initial'},
        ...window.location.pathname === '/onboarding' && mobile && {justifyContent: 'space-between'}}}
      >
        {window.location.pathname === '/onboarding' && !mobile ?
        <>
          <div className={styles.logo}>
            <Logo fill={me.theme === THEMES.LIGHT ? BLUE_COLOR : WHITE_COLOR} />
            <div className={styles.logoDivider}></div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginLeft: '32px'}}>
            <HeaderBody openCounterModal={openCounterModal} setOpenCounterModal={setOpenCounterModal} isOnSettings={isOnSettings} tablet={tablet}followers={followers} setOpen={setOpen} user={me} mobile={mobile} />
          </div>
        </> :
        <HeaderBody openCounterModal={openCounterModal} setOpenCounterModal={setOpenCounterModal} isOnSettings={isOnSettings} tablet={tablet} followers={followers} setOpen={setOpen} user={me} mobile={mobile} />
        }
        {mobile && window.location.pathname !== '/onboarding' &&
        <SearchContainer style={{marginLeft: '15px'}} setOpenCounterModal={setOpenCounterModal} setOpen={setOpen} tablet={tablet} mobile={mobile} />}
      </div>

      {!mobile && <div className={styles.separator}></div>}
      {open && <SocialModal setIsModalOpen={setOpen} handleClose={()=>setOpen(false)} title={'Connect your account'} isModalOpen={open} message=""></SocialModal>}
    </>
  );
};


const SearchContainer = ({setOpen, mobile, tablet, style}:any)=>{
  const [openNotifications, setOpenNotifications] = useState(false);
  const node = useRef(null);
  useOutsideAlerter(node, setOpenNotifications);
  return (
    <div className={styles.searchContainer} style={style}>
      {/* <Button onClick={() => setOpen(true)}
        inverted
        style={{
          background: 'transparent',
          width: 'auto',
          ...!mobile ? {marginRight: '20px', fontSize: '15px', padding: '13px 20px 13px 20px'} :
     {fontSize: '14px', padding: '12px 12px 12px 12px', borderRadius: '8px'}}}
      >
        <div className={styles.buttonChildrenContainer} style={{color: 'unset'}}>
          <img className={styles.buttonImage} src={plus} />
          <span>{mobile ? 'Add acc.' : 'Add account'}</span>
        </div>
      </Button> */}
      {!tablet && <div ref={node}>
        <NotificationButton onClick={()=>setOpenNotifications(!openNotifications)} style={{position: 'relative'}} />
        {openNotifications && <div style={{position: 'absolute', top: '100px', right: '30px'}}>
          <NotificationBar />
        </div>}
      </div>
      }
    </div>
  );
};

const HeaderBody = ({openCounterModal, setOpenCounterModal, followers, setOpen, user, mobile, tablet, isOnSettings}:any)=>{
  return (
    <>
      {!isOnSettings ? <div style={{display: 'flex', alignItems: 'center'}}>
        {user.avatar ? <img src={user.avatar} className={styles.avatar}/> : <DefaultIcon color={user.defaultAvatar} name={user.firstName} surname={user.lastName} style={{marginRight: '16px'}} />}
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={styles.upperText} style={{...(window.location.pathname === '/onboarding' && {marginBottom: '0px'}), ...(user.theme === THEMES.DARK && {color: 'white'})}}>Hi {user.firstName}</div>
          {/* {window.location.pathname !== '/onboarding' && <div className={styles.lowerText} style={{...(user.theme === THEMES.LIGHT &&{color: '#8C92B2'})}} >{followers} {followers === '1' ? 'Follower' : 'Followers'}</div>} */}
        </div>
      </div> : <div className={`${styles.settings} header`}>Account Settings</div>}

      {!mobile && window.location.pathname !== '/onboarding' ? <SearchContainer setOpenCounterModal={setOpenCounterModal} setOpen={setOpen} tablet={tablet} mobile={mobile} /> : <></>}

    </>);
};
export default Header;
