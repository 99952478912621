import {idText} from 'typescript';
import {getDenseCardIcons} from '../components/DenseCard/DenseCard';
import {THEMES} from './types';

type options = {
  [key: string]: string
}

export const CounterIconMapping: options = {
  youtube: 'Youtube',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  facebook: 'Facebook',
  twitter: 'Twitter',
};

export const getCounterIcon = (type: string, theme = THEMES.LIGHT) => {
  const sourceName = CounterIconMapping[type];
  const icon = getDenseCardIcons(sourceName, theme);
  return icon;
};
