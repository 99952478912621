import React from 'react';
import {USER_ICON_COLORS} from '../../constants/colors';
import {fetchMe} from '../../features/Login/login';
import styles from './DefaultIcon.module.scss';

type IconProps = {
    name: string;
    surname?: string;
    style?:any;
    color: string;
}
const DefaultIcon = ({name, surname, style, color}:IconProps)=>{
  return (<div className={styles.container} style={{background: color, ...style}}>
    {name?.charAt(0)} {surname?.charAt(0)}
  </div>);
};

export default DefaultIcon;
