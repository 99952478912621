import React, {useEffect} from 'react';
import {AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, ResponsiveContainer} from 'recharts';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from './CustomAreaChart.module.scss';
import moment from 'moment';
import redArrow from '../../assets/images/down-arrow.svg';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {THEMES} from '../../constants/types';
import greenArrow from '../../assets/images/up-arrow.svg';
import {GREEN_COLOR, RED_COLOR} from '../../constants/colors';

type AreaChartProps = {
  data: any;
  width?: number;
}

export const toK = (number:any) => {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'B'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function(item) {
    return number >= item.value;
  });
  return item ? (number / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
};

const CustomAreaChart = ({data, width}:AreaChartProps)=>{
  const yTickOffset=45;
  const smallMobile = useWindowWidth() <= 390;
  const me = selectMe(useAppSelector(store.getState)).me;

  return (
    <ResponsiveContainer minWidth={smallMobile ? 190 : 350} height={223}>
      <AreaChart data={data} margin={{top: 14, right: 10, left: smallMobile ? 30 : yTickOffset-20, bottom: 0}}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="27%" stopColor="#ff5a5e" stopOpacity={0.2}/>
            <stop offset="116%" stopColor="#ff5a5e" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          dy={6} axisLine={false}
          style={{fontSize: 12}}
          tick={{fill: '#bbbfd2'}}
          tickLine={false}
          tickFormatter={(tick:string)=>moment(tick, 'DD-MM-YYYY').format('MMM,DD,YYYY').toString()}/>
        <YAxis
          dx={smallMobile ? -5 : -yTickOffset}
          dy={-5}
          {...(smallMobile && {width: 10})}
          tickFormatter={(tick)=>{
            if (Number(tick) < 1 && Number(tick) > 0) return Number(tick).toFixed(2);
            else return toK(tick);
          }}
          style={{...(smallMobile ? {fontSize: 12} : {fontSize: 13})}} tick={{fill: '#bbbfd2'}} tickLine={false}
          tickCount={4} axisLine={false}/>
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <Tooltip
          wrapperStyle={{top: -90, left: -45}}
          content={<CustomTooltip me={me} data={data} />}
          cursor={{stroke: '#ff5a5e', strokeOpacity: 0.1, strokeWidth: 52}}
        />
        <Area type="monotone" dataKey="value" stroke="#ff5a5e" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>

  );
};

const CustomTooltip = (props:any) => {
  const {active, payload, label, hideDollar, me} = props;
  if (active && payload) {
    return (
      <div className={styles.tooltip} style={{...(me.theme === THEMES.DARK && {boxShadow: 'none'})}}>
        <div>{payload[0]?.payload.name}</div>
        {payload.map((el: any, index: number) => {
          return <div key={index} className={styles.tooltipLabel}>
            {(!hideDollar?'$' : '')+ el.value.toLocaleString(undefined, {minimumFractionDigits: 2,
              maximumFractionDigits: 2})}
          </div>;
        })}

      </div>
    );
  }

  return null;
};

// const CustomTooltip = ({active, payload, label, data, me}:any) => {
//   let valueComparedToLastMonth = 0;
//   let pct = -1;
//   if (payload?.length) valueComparedToLastMonth=payload[0].value;
//   if (label) {
//     const prevMonth = moment().month(label).subtract(1, 'months').format('DD-MM-YYYY');
//     const prevEarning = data.filter((earning: any)=>earning.name === prevMonth);
//     if (prevEarning?.length) {
//       const lala = prevEarning[0].value;
//       pct = valueComparedToLastMonth > 0 && valueComparedToLastMonth < 1 ? ((valueComparedToLastMonth-lala)/parseFloat(lala))*100 : Math.round(((valueComparedToLastMonth-lala)/lala)*100);
//     }
//   }

//   if (active) {
//     return (
//       <div className={styles.tooltip} style={{...(me.theme === THEMES.DARK && {boxShadow: 'none'})}}>
//         <div className={styles.tooltipLabel}>
//           {valueComparedToLastMonth > 0 && valueComparedToLastMonth < 1 ? '$' + valueComparedToLastMonth.toFixed(2) : '$'+toK(valueComparedToLastMonth)}
//         </div>
//         {pct !== -1 &&
//         <div className={styles.tooltipPct} style={{...(pct < 0 ? {color: RED_COLOR}:{color: GREEN_COLOR})}}>
//           {pct <0 ? <img src={redArrow} className={styles.downarrow} style={{...(pct < 0 && {transform: 'rotate(180deg)'})}} /> :
//           <img src={greenArrow} className={styles.downarrow} />}
//           {pct.toFixed(2) + '%'}
//         </div>}
//       </div>
//     );
//   }

//   return null;
// };


export default CustomAreaChart;
