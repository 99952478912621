import cuid from 'cuid';
import moment from 'moment';
import {THEMES, TOKENS} from '../constants/types';
import instagram from '../assets/images/Instagram-dark.svg';
import youtube from '../assets/images/youtube-dark.svg';

import instagramDarkTheme from '../assets/images/InstagramDarkTheme.svg';
import youtubeDarkTheme from '../assets/images/youtubeDarkTheme.svg';

export const getIcons = (me: any)=>{
  if (!me) return [];
  const icons = [];
  if (me.instagramRefreshToken) icons.push(me.theme === THEMES.LIGHT ? instagram : instagramDarkTheme);
  if (me.googleRefreshToken) icons.push(me.theme === THEMES.LIGHT ? youtube : youtubeDarkTheme);

  return icons;
};

export const getPlatforms = (me: any)=>{
  if (!me) return [];
  const platforms = [];
  if (me.instagramRefreshToken) platforms.push('instagram');
  if (me.googleRefreshToken) platforms.push('youtube');

  return platforms;
};


export const onChangeField = (e:any, setObject:Function, isNumber=false) => {
  if (isNumber) {
    if (e.target.value === '' || Number(e.target.value)) {
      setObject((prev:any)=>({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  } else {
    setObject((prev:any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }
};

export const parseRecentPostsYoutube = (data:any) => {
  const parsedData = data.map((video:any) => {
    const newVideoObject = {
      id: video.id.videoId,
      title: video.snippet.title,
      publishedAt: video.snippet.publishedAt,
      thumbnail: video.snippet.thumbnails.high.url,
      views: Number(video.statistics.viewCount),
      engagement: Number(video.statistics.likeCount) + Number(video.statistics.dislikeCount) + Number(video.statistics.commentCount),
    };
    return newVideoObject;
  });
  return parsedData;
};

export const shouldModalOpen = (tokenObject:TOKENS)=>{
  let open = true;
  for (const [key, value] of Object.entries(tokenObject)) {
    if (value) {
      open = false;
      break;
    }
  }
  return open;
};

export const checkTokenPresent = (token:string, tokens:Array<any>)=>{
  for (const [key, value] of Object.entries(tokens)) {
    if (key === token) return value;
  }
};

export const capitalizeFirstLetter = ([first, ...rest]:any, locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('');

export const getSessionToken = () => {
  if (window.localStorage.getItem('sessionToken')) {
    return window.localStorage.getItem('sessionToken');
  }
  return null;
};

export const createSessionToken = () => {
  if (getSessionToken() || window.localStorage.getItem('token')) {
    return null;
  }
  window.localStorage.setItem('sessionToken', cuid());
  return getSessionToken();
};


export const COUNTER_STATUS = {
  NO_DATA: -1,
  SUCCESS: 0,
  DB_ERROR: 1,
  API_ERROR: 2,
};

export const resolveCounterStatus = (data:any)=>{
  if (!data) {
    return {
      expression: false,
      message: 'No data provided.',
    };
  }

  let atLeastOneError = false;

  if (data) {
    Object.keys(data).forEach(function(key) {
      if (data[key].status === 1 || data[key].status === 2) atLeastOneError = true;
    });
  }

  const expression = !atLeastOneError;
  let message = '';
  if (expression && false) message = 'Account is already connected.'; // set to never activate, need a new flag from backend to signal that account exists
  else if (atLeastOneError) message = 'There has been an error in getting the follower count in one or more platforms. Please check your spelling in case of any errors.';
  else message = 'Followers successfully added.';

  return {
    expression,
    message,
  };
};

export const determinePrice = (priceShortcut:string)=>{
  let price = '';
  switch (priceShortcut) {
    case 'ytIntegration':
      price = 'Youtube Integration';
      break;
    case 'igStory':
      price = 'Instagram Story (3 frames)';
      break;
    case 'igPost':
      price = 'Instagram Post';
      break;
    case 'snap':
      price = 'Snap (3 frames)';
      break;
  }

  return price;
};

export const calculateDate = (dateFilter: string, format='YYYY-MM-DD') => {
  const formatedDate = {start: '', end: ''};
  switch (dateFilter) {
    case 'This Month':
      formatedDate.start = moment().startOf('month').format(format);
      formatedDate.end = moment().endOf('month').format(format);
      break;
    case 'Last Month':
       formatedDate.start = moment().subtract(1, 'months').startOf('month').format(format);
       formatedDate.end = moment().subtract(1, 'months').endOf('month').format(format);
       break;  
    case 'Last Quater':
      formatedDate.start = moment().subtract(3, 'months').startOf('month').format(format);
      formatedDate.end = moment().subtract(1, 'months').endOf('month').format(format);
      break; 
    case 'This Year':
      formatedDate.start = moment().startOf('year').format(format);
      formatedDate.end = moment().endOf('year').format(format);
      break; 
    case 'Last Year':
      formatedDate.start = moment().subtract(1, 'year').startOf('year').format(format);
      formatedDate.end = moment().subtract(1, 'year').endOf('year').format(format);
      break;        
  }
  return formatedDate;
};
