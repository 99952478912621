import React, {useState, useRef} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import {selectMe, setMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {baseUrl} from '../../features/EnvironmentVariables/enviromentVariables';
import crown from '../../assets/images/crown.svg';
import crownNoBg from '../../assets/images/crown-noBg.svg';
import personIcon from '../../assets/images/personIcon.svg';
import personIconNoBG from '../../assets/images/personIcon-noBg.svg';
import plusIconRed from '../../assets/images/plusIconRed.svg';

import checkWhite from '../../assets/images/check-white.svg';
import checkGray from '../../assets/images/check-gray.svg';
import checkGreen from '../../assets/images/check-green.svg';

import freeIcons from '../../assets/images/freeIcons.svg';
import premiumIcons from '../../assets/images/premiumIcons.svg';

import youtube from '../../assets/images/01-Youtube-Red.png';
import instagram from '../../assets/images/Instagram_logo_2016.svg';
import tiktok from '../../assets/images/tiktoklogo.svg';
import spotify from '../../assets/images/18- Spotify.png';
import twitter from '../../assets/images/twitter.svg';
import patreon from '../../assets/images/Icon_Patreon.svg';
import shopify from '../../assets/images/Icon_Shopify.svg';
import cameo from '../../assets/images/Icon_Cameo.svg';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';


import {ReactComponent as TotalFollowers} from '../../assets/images/Icon-Followers.svg';
import {ReactComponent as Track} from '../../assets/images/Icon-Track.svg';
import {ReactComponent as SelectedTrack} from '../../assets/images/Icon-Track-Selected.svg';

import styles from '../../pages/Login/login.module.scss';
import s from './SocialModal.module.scss';
import {useEffect} from 'react';
import useRequest from '../../features/API/request';
import useWindowWidth from '../../hooks/useWindowWidth';
import {ACTIVERED, BLUE_COLOR, DARKTHEMEMAINGREY, WHITE_COLOR, GREY_COLOR_9, GREY_COLOR_7, DARKTHEME_GREY_1, GREEN_COLOR, BLACK_COLOR_3} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import TextField from '../TextField/TextField';
import {resolveCounterStatus} from '../../features/util';
import {toK} from '../AreaChart/CustomAreaChart';
import {refreshMe} from '../../features/Login/login';
import PageSpinner from '../PageSpinner/PageSpinner';
import GoogleSignIn from '../GoogleSignIn/GoogleSignIn';

type ModalProps = {
  title: string;
  isModalOpen: boolean;
  message: string;
  handleClose?: ()=>void
  setIsModalOpen?: Function;
}

const SocialModal = ({title, isModalOpen, message, handleClose, setIsModalOpen}: ModalProps) => {
  const publicDataRequest = useRequest({method: 'POST', url: '/platforms/public'});
  const [clicked, setClicked] = useState(false);
  const me = selectMe(useAppSelector(store.getState)).me;
  // const [me, setMe] = useState(loggedUser);
  const isMobile = useWindowWidth() < 800;
  const shouldStretch = useWindowWidth() < 1163;
  const [isCounterSelected, setIsCounterSelected] = useState(false) as [any, (arg1: any) => void];
  const [loading, setLoading] = useState(false);
  const [counterData, setCounterData] = useState({
    channelId: '',
    igUsername: '', // for instagram
    tkUsername: '', // for tiktok
    youtubeURL: '', // for twitter
    twUsername: '',
  });
  const [validation, setValidation] = useState({
    color: GREEN_COLOR,
    message: '',
  });
  const [flowType, setFlowType] = useState('');


  let borderStyle = {};
  if (me.theme === THEMES.LIGHT) {
    borderStyle = {
      border: '1px solid ' + BLUE_COLOR,
    };
  } else borderStyle = {border: '1px solid ' + DARKTHEMEMAINGREY};
  const toggleStyle = {height: '64px', borderRadius: '8px', backgroundColor: 'transparent', ...borderStyle};
  const selectedToggleStyle = {backgroundColor: ACTIVERED, border: '0px'};
  const selectedToggleChildrenStyle = {color: WHITE_COLOR, fontSize: '16px'};
  const textFieldStyle = {height: '48px', backgroundColor: 'transparent',
    border: '1px solid '+ GREY_COLOR_7, marginLeft: '16px', ...(shouldStretch && {maxWidth: '100%'})};


  const cancelButtonChildrenStyle = {
    ...(me.theme === THEMES.LIGHT ? {color: BLUE_COLOR}:{color: WHITE_COLOR}), fontSize: '15px',
  };
  const cancelButtonStyle = {
    boxSizing: 'border-box', height: '48px',
    ...(me.theme === THEMES.LIGHT ?
        {border: 'solid 1px '+ BLUE_COLOR} : {border: '0px', backgroundColor: DARKTHEME_GREY_1}
    ),
    borderRadius: '8px'};

  const buttonStyle = {...cancelButtonStyle, backgroundColor: ACTIVERED, border: '0px'};

  const buttonChildrenStyle={
    ...(me.theme === THEMES.LIGHT ? {color: WHITE_COLOR}:{color: WHITE_COLOR}), fontSize: '15px',
  };

  const linkAccountButtonStyle = {height: '48px', marginTop: '12px',
    backgroundColor: 'transparent', border: 'solid 1px #d9d9d9', color: '#091d60', fontSize: isMobile ? '13px':'14px'};

  const onGoogleLink = async ()=>{
    setClicked(!clicked);
    const url = baseUrl() + `/auth/link-google-account`;
    window.location.href = url;
  };

  const onIgLink = async ()=>{
    setClicked(!clicked);
    const url = baseUrl() + `/auth/instagram-link-account`;
    window.location.href = url;
  };


  const sendCounterData = async ()=>{
    const result = await publicDataRequest.doRequest(counterData) as any;
    const {expression, message} = resolveCounterStatus(result);

    if (!expression) {
      setValidation({
        color: ACTIVERED,
        message: message,
      });
    } else {
      setValidation({
        color: GREEN_COLOR,
        message: message,
      });
      refreshMe();
    }
    setClicked(!clicked);
  };

  useEffect(()=>{
    if (publicDataRequest.isFetching) {
      if (!loading) {
        setLoading(true);
      }
    };
    if (publicDataRequest.isFetched) {
      if (loading) {
        setLoading(false);
      }
    }
  }, [publicDataRequest]);

  useEffect(()=>{
    setValidation({
      color: GREEN_COLOR,
      message: '',
    });
  }, [counterData, isCounterSelected]);

  useEffect(()=>{
    if (!isCounterSelected) {
      setCounterData({
        igUsername: '',
        tkUsername: '',
        channelId: '',
        youtubeURL: '',
        twUsername: '',
      });
    }
  }, [isCounterSelected]);

  const retreiveId = (url: string) => {
    const indexOfSlash = url.lastIndexOf('/');
    if (indexOfSlash !== -1) {
      setCounterData({...counterData, youtubeURL: url, channelId: (url.slice(indexOfSlash+1))});
    }
  };

  return (
    <Modal setShow={setIsModalOpen} show={isModalOpen} title={title} handleClose={handleClose}>
      {isCounterSelected === null && <div className={s.modalContainer}>
        <div className={s.panel}>
          <div className={s.modalContainer__img}>
            <img src={personIcon}/>
          </div>
          <div className={s.modalContainer__header}>Lite - New Creator</div>
          <div className={s.modalContainer__bulletList}>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkGreen}></img>
              <span>Track Total Followers</span>
            </div>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkGray}></img>
              <span>Track Revenue Streams</span>
            </div>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkGray}></img>
              <span>Full Insights</span>
            </div>
          </div>
          {!isMobile && <img className={s.panel__background} src={freeIcons}></img>}
          {isMobile && <img className={s.panel__background__mobile} src={freeIcons}></img>}
          <div className={s.modalContainer__buttonWhite__red} onClick={()=>setIsCounterSelected(true)}>Select</div>
        </div>
        <div className={s.panel + ' ' + s.panel__right}>
          <div className={s.modalContainer__img}>
            <img src={crown}/>
          </div>
          <div className={s.modalContainer__header}>Pro - Full Access</div>
          <div className={s.modalContainer__bulletList}>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkWhite}></img>
              <span>Track Total Followers</span>
            </div>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkWhite}></img>
              <span>Track Revenue Streams</span>
            </div>
            <div className={s.modalContainer__bulletList__line}>
              <img src={checkWhite}></img>
              <span>Full Insights</span>
            </div>
          </div>
          {!isMobile && <img className={s.panel__background + ' ' + s.panel__background__opacity} src={premiumIcons}></img>}
          {isMobile && <img className={s.panel__background__mobile + ' ' + s.panel__background__opacity} src={premiumIcons}></img>}
          <div className={s.modalContainer__buttonWhite} onClick={()=>setIsCounterSelected(false)}>Select</div>
        </div>
      </div>}
      {/* <Button onClick={()=>setIsCounterSelected(true)} style={{...toggleStyle, ...(isCounterSelected && {...selectedToggleStyle})}}>

          <div style={{...(isCounterSelected && {...selectedToggleChildrenStyle})}} className={s.toggleText}>
            <div style={{
              ...(isCounterSelected ? {backgroundColor: WHITE_COLOR} : {backgroundColor: me.theme === THEMES.LIGHT ? BLUE_COLOR : BLACK_COLOR_3}),
              borderRadius: '8px',
              marginRight: '15px',
              marginLeft: '10px',
            }}>
              <TotalFollowers fill={isCounterSelected ? ACTIVERED : WHITE_COLOR} />
            </div>

            Total follower count</div>
        </Button>
        <Button onClick={()=>setIsCounterSelected(false)} style={{...toggleStyle, ...(!isCounterSelected && {...selectedToggleStyle}), marginLeft: '24px'}}>
          <div style={{...(!isCounterSelected && {...selectedToggleChildrenStyle})}} className={s.toggleText}>
            <div style={{
              ...(!isCounterSelected ? {backgroundColor: WHITE_COLOR} : {backgroundColor: me.theme === THEMES.LIGHT ? BLUE_COLOR : BLACK_COLOR_3}),
              borderRadius: '8px',
              marginRight: '15px',
              marginLeft: '10px',
            }}>
              {!isCounterSelected ?
              <SelectedTrack fill={!isCounterSelected ? ACTIVERED : WHITE_COLOR} /> : <Track fill={!isCounterSelected ? ACTIVERED : WHITE_COLOR} />}
            </div>
            Track all your data in one place
          </div>
        </Button> */}

      <div className={s.selection}>
        {isCounterSelected !== null && <div className={s.selection__header}>
          {/* <div className={s.selection__header__option} onClick={()=>setIsCounterSelected(true)}>
            <img src={personIconNoBG} style={isCounterSelected === true? {filter: 'brightness(0) saturate(100%) invert(33%) sepia(97%) saturate(6731%) hue-rotate(349deg) brightness(95%) contrast(95%)'} : {}}/>
            <span style={isCounterSelected === true? {color: '#EC1E24'} : {}}>LITE - NEW Creator</span>
          </div> */}
          <div className={s.selection__header__option} onClick={()=>setIsCounterSelected(false)}>
            <img src={crownNoBg} style={isCounterSelected === false? {filter: 'brightness(0) saturate(100%) invert(33%) sepia(97%) saturate(6731%) hue-rotate(349deg) brightness(95%) contrast(95%)'} : {}}/>
            <span style={isCounterSelected === false? {color: '#EC1E24'} : {}}>PRO - FULL Access</span>
          </div>
        </div>}
        {isCounterSelected === true &&
      <div className={s.container}>
        <div style={{fontSize: '13px'}}>
        Here you can get follower counts from your social media accounts. You do not need to give any permissions for this process.
        </div>

        <div className={s.counterContainer}>

          <div className={s.counterContainer__inputs}>
            <div className={s.socialContainer} style={{width: '100%'}}>
              <img src={instagram} style={{width: '40px', height: '40px'}} />
              <TextField value={counterData.igUsername} styles={textFieldStyle} type='text' placeholder={'username'} name='igUsername' setObject={setCounterData}/>
            </div>

            <div className={s.socialContainer} style={{width: '100%'}}>
              <img src={tiktok} style={{width: '40px', height: '40px'}} />
              <TextField value={counterData.tkUsername} styles={textFieldStyle} type='text' placeholder={'username'} name='tkUsername' setObject={setCounterData}/>
            </div>

            <div className={s.socialContainer} style={{width: '100%'}}>
              <img src={twitter} style={{width: '40px', height: '40px'}} />
              <TextField value={counterData.twUsername} styles={textFieldStyle} type='text' placeholder={'username'} name='twUsername' setObject={setCounterData}/>
            </div>

            <div className={s.socialContainer}>
              <img src={youtube} style={{width: '40px', height: '40px'}} />
              <TextField value={counterData.youtubeURL} styles={textFieldStyle} type='text' placeholder={'https://www.youtube.com/channel/...'} name='youtubeURL' setObject={setCounterData} onChange={(value: string) => retreiveId(value)}/>
            </div>

          </div>

          {!isMobile && <div className={s.circleContainer}>
            <div className={s.smallCircle} style={{top: '25px', left: '-5px'}}>
              <img src={youtube} className={s.smallIcon} style={{height: '20px', transform: 'rotate(-20deg)'}} />
            </div>
            <div className={s.smallCircle} style={{right: '20px'}}>
              <img src={twitter} style={{transform: 'rotate(20deg)', width: '25px', height: '25px'}} />
            </div>
            <div className={s.smallCircle} style={{right: '-10px', bottom: '120px'}}>
              <img src={instagram} style={{transform: 'rotate(20deg)', height: '20px', width: '20px'}} />
            </div>
            <div className={s.smallCircle} style={{left: '20px', bottom: '90px'}}>
              <img src={tiktok} style={{transform: 'rotate(-20deg)', height: '20px', width: '20px'}} />
            </div>
            <div className={s.circleBase}>
              {!loading? <><div className={s.innerCircle}>
                <div className={s.innerTitle}>{toK(me.followersFromLinkedAccounts)}</div>
                <div className={s.innerSubtitle}>Total audience</div>
              </div> </> :
              <div className={s.innerCircle}>
                <PageSpinner></PageSpinner>
              </div>}
            </div>
          </div>}


        </div>


        <div style={{color: validation.color, maxWidth: '90%', wordBreak: 'break-word', marginTop: '20px'}}>{validation.message}</div>
        <div className={s.buttonContainer}>
          <Button
            style={{...buttonStyle}}
            onClick={sendCounterData}
          >
            <div style={buttonChildrenStyle}>Save</div>
          </Button>

          <Button
            style={{...cancelButtonStyle, marginLeft: '16px'}}
            onClick={()=>setCounterData({channelId: '', tkUsername: '', igUsername: '', youtubeURL: '', twUsername: ''})}
            inverted
          >
            <div style={cancelButtonChildrenStyle}>Cancel</div>
          </Button>

        </div>


      </div>}
        {isCounterSelected === false && <div className={s.container}>

          <div style={{fontSize: '13px'}}>
      Here you can link your social media accounts.
      If you decide to link your account, you will be asked to grant certain permissions to the platform in order to see more detailed statistics from the linked account.
          </div>

          {flowType == '' ? <div className={s.platformsContainer}>
            <div className={s.audience}>
              <div className={s.title}>audience</div>
              <Button onClick={onIgLink} disabled style={linkAccountButtonStyle} >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={instagram} alt="instagram icon" />
                  <span className={s.buttonSpan}>Instagram</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
              <Button disabled onClick={()=>console.log('tiktok')} style={linkAccountButtonStyle} >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={tiktok} alt="titok icon" />
                  <span className={s.buttonSpan}>Tiktok</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
              <Button disabled onClick={()=>console.log('spotify')} style={linkAccountButtonStyle} >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={spotify} alt="spotify icon" />
                  <span className={s.buttonSpan}>Spotify</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>

            </div>
            <div className={s.money}>
              <div className={s.title}>money</div>

              <Button onClick={()=>console.log('shopify')} style={linkAccountButtonStyle} disabled >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={shopify} alt="shopify icon" />
                  <span className={s.buttonSpan}>Shopify</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
              <Button onClick={()=>console.log('patreon')} style={linkAccountButtonStyle} disabled >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={patreon} alt="shopify icon" />
                  <span className={s.buttonSpan}>Patreon</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
              <Button onClick={()=>console.log('cameo')} style={linkAccountButtonStyle} disabled >
                <div className={s.socialButton} style={{width: '100%'}}>
                  <img className={styles.googleLogo} src={cameo} alt="shopify icon" />
                  <span className={s.buttonSpan}>Cameo</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
            </div>
            <div className={s.audience__money}>
              <div className={s.title}>money + audience</div>
              <Button onClick={()=>setFlowType('GOOGLE')} style={linkAccountButtonStyle} >
                <div className={s.socialButton}>
                  <img className={styles.googleLogo} src={youtube} alt="youtube icon" />
                  <span className={s.buttonSpan}>Youtube</span>
                  <img className={styles.googleLogo} src={plusIconRed}/>
                </div>
              </Button>
            </div>
          </div> : flowType == 'GOOGLE' ? <GoogleSignIn onGoogleLink={onGoogleLink} /> : null}
        </div>}
      </div>
    </Modal>
  );
};

export default SocialModal;
