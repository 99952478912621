import React, {useEffect} from 'react';
import {useState} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {BLUE_COLOR, DARKTHEMEMAINGREY, DARKTHEMEMAINWHITE, DARKTHEME_BLACK, GREY_COLOR_11, GREY_COLOR_13, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import OutlineButton from '../OutlineButton/OutlineButton';
import styles from './Select.module.scss';

interface Option{
    value: any,
    name: string,
}

interface ISelect{
    options: Array<Option>,
    title: string;
    icon?: string;
    style?: any;
    className?: string;
    selectStyles?: any;
    setSelectedOption: Function;
    selectedOption?: string;
    setIsOpen: Function;
    isOpen: boolean;
    innerStyle?: any;
    defaultOptionStyle?: any;
}

const Select = (props: ISelect) => {
  const [text, setText] = useState(props.title) as any;
  const me = selectMe(useAppSelector(store.getState)).me;

  useEffect(()=>{
    let title = 'Gender';
    if (props.selectedOption) title = props.selectedOption?.charAt(0).toUpperCase() + props.selectedOption?.slice(1, props.selectedOption?.length);
    setText(title);
  }, [props.selectedOption]);
  return (
    <div style={{outline: 'none', position: 'relative'}}>
      <OutlineButton
        style={{
          ...(props.isOpen ? {borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px',
            borderTopRightRadius: '8px', borderTopLeftRadius: '8px'} : {borderRadius: '8px'}),
          border: '1px solid #d3d6de', ...props.style,
        }}
        textStyle={{...props.selectedOption && {color: BLUE_COLOR}, ...props.defaultOptionStyle}}
        onClick={()=>props.setIsOpen(!props.isOpen)}
        text={text}
        icon={props.icon} />

      {props.isOpen &&
      <div className={styles.container}
        style={{
          ...(me.theme === THEMES.DARK ?
            {backgroundColor: DARKTHEME_BLACK, borderColor: DARKTHEMEMAINWHITE} :
            {backgroundColor: WHITE_COLOR, borderColor: GREY_COLOR_13}),
          ...props.innerStyle}}
      >
        {props.options.map((option: any)=>
          <div key={option.name}
            style={{...(option.value === props.selectedOption && {backgroundColor: '#eaf1f8', opacity: 0.7})}}
            onClick={()=>{
              props.setSelectedOption(option.value);
              props.setIsOpen(false);
            }} className={styles.items}>{option.name}</div>)}
      </div>}
    </div>
  );
};

export default Select;
