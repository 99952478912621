import React from 'react';
import {Route, Switch} from 'react-router';
import Login from '../../pages/Login/login';
import PublicLogin from '../../pages/PublicLogin/PublicLogin';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import CookiePolicy from '../../pages/CookiePolicy/CookiePolicy';
import StandardOfUse from '../../pages/StandardOfUse/StandardOfUse';
import SalesAndPurchaseTerm from '../../pages/SalesAndPurchaseTerm/SalesAndPurchaseTerm';
import TermsAndConditionsBuyer from '../../pages/TermsAndConditionsBuyer/TermsAndConditionsBuyer';
import TermsAndConditionsCreator from '../../pages/TermsAndConditionsCreator/TermsAndConditionsCreator';
import PrivacyNotice from '../../pages/PrivacyNotice/PrivacyNotice';

const PublicSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" component={PublicLogin} />
      <Route exact path="/privacy-policy-old" component={PrivacyPolicy} />
      <Route exact path="/privacy-policy" component={PrivacyNotice} />
      <Route exact path="/terms-and-condition-old" component={TermsAndConditions} />
      <Route exact path="/terms-and-condition-buyer" component={TermsAndConditionsBuyer} />
      <Route exact path="/terms-and-condition" component={TermsAndConditionsCreator} />
      <Route exact path="/cookie-policy" component={CookiePolicy} />
      <Route exact path="/standards-of-use" component={StandardOfUse} />
      <Route exact path="/sales-and-purchase-term" component={SalesAndPurchaseTerm} />
    </Switch>
  );
};

export default PublicSwitch;
