import React, {useState, useEffect} from 'react';
import AutoComplete from 'react-autocomplete';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {ACTIVEGREEN, ACTIVERED, BLUE_COLOR, DARKTHEMEMAINGREY, DARKTHEME_BLACK, GREEN_COLOR, GREYTEXTFIELDCOLOR2, GREY_COLOR_13, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from './CustomAutocomplete.module.scss';
import Button from '../Button/Button';
type ItemType = {
  title: string;
  id: string;
}

type AutocompleteProps = {
  style: any;
  setStyle: Function;
  items: ItemType[];
  value: string;
  setValue?: Function;
  onChange: (e?: any) => void;
  onSelect: (e: any) => void;
  placeholder?: string;
  itemTitle?: string;
  canAddNewValues?: boolean;
  minWidth?: string;
  newValuesArray?: Array<any>;
  setPopUpMessage?: Function;
  setNewValuesArray?: Function;
  canSelectMultiple?: boolean;
  allSelectedItems?: Array<any>;
  validationPosition?: number;
}

const CustomAutocomplete = ({setStyle, items, value, onChange, onSelect, style, placeholder, itemTitle, canAddNewValues=false,
  minWidth, newValuesArray, setNewValuesArray, setValue, setPopUpMessage, canSelectMultiple, allSelectedItems, validationPosition}: AutocompleteProps) => {
  const isMobile = useWindowWidth() <= 767;
  const me = selectMe(useAppSelector(store.getState)).me;
  const [autoCompleteItems, setAutocompleteItems] = useState([]) as any;
  const title = itemTitle ? itemTitle : 'title';


  useEffect(()=>{
    if (items.length) setAutocompleteItems([...items, ...(newValuesArray ? newValuesArray : [])]);
  }, [items]);

  const handleCustomValue = (value: string)=>{
    if (setPopUpMessage && newValuesArray && setNewValuesArray && setValue) {
      if (!value) {
        setPopUpMessage({
          position: 3,
          message: 'Brand name must not be empty!',
          color: ACTIVERED,
        });
        return;
      }

      const element = items.filter((el:any)=>el[title].toUpperCase() === value.toUpperCase()) as any;
      const inCustomValues = newValuesArray.filter((el:any)=>el.toUpperCase() === value.toUpperCase()) as any;

      if (element.length) {
        // brand already exists, write a validation message
        setPopUpMessage({
          position: 3,
          message: 'This brand already exists. Please select it from the drop down menu.',
          color: ACTIVERED,
        });
      } else if (inCustomValues.length) {
        setPopUpMessage({
          position: 3,
          message: 'You have already added this brand.',
          color: ACTIVERED,
        });
      } else {
        setPopUpMessage({
          position: 3,
          message: '',
          color: GREEN_COLOR,
        });
        // for brand table
        const modifiedNewCustomValues = newValuesArray;
        modifiedNewCustomValues.push(value);
        setNewValuesArray(modifiedNewCustomValues);
        // clear input
        setValue('');
      }
    }
  };

  return (
    <div style={{...(canAddNewValues && {position: 'relative', minWidth: minWidth})}}>
      <AutoComplete
        onMenuVisibilityChange={(isOpen: any) => {
          if (isOpen) {
            setStyle({
              borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px',
              borderTopRightRadius: '8px', borderTopLeftRadius: '8px',
            });
          } else setStyle({borderRadius: '8px'});
        }}
        getItemValue={(item) => itemTitle ? item[itemTitle] : item.title}
        items={autoCompleteItems}
        renderItem={(item, isHighlighted) =>
          <div style={{
            ...(me.theme === THEMES.LIGHT ? {background: isHighlighted ? 'lightgray' : 'white'} : {background: isHighlighted ? 'lightgray' : DARKTHEME_BLACK}),
            marginTop: '5px',
          }}>
            {itemTitle ? item[itemTitle] : item.title}
          </div>
        }
        shouldItemRender={(item, value: any) => {
          const should = item[title].toLowerCase().indexOf(value.toLowerCase()) > -1;
          return should;
        }}
        value={value}
        onChange={(e:any)=>{
          onChange(e);
          if (setPopUpMessage) {
            setPopUpMessage({
              position: 3,
              message: '',
              color: GREEN_COLOR,
            });
          }
        }}
        onSelect={(val) =>{
          if (setPopUpMessage) {
            setPopUpMessage({
              position: validationPosition || 3,
              message: '',
              color: GREEN_COLOR,
            });
          }

          if (canSelectMultiple && allSelectedItems) {
            // check for doubles
            const isInChosenItems = allSelectedItems.filter((item:any)=>item[title] === val).length;
            if (isInChosenItems && setPopUpMessage) {
              setPopUpMessage({
                position: validationPosition || 3,
                message: 'You have already added this item.',
                color: ACTIVERED,
              });
            } else {
              onSelect(val);
            }
          } else onSelect(val);
        }}

        wrapperStyle={{
          ...(isMobile ? {height: '48px'} : {height: '56px'}), marginTop: '8px', ...style,
          ...(me.theme === THEMES.LIGHT ? {border: '1px solid ' + GREY_COLOR_13} : {border: '1px solid ' + DARKTHEMEMAINGREY}), boxSizing: 'border-box', padding: '15px 0px 15px 0px', position: 'relative',
          display: 'flex', justifyContent: 'center',
        }}


        inputProps={{
          style: {
            width: '95%', border: 0, boxSizing: 'border-box', fontSize: isMobile ? '13px' : '15px',
            ...(me.theme === THEMES.DARK && {backgroundColor: DARKTHEME_BLACK}),
            ...(me.theme === THEMES.LIGHT ? {
              color: value ? BLUE_COLOR : GREYTEXTFIELDCOLOR2,
              opacity: value ? 1 : 0.9,
            } : {
              color: value ? WHITE_COLOR : DARKTHEMEMAINGREY,
            }),
          },
          placeholder,
        }}

        menuStyle={{
          position: 'absolute',
          top: isMobile ? '46px' : '54px',
          left: '0px', width: '100%',
          fontSize: isMobile ? '13px' : '15px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          backgroundColor: me.theme === THEMES.LIGHT ? '#fff' : DARKTHEME_BLACK,
          zIndex: 20,
          padding: isMobile ? '15px' : '20px',
          border: '1px solid #d3d6de',
          boxSizing: 'border-box',
          maxHeight: '200px',
          overflowY: 'scroll',
        }}
        wrapperProps={{
          className: styles.noSelect,
        }}
      />
      {canAddNewValues && <Button style={{border: 'none', width: 'auto', position: 'absolute', right: '10px', top: '19px'}}
        inverted onClick={()=>{
          // add to new array
          const title = itemTitle ? itemTitle : 'title';
          const isInArray = autoCompleteItems.filter((i:any)=>i[title] === value).length > 0;
          if (!isInArray && newValuesArray && canAddNewValues && setNewValuesArray && setValue) {
            handleCustomValue(value);
          }
        }}>
        <div style={{color: ACTIVERED}}>Add</div>
      </Button>}
    </div>


  );
};

export default CustomAutocomplete;
