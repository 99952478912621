export const LANGUAGE = [
    // {
    //   "label": "Abkhaz",
    //   "value": "Abkhaz",
    // },
    // {
    //   "label": "Afar",
    //   "value": "Afar",
    // },
    // {
    //   "label": "Afrikaans",
    //   "value": "Afrikaans",
    // },
    // {
    //   "label": "Akan",
    //   "value": "Akan",
    // },
    // {
    //   "label": "Albanian",
    //   "value": "Albanian",
    // },
    // {
    //   "label": "Amharic",
    //   "value": "Amharic",
    // },
    {
      "label": "Arabic",
      "value": "Arabic",
    },
    // {
    //   "label": "Aragonese",
    //   "value": "Aragonese",
    // },
    // {
    //   "label": "Armenian",
    //   "value": "Armenian",
    // },
    // {
    //   "label": "Assamese",
    //   "value": "Assamese",
    // },
    // {
    //   "label": "Avaric",
    //   "value": "Avaric",
    // },
    // {
    //   "label": "Avestan",
    //   "value": "Avestan",
    // },
    // {
    //   "label": "Aymara",
    //   "value": "Aymara",
    // },
    // {
    //   "label": "Azerbaijani",
    //   "value": "Azerbaijani",
    // },
    // {
    //   "label": "Bambara",
    //   "value": "Bambara",
    // },
    // {
    //   "label": "Bashkir",
    //   "value": "Bashkir",
    // },
    // {
    //   "label": "Basque",
    //   "value": "Basque",
    // },
    // {
    //   "label": "Belarusian",
    //   "value": "Belarusian",
    // },
    // {
    //   "label": "Bengali",
    //   "value": "Bengali",
    // },
    // {
    //   "label": "Bihari",
    //   "value": "Bihari",
    // },
    // {
    //   "label": "Bislama",
    //   "value": "Bislama",
    // },
    // {
    //   "label": "Bosnian",
    //   "value": "Bosnian",
    // },
    // {
    //   "label": "Breton",
    //   "value": "Breton",
    // },
    // {
    //   "label": "Bulgarian",
    //   "value": "Bulgarian",
    // },
    // {
    //   "label": "Burmese",
    //   "value": "Burmese",
    // },
    // {
    //   "label": "Catalan; Valencian",
    //   "value": "Catalan; Valencian",
    // },
    // {
    //   "label": "Chamorro",
    //   "value": "Chamorro",
    // },
    // {
    //   "label": "Chechen",
    //   "value": "Chechen",
    // },
    // {
    //   "label": "Chichewa; Chewa; Nyanja",
    //   "value": "Chichewa; Chewa; Nyanja",
    // },
    // {
    //   "label": "Chinese",
    //   "value": "Chinese",
    // },
    // {
    //   "label": "Chuvash",
    //   "value": "Chuvash",
    // },
    // {
    //   "label": "Cornish",
    //   "value": "Cornish",
    // },
    // {
    //   "label": "Corsican",
    //   "value": "Corsican",
    // },
    // {
    //   "label": "Cree",
    //   "value": "Cree",
    // },
    // {
    //   "label": "Croatian",
    //   "value": "Croatian",
    // },
    // {
    //   "label": "Czech",
    //   "value": "Czech",
    // },
    // {
    //   "label": "Danish",
    //   "value": "Danish",
    // },
    // {
    //   "label": "Divehi; Dhivehi; Maldivian;",
    //   "value": "Divehi; Dhivehi; Maldivian;",
    // },
    // {
    //   "label": "Dutch",
    //   "value": "Dutch",
    // },
    {
      "label": "English",
      "value": "English",
    },
    // {
    //   "label": "Esperanto",
    //   "value": "Esperanto",
    // },
    // {
    //   "label": "Estonian",
    //   "value": "Estonian",
    // },
    // {
    //   "label": "Ewe",
    //   "value": "Ewe",
    // },
    // {
    //   "label": "Faroese",
    //   "value": "Faroese",
    // },
    // {
    //   "label": "Fijian",
    //   "value": "Fijian",
    // },
    // {
    //   "label": "Finnish",
    //   "value": "Finnish",
    // },
    // {
    //   "label": "French",
    //   "value": "French",
    // },
    // {
    //   "label": "Fula; Fulah; Pulaar; Pular",
    //   "value": "Fula; Fulah; Pulaar; Pular",
    // },
    // {
    //   "label": "Galician",
    //   "value": "Galician",
    // },
    // {
    //   "label": "Georgian",
    //   "value": "Georgian",
    // },
    // {
    //   "label": "German",
    //   "value": "German",
    // },
    // {
    //   "label": "Greek, Modern",
    //   "value": "Greek, Modern",
    // },
    // {
    //   "label": "Guaraní",
    //   "value": "Guaraní",
    // },
    // {
    //   "label": "Gujarati",
    //   "value": "Gujarati",
    // },
    // {
    //   "label": "Haitian; Haitian Creole",
    //   "value": "Haitian; Haitian Creole",
    // },
    // {
    //   "label": "Hausa",
    //   "value": "Hausa",
    // },
    // {
    //   "label": "Hebrew (modern)",
    //   "value": "Hebrew (modern)",
    // },
    // {
    //   "label": "Herero",
    //   "value": "Herero",
    // },
    // {
    //   "label": "Hindi",
    //   "value": "Hindi",
    // },
    // {
    //   "label": "Hiri Motu",
    //   "value": "Hiri Motu",
    // },
    // {
    //   "label": "Hungarian",
    //   "value": "Hungarian",
    // },
    // {
    //   "label": "Interlingua",
    //   "value": "Interlingua",
    // },
    // {
    //   "label": "Indonesian",
    //   "value": "Indonesian",
    // },
    // {
    //   "label": "Interlingue",
    //   "value": "Interlingue",
    // },
    // {
    //   "label": "Irish",
    //   "value": "Irish",
    // },
    // {
    //   "label": "Igbo",
    //   "value": "Igbo",
    // },
    // {
    //   "label": "Inupiaq",
    //   "value": "Inupiaq",
    // },
    // {
    //   "label": "Ido",
    //   "value": "Ido",
    // },
    // {
    //   "label": "Icelandic",
    //   "value": "Icelandic",
    // },
    // {
    //   "label": "Italian",
    //   "value": "Italian",
    // },
    // {
    //   "label": "Inuktitut",
    //   "value": "Inuktitut",
    // },
    // {
    //   "label": "Japanese",
    //   "value": "Japanese",
    // },
    // {
    //   "label": "Javanese",
    //   "value": "Javanese",
    // },
    // {
    //   "label": "Kalaallisut, Greenlandic",
    //   "value": "Kalaallisut, Greenlandic",
    // },
    // {
    //   "label": "Kannada",
    //   "value": "Kannada",
    // },
    // {
    //   "label": "Kanuri",
    //   "value": "Kanuri",
    // },
    // {
    //   "label": "Kashmiri",
    //   "value": "Kashmiri",
    // },
    // {
    //   "label": "Kazakh",
    //   "value": "Kazakh",
    // },
    // {
    //   "label": "Khmer",
    //   "value": "Khmer",
    // },
    // {
    //   "label": "Kikuyu, Gikuyu",
    //   "value": "Kikuyu, Gikuyu",
    // },
    // {
    //   "label": "Kinyarwanda",
    //   "value": "Kinyarwanda",
    // },
    // {
    //   "label": "Kirghiz, Kyrgyz",
    //   "value": "Kirghiz, Kyrgyz",
    // },
    // {
    //   "label": "Komi",
    //   "value": "Komi",
    // },
    // {
    //   "label": "Kongo",
    //   "value": "Kongo",
    // },
    // {
    //   "label": "Korean",
    //   "value": "Korean",
    // },
    // {
    //   "label": "Kurdish",
    //   "value": "Kurdish",
    // },
    // {
    //   "label": "Kwanyama, Kuanyama",
    //   "value": "Kwanyama, Kuanyama",
    // },
    // {
    //   "label": "Latin",
    //   "value": "Latin",
    // },
    // {
    //   "label": "Luxembourgish, Letzeburgesch",
    //   "value": "Luxembourgish, Letzeburgesch",
    // },
    // {
    //   "label": "Luganda",
    //   "value": "Luganda",
    // },
    // {
    //   "label": "Limburgish, Limburgan, Limburger",
    //   "value": "Limburgish, Limburgan, Limburger",
    // },
    // {
    //   "label": "Lingala",
    //   "value": "Lingala",
    // },
    // {
    //   "label": "Lao",
    //   "value": "Lao",
    // },
    // {
    //   "label": "Lithuanian",
    //   "value": "Lithuanian",
    // },
    // {
    //   "label": "Luba-Katanga",
    //   "value": "Luba-Katanga",
    // },
    // {
    //   "label": "Latvian",
    //   "value": "Latvian",
    // },
    // {
    //   "label": "Manx",
    //   "value": "Manx",
    // },
    // {
    //   "label": "Macedonian",
    //   "value": "Macedonian",
    // },
    // {
    //   "label": "Malagasy",
    //   "value": "Malagasy",
    // },
    // {
    //   "label": "Malay",
    //   "value": "Malay",
    // },
    // {
    //   "label": "Malayalam",
    //   "value": "Malayalam",
    // },
    // {
    //   "label": "Maltese",
    //   "value": "Maltese",
    // },
    // {
    //   "label": "Māori",
    //   "value": "Māori",
    // },
    // {
    //   "label": "Marathi (Marāṭhī)",
    //   "value": "Marathi (Marāṭhī)",
    // },
    // {
    //   "label": "Marshallese",
    //   "value": "Marshallese",
    // },
    // {
    //   "label": "Mongolian",
    //   "value": "Mongolian",
    // },
    // {
    //   "label": "Nauru",
    //   "value": "Nauru",
    // },
    // {
    //   "label": "Navajo, Navaho",
    //   "value": "Navajo, Navaho",
    // },
    // {
    //   "label": "Norwegian Bokmål",
    //   "value": "Norwegian Bokmål",
    // },
    // {
    //   "label": "North Ndebele",
    //   "value": "North Ndebele",
    // },
    // {
    //   "label": "Nepali",
    //   "value": "Nepali",
    // },
    // {
    //   "label": "Ndonga",
    //   "value": "Ndonga",
    // },
    // {
    //   "label": "Norwegian Nynorsk",
    //   "value": "Norwegian Nynorsk",
    // },
    // {
    //   "label": "Norwegian",
    //   "value": "Norwegian",
    // },
    // {
    //   "label": "Nuosu",
    //   "value": "Nuosu",
    // },
    // {
    //   "label": "South Ndebele",
    //   "value": "South Ndebele",
    // },
    // {
    //   "label": "Occitan",
    //   "value": "Occitan",
    // },
    // {
    //   "label": "Ojibwe, Ojibwa",
    //   "value": "Ojibwe, Ojibwa",
    // },
    // {
    //   "label": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    //   "value": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    // },
    // {
    //   "label": "Oromo",
    //   "value": "Oromo",
    // },
    // {
    //   "label": "Oriya",
    //   "value": "Oriya",
    // },
    // {
    //   "label": "Ossetian, Ossetic",
    //   "value": "Ossetian, Ossetic",
    // },
    // {
    //   "label": "Panjabi, Punjabi",
    //   "value": "Panjabi, Punjabi",
    // },
    // {
    //   "label": "Pāli",
    //   "value": "Pāli",
    // },
    // {
    //   "label": "Persian",
    //   "value": "Persian",
    // },
    // {
    //   "label": "Polish",
    //   "value": "Polish",
    // },
    // {
    //   "label": "Pashto, Pushto",
    //   "value": "Pashto, Pushto",
    // },
    // {
    //   "label": "Portuguese",
    //   "value": "Portuguese",
    // },
    // {
    //   "label": "Quechua",
    //   "value": "Quechua",
    // },
    // {
    //   "label": "Romansh",
    //   "value": "Romansh",
    // },
    // {
    //   "label": "Kirundi",
    //   "value": "Kirundi",
    // },
    // {
    //   "label": "Romanian, Moldavian, Moldovan",
    //   "value": "Romanian, Moldavian, Moldovan",
    // },
    // {
    //   "label": "Russian",
    //   "value": "Russian",
    // },
    // {
    //   "label": "Sanskrit (Saṁskṛta)",
    //   "value": "Sanskrit (Saṁskṛta)",
    // },
    // {
    //   "label": "Sardinian",
    //   "value": "Sardinian",
    // },
    // {
    //   "label": "Sindhi",
    //   "value": "Sindhi",
    // },
    // {
    //   "label": "Northern Sami",
    //   "value": "Northern Sami",
    // },
    // {
    //   "label": "Samoan",
    //   "value": "Samoan",
    // },
    // {
    //   "label": "Sango",
    //   "value": "Sango",
    // },
    // {
    //   "label": "Serbian",
    //   "value": "Serbian",
    // },
    // {
    //   "label": "Scottish Gaelic; Gaelic",
    //   "value": "Scottish Gaelic; Gaelic",
    // },
    // {
    //   "label": "Shona",
    //   "value": "Shona",
    // },
    // {
    //   "label": "Sinhala, Sinhalese",
    //   "value": "Sinhala, Sinhalese",
    // },
    // {
    //   "label": "Slovak",
    //   "value": "Slovak",
    // },
    // {
    //   "label": "Slovene",
    //   "value": "Slovene",
    // },
    // {
    //   "label": "Somali",
    //   "value": "Somali",
    // },
    // {
    //   "label": "Southern Sotho",
    //   "value": "Southern Sotho",
    // },
    // {
    //   "label": "Spanish; Castilian",
    //   "value": "Spanish; Castilian",
    // },
    // {
    //   "label": "Sundanese",
    //   "value": "Sundanese",
    // },
    // {
    //   "label": "Swahili",
    //   "value": "Swahili",
    // },
    // {
    //   "label": "Swati",
    //   "value": "Swati",
    // },
    // {
    //   "label": "Swedish",
    //   "value": "Swedish",
    // },
    // {
    //   "label": "Tamil",
    //   "value": "Tamil",
    // },
    // {
    //   "label": "Telugu",
    //   "value": "Telugu",
    // },
    // {
    //   "label": "Tajik",
    //   "value": "Tajik",
    // },
    // {
    //   "label": "Thai",
    //   "value": "Thai",
    // },
    // {
    //   "label": "Tigrinya",
    //   "value": "Tigrinya",
    // },
    // {
    //   "label": "Tibetan Standard, Tibetan, Central",
    //   "value": "Tibetan Standard, Tibetan, Central",
    // },
    // {
    //   "label": "Turkmen",
    //   "value": "Turkmen",
    // },
    // {
    //   "label": "Tagalog",
    //   "value": "Tagalog",
    // },
    // {
    //   "label": "Tswana",
    //   "value": "Tswana",
    // },
    // {
    //   "label": "Tonga (Tonga Islands)",
    //   "value": "Tonga (Tonga Islands)",
    // },
    // {
    //   "label": "Turkish",
    //   "value": "Turkish",
    // },
    // {
    //   "label": "Tsonga",
    //   "value": "Tsonga",
    // },
    // {
    //   "label": "Tatar",
    //   "value": "Tatar",
    // },
    // {
    //   "label": "Twi",
    //   "value": "Twi",
    // },
    // {
    //   "label": "Tahitian",
    //   "value": "Tahitian",
    // },
    // {
    //   "label": "Uighur, Uyghur",
    //   "value": "Uighur, Uyghur",
    // },
    // {
    //   "label": "Ukrainian",
    //   "value": "Ukrainian",
    // },
    // {
    //   "label": "Urdu",
    //   "value": "Urdu",
    // },
    // {
    //   "label": "Uzbek",
    //   "value": "Uzbek",
    // },
    // {
    //   "label": "Venda",
    //   "value": "Venda",
    // },
    // {
    //   "label": "Vietlabelse",
    //   "value": "Vietlabelse",
    // },
    // {
    //   "label": "Volapük",
    //   "value": "Volapük",
    // },
    // {
    //   "label": "Walloon",
    //   "value": "Walloon",
    // },
    // {
    //   "label": "Welsh",
    //   "value": "Welsh",
    // },
    // {
    //   "label": "Wolof",
    //   "value": "Wolof",
    // },
    // {
    //   "label": "Western Frisian",
    //   "value": "Western Frisian",
    // },
    // {
    //   "label": "Xhosa",
    //   "value": "Xhosa",
    // },
    // {
    //   "label": "Yiddish",
    //   "value": "Yiddish",
    // },
    // {
    //   "label": "Yoruba",
    //   "value": "Yoruba",
    // },
    // {
    //   "label": "Zhuang, Chuang",
    //   "value": "Zhuang, Chuang",
    // },
  ];
