import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
// import {AppContainer} from 'react-hot-loader';


ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);


// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default;

//     ReactDOM.render(
//         <AppContainer>
//           <NextApp />
//         </AppContainer>,
//         document.getElementById('root'),
//     );
//   });
// }

