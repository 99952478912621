/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './PrivacyNotice.scss';

const PrivacyNotice = () => {
  return (
    <>
      <header className="header">
        <div className="header__content">
          <h2>Alfan</h2>
          <p>Privacy Policy</p>
        </div>
      </header>
      <div className="privacy-notice">
        <h1>Privacy Notice</h1>
        <p>LAST UPDATED: February 2024</p>

        <section>
          <p>
            The Alfan Group and its affiliates (collectively, “Alfan,” “we,” or
            “us”) is the owner of the Alfan platform (Platform). Alfan wants you
            to be familiar with how we collect, use and disclose personal
            information when you visit our website{' '}
            <a href="https://alfangroup.com">https://alfangroup.com</a>, use our
            Platform or enter into a contractual agreement with us for one of
            our Services.
          </p>
          <p>
            We know that providing personal information is an act of trust and
            we take that seriously. This Privacy Notice should help you work out
            what we collect your data for and how we protect it for you. If you
            have any questions or would like to make any requests about your
            personal information such as opting out of marketing, please email{' '}
            <a href="mailto:privacy@alfangroup.com">privacy@alfangroup.com</a>.
          </p>
          <p>
            When we collect or process personal information about you, it’s
            important that you read this Privacy Notice in conjunction with any
            other Privacy Notice provided to you, so that you are fully aware of
            how and why we are using your data. This Privacy Notice supplements
            other notices and is not intended to override them. It should also
            be read in conjunction with any terms and conditions as may be
            applicable to you) and will form part of these.
          </p>
          <p>
            This website may include links to third-party websites, SDKs,
            plug-ins and applications. Clicking on those links or enabling those
            connections may allow third parties to collect or share data about
            you. When you leave our website, we encourage you to read the
            Privacy Notice of every website you visit. We don’t control these
            third-party websites and are not responsible for their privacy
            notices.
          </p>
        </section>

        <h2>Contact Us</h2>
        <p>
          Website: <a href="https://alfangroup.com">Alfan (alfangroup.com)</a>
        </p>
        <p>
          Email:{' '}
          <a href="mailto:privacy@alfangroup.com">privacy@alfangroup.com</a>
        </p>

        <h2>1. How We Use Personal Information</h2>
        <section>
          <h3>Information that you provide to us:</h3>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Categories of personal information we collect</th>
                  <th>Purposes of use</th>
                  <th>Source of Information</th>
                  <th>Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Creators: Contact such as (name, email and contact number)
                  </td>
                  <td>
                    Provide you with our Services, and register your account on
                    the platform
                  </td>
                  <td>
                    You where you are defined as a Creator and have signed up
                    for our services on the Platform
                  </td>
                  <td>Performance of contract</td>
                </tr>
                <tr>
                  <td>AML documentation</td>
                  <td>At onboarding stage for compliance reasons</td>
                  <td>Legal obligation</td>
                  <td>Legal obligation</td>
                </tr>
                <tr>
                  <td>Analytics and preferences</td>
                  <td>
                    Marketing relevant products and personalising your
                    experience on the platform
                  </td>
                  <td>Legitimate interests</td>
                  <td>Legitimate interests</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <h3>Users following our Creators</h3>
          <div className="table-responsive">
            {' '}
            <table>
              <thead>
                <tr>
                  <th>Categories of personal information we collect</th>
                  <th>Purposes of use</th>
                  <th>Source of Information</th>
                  <th>Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Users following our Creators (name, email and phone number)
                  </td>
                  <td>
                    To provide paid for services. We use a third party provider,
                    called Tap Payments to facilitate this service offering
                    through the platform
                  </td>
                  <td>
                    Creators, Buyers, Third party vendors, Publicly available
                    sources
                  </td>
                  <td>Legitimate interests</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <h3>
            Information that we collect for business-to-business relationships
            only
          </h3>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Categories of personal information we collect</th>
                  <th>Purposes of use</th>
                  <th>Source of Information</th>
                  <th>Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Business contact information</td>
                  <td>Build and manage business-to-business relationships</td>
                  <td>
                    You, Creators, Buyers, Third party vendors, Media partners
                  </td>
                  <td>
                    Performance of contract, Legitimate interests, Legal
                    obligation
                  </td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td>Build and manage business-to-business relationships</td>
                  <td>
                    You, Creators, Buyers, Third party vendors, Publicly
                    available sources
                  </td>
                  <td>Performance of contract, Legitimate interests</td>
                </tr>
                <tr>
                  <td>Transactional information (as permitted by law)</td>
                  <td>Build and manage business-to-business relationships</td>
                  <td>
                    You, Creators, Buyers, Third party vendors, Publicly
                    available sources
                  </td>
                  <td>Performance of contract, Legitimate interests</td>
                </tr>
                <tr>
                  <td>
                    Due diligence and AML information (as permitted by law)
                  </td>
                  <td>
                    Compliance and risk management activities for
                    business-to-business relationships
                  </td>
                  <td>
                    Your response to our due diligence questions, Third party
                    vendor risk compliance screening and data validation tools
                    and databases
                  </td>
                  <td>
                    Performance of contract, Legitimate interests, Legal
                    obligation
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <h3>
            Information we collect automatically from you and /or your device
          </h3>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Categories of personal information we collect</th>
                  <th>Purposes of use</th>
                  <th>Source of Information</th>
                  <th>Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Device information and identifiers</td>
                  <td>
                    Provide you with our Services, Personalise your experience,
                    Send you relevant marketing and advertising
                  </td>
                  <td>You, Creators, Buyers, Third party service providers</td>
                  <td>Legitimate interests, Legal obligation</td>
                </tr>
                <tr>
                  <td>
                    Connection and Usage (including information collected
                    through cookie)
                  </td>
                  <td>
                    Provide you with our Services, Personalise your experience,
                    Send you relevant marketing and advertising, Safety and
                    security, Marketing and advertising for third parties, Build
                    and manage business-to-business relationships
                  </td>
                  <td>You, Publicly available sources</td>
                  <td>Legitimate interests, Performance of contract</td>
                </tr>
                <tr>
                  <td>Geolocation</td>
                  <td>
                    Provide you with our Services, Personalise your experience,
                    Send you relevant marketing and advertising, Safety and
                    security, Market and advertise for third parties, Build and
                    manage business-to-business relationships
                  </td>
                  <td>
                    You or your company’s responses to our due diligence
                    questions, Third party risk, compliance screening and data
                    validation tools and databases
                  </td>
                  <td>Legitimate interests</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <h3>Information that we collect from third parties:</h3>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Categories of personal information we collect</th>
                  <th>Purposes of use</th>
                  <th>Source of Information</th>
                  <th>Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Information from public and commercial sources</td>
                  <td>Build and manage business-to-business relationships</td>
                  <td>
                    Advertising providers, Analytics providers, Marketers,
                    Partners, Third party vendors, Public records databases,
                    Content partners
                  </td>
                  <td>Legitimate interests</td>
                </tr>
                <tr>
                  <td>
                    Third Party Partners in Connection with providing our
                    Services
                  </td>
                  <td>
                    Provide our Services, Personalise your experience, Provide
                    seamless experience across platforms and devices, Obtain
                    analytics to improve performance, Safety and security, Send
                    you relevant marketing and advertising
                  </td>
                  <td>
                    Advertising providers, Analytics providers, Marketers,
                    Partners, Third party vendors, Public records databases
                  </td>
                  <td>
                    Performance of contract, Legitimate interests, Legal
                    obligation
                  </td>
                </tr>
                <tr>
                  <td>
                    Due diligence information (where required and permitted by
                    law)
                  </td>
                  <td>
                    To conduct due diligence checks on potential and existing
                    customers, selling partners and suppliers and background and
                    suitability checks on individuals. Compliance and risk
                    management activities for business-to-business
                    relationships, Build and manage business-to-business
                    relationships
                  </td>
                  <td>
                    You or your company’s responses to our due diligence
                    questions, Third party risk, compliance screening and data
                    validation tools and databases
                  </td>
                  <td>
                    Legal obligation, Performance of contract, Legitimate
                    interests
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <h2>3. How We Share Your Personal Information</h2>
        <section>
          <ul>
            <li>
              To third parties where necessary to manage and operate our
              business, including management of contracts and providing the
              functionality of our Services.
            </li>
            <li>
              To service providers, website hosting service providers,
              information technology and related infrastructure service
              providers, analytics providers, email delivery service providers.
            </li>
            <li>
              For legal and compliance reasons, including responding to requests
              and legal demands from regulators or other authorities, pursuing
              legal rights and remedies and defending claims.
            </li>
            <li>
              For fraud prevention and security, including ensuring the security
              and safety of our premises.
            </li>
            <li>To comply with applicable law and regulations</li>
            <li>
              We have a legitimate interest in disclosing or transferring your
              personal information to a third-party in the event of any
              reorganisation, merger, sale, joint venture, assignment, transfer,
              or other disposition of all or any portion of our business,
              assets, or stock (including in connection with any bankruptcy or
              similar proceedings). You will be notified of any such business
              transaction and of possible changes to the processing of your
              personal information in accordance with applicable law and the
              ‘Updates to This Privacy Notice’ section.
            </li>
          </ul>
        </section>

        <h2>4. How Long We Keep Your Data</h2>
        <section>
          <p>
            Generally, we will retain Creator data for as long as they are
            active users on the platform. Where users have deactivated their
            accounts, we will retain data for 12 months before deleting same,
            unless required to retain it longer for regulatory or compliance
            reasons.
          </p>
          <p>
            We will retain your personal information for the period necessary to
            fulfil the purposes outlined in this Privacy Notice unless a longer
            retention period is required or permitted by law, for example, for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements.
          </p>
          <p>
            The criteria used to determine our retention periods are set out in
            our retention policy and will vary depending on such factors as (i)
            the length of time we have an ongoing relationship with you and
            provide goods or services to you (for example, for as long as you
            have an account with us); (ii) whether there is a legal obligation
            to which we are subject (for example, certain laws require us to
            keep records of transactions for a certain period of time before we
            can delete them); or (iii) whether retention is advisable in light
            of our legal position (such as in regard to enforcement of our
            contractual terms, applicable statutes of limitations, litigation or
            regulatory investigations).
          </p>
          <p>
            <strong>Retention Periods Based on Legal Obligations</strong>
            <ul>
              <li>
                To cooperate with law enforcement or public, regulatory and
                government authorities: If we receive a preservation order or
                search warrant, related to your account, we will preserve
                personal information subject to such order or warrant after you
                delete your account.
              </li>
              <li>
                To comply with legal provisions on tax and accounting: We may
                retain your personal information, such as financial and
                relationship history after you delete your account, as required
                by local tax law and to comply with bookkeeping requirements.
              </li>
              <li>
                To pursue or defend a legal action: We may retain relevant
                personal information in the event of a legal claim or complaint,
                including regulatory investigations or legal proceedings about a
                claim related to your personal information, or if we reasonably
                believe there is a prospect of litigation (whether in respect of
                our relationship with you or otherwise) for the amount of time
                appropriate to local limitation periods after the dispute has
                been settled or decided by a court or tribunal from which there
                is no further right of appeal.
              </li>
            </ul>
          </p>
          <p>
            <strong>Anonymisation Of Data</strong>
            In some circumstances we will anonymise your personal information
            (so that it can no longer be associated with you) for research or
            statistical purposes, in which case we may use this information
            indefinitely without further notice to you. By way of example,
            anonymisation techniques may include removing direct identifiers
            from a dataset or replacing point coordinates in geo-referenced data
            with non-disclosing features or variables, or other recognised
            techniques appropriate to the data in question.
          </p>
        </section>

        <h2>5. How We Keep Your Data Secure</h2>
        <section>
          <p>
            We seek to use reasonable organisational, technical and
            administrative measures to protect personal information within our
            organisation, such as encryption and multi factor authentication.
            Unfortunately, no data transmission or storage system can be
            guaranteed to be 100% secure. If you have reason to believe that
            your interaction with us is no longer secure, please immediately
            notify us in accordance with the “Contacting Us” section below.
          </p>
        </section>

        <h2>6. Third-Party Services</h2>
        <section>
          <p>
            This Privacy Notice does not address, and we are not responsible
            for, the privacy, information, or other practices of any third
            parties, including any third-party operating any website or service
            to which our products or services link. The inclusion of a link via
            any of our services does not imply endorsement of the linked site or
            service by us or by our affiliates.
          </p>
        </section>

        <h2>7. Children</h2>
        <section>
          <p>
            Our Services are intended for users over the age of 18. We do not
            knowingly collect personal information from children for the
            purposes outlined in this Privacy Notice.
          </p>
        </section>

        <h2>8. International Transfers</h2>
        <section>
          <p>
            Alfan has its headquarters in the United Arab Emirates. We may
            transfer your information to service providers, and other third
            parties located outside of your country of residence. Where we do
            so, this is necessary to provide our Services and for the purposes
            outlined in this Privacy Notice. Data privacy laws vary from country
            to country and may not be equivalent to, or as protective as, the
            laws in your home country. We take steps to ensure that reasonable
            safeguards are in place with the aim to ensure an appropriate level
            of protection for your information, in accordance with applicable
            law. These measures include data transfer agreements. By providing
            us with your information, you acknowledge any such transfer, storage
            or use.
          </p>
        </section>

        <h2>9. Your Rights</h2>
        <section>
          <p>
            You may be able to exercise certain privacy rights. The rights
            available to you depend on our reason for processing your personal
            information and the requirements of applicable law (i.e., your
            rights will vary depending on whether you are located in, for
            example, the United Arab Emirates, the Middle East, European Union,
            the United Kingdom, or California). Specifically, you may have the
            following rights:
          </p>
          <ul>
            <li>
              <strong>
                The right to confirmation on the existence of processing and to
                access the data:
              </strong>{' '}
              You may have the right to obtain from us confirmation as to
              whether personal information processed, and, where that is the
              case, to request access to the personal information. Depending on
              where you are located, you may also have the right to information
              about public and private entities with which the controller has
              disclosed personal information.
            </li>
            <li>
              <strong>
                The right to correct incomplete, inaccurate, or out-of-date
                data:
              </strong>{' '}
              You may have the right to request that we correct any personal
              information about you that is inaccurate. Depending on the purpose
              of the processing, you also have the right to request that we
              complete the personal information we hold about you where you
              believe it is incomplete, including by means of providing a
              supplementary statement.
            </li>
            <li>
              <strong>The right to the portability of data:</strong> You may
              have the right to request that we transfer the personal
              information we have collected about you to another organisation,
              or directly to you, in a structured, commonly used, and
              machine-readable format, under certain conditions.
            </li>
            <li>
              <strong>
                The right to request anonymisation, blocking, or deletion of
                personal information:
              </strong>{' '}
              You have the right to request the deletion of your personal
              information we have collected from you, subject to certain
              conditions and limitations under the law.
            </li>
            <li>
              <strong>The right to revoke consent:</strong> Where we rely on
              your consent to process your personal information, you have the
              right to withdraw that consent at any time with future effect.
              Depending on where you are located, you may also have the right to
              request deletion of personal information that was processed based
              on your consent, or the right to know the consequences of revoking
              your consent. Such a withdrawal will not affect the lawfulness of
              the processing prior to the consent withdrawal.
            </li>
            <li>
              <strong>The right to object to processing:</strong> You may have
              the right to object to our processing of your personal
              information, under certain conditions, and we can be required to
              no longer process your personal information. Such right to object
              may especially apply if we collect and process your personal
              information through automated decision making, such as profiling,
              to better understand your interests in our products and services
              or for direct marketing. If you have a right to object and you
              exercise this right, your personal information will no longer be
              processed by us for such purposes. Such a right to object may, in
              particular, not exist if the processing of your personal
              information is necessary to take steps prior to entering into a
              contract or to perform a contract already concluded.
            </li>
            <li>
              <strong>
                The right to opt-out of the sale or sharing of personal
                information:
              </strong>{' '}
              We do not sell your personal information in exchange for monetary
              consideration. In the event that we disclose your personal
              information to third parties for their marketing purposes, you may
              have the right to opt out of that disclosure. You may also have
              the right to request further information about the disclosure of
              your personal information for those third parties' marketing
              purposes, such as the contact information of those parties, once
              per calendar year.
            </li>
            <li>
              <strong>
                The right to limit the use and disclosure of sensitive personal
                information:
              </strong>{' '}
              We will only use sensitive or special personal information as
              needed for the purposes for which it was collected or with your
              consent. We do not currently process sensitive personal
              information for purposes that may be limited under applicable law.
              If this changes, we will notify you, and you may have the right to
              restrict such additional uses.
            </li>
            <li>
              <strong>
                The right to non-discrimination for exercising a privacy right:
              </strong>{' '}
              We will not discriminate against you for exercising any rights.
            </li>
          </ul>
          <p>
            <strong>Exercising Your Rights</strong>
            To exercise any of your rights as set out above, please contact us
            by submitting a request to{' '}
            <a href="mailto:privacy@alfangroup.com">privacy@alfangroup.com</a>.
            Please note that you will need to verify your identity before we can
            fulfil your request. Your request must: (i) provide sufficient
            information that allows us to reasonably verify that you are the
            person about whom we collected personal information or an authorised
            representative of that person; and (ii) describe the request with
            sufficient detail that allows us to properly understand, evaluate,
            and respond to it. We will only use personal information provided in
            a verifiable consumer request to verify the requestor’s identity or
            authority to make the request. We will respond to your verifiable
            request within any prescribed timelines. In some regions, there may
            be limitations on how often a request relating to personal
            information may be submitted.
          </p>
        </section>

        <h2>10. Updates to This Notice</h2>
        <section>
          <p>
            The “LAST UPDATED” legend at the top of this Privacy Notice
            indicates when this Privacy Notice was last revised. Any changes
            will become effective when we post the revised Privacy Notice.
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyNotice;
