import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface SocketState {
    websocket: object | null;
}

const initialState: SocketState = {
  websocket: null,
};

export const socketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    removeSocket: (state) => {
      state.websocket = {};
    },
    createSocket: (state, action: PayloadAction<any>) => {
      state.websocket = action.payload.websocket;
    },
  },
});

export const {removeSocket, createSocket} = socketSlice.actions;

export const selectWebSocket = (state: RootState) => state.websocket;

export default socketSlice.reducer;
