import React from 'react';
import './../PrivacyPolicy/PrivacyPolicy.scss';

const SalesAndPurchaseTerm = () => {
  return (
    <div className='main__wrapper'>
        <div className='header'>
            <div className='header__content'>
              <h2>Alfan.Link</h2>
              <p>Sale and purchase terms</p>
            </div>
        </div>
        <div className='content'>
            <div className='content__wrapper'>
            <div className='privacy__policy'><h1>SALE AND PURCHASE TERMS</h1></div>
                <br />
                <div className='privacy__description'><h5>UPDATED: 20 SEPTEMBER 2022</h5></div>
                <br />
                <div className='privacy__description'><h5>PLEASE READ THESE SALE AND PURCHASE TERMS CAREFULLY BEFORE USING THIS SITE</h5></div>
                <br />

                <div className='privacy__description'><h3>What are these Sale and Purchase Terms?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  All defined terms under the &quot;Alfan.Link Terms and Conditions&quot; have the same meaning hereunder.
                  </div>  
                  <div className='privacy__para textColor'>
                  These Sale and Purchase Terms:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>are our Sale and Purchase Terms as set out under &quot;There are other terms that apply to you&quot; in the &quot;Alfan.Link Terms and Conditions&quot;;</li>
                          <li>also apply to your use of our site and you agree with them and agree to comply with them; and</li>
                          <li>are part of the Terms.</li>
                      </ul>
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>By using our site you accept these terms</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  By using our site, you confirm that you accept these Sale and Purchase Terms and that you agree to comply with them.
                  </div>  
                  <div className='privacy__para textColor'>
                  If you do not agree to these Sale and Purchase Terms, you must not use our site.
                  </div>
                  <div className='privacy__para textColor'>
                  We recommend that each time you use our site, you check these Sale and Purchase Terms for updates.
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>What sales and purchase activities can occur on this site?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Our site provides users with the opportunity to, in compliance with these Terms:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>set up online stores as part of their account profile;</li>
                          <li>offer, promote, market and display (Offer) various goods and / or services on their online stores including, without limitation, products, digital products, consulting services, advertising and promotional services and courses, whether on their own behalf or as a promotional activity for others (Goods and Services); and</li>
                          <li>view, review, purchase, buy, sell, deliver, supply, tip, deal or otherwise transact in relation to Goods and Services (each a Transaction).</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Our role in Transactions</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Transactions on this site are strictly and directly between users and we have no role in any Transaction, except as may be expressly described on our site.  Without limiting the foregoing, we:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>are not party to any agreement formed between users of this site in respect of any Offer, Good and Service, Transaction or otherwise;</li>
                          <li>are not an agent, partner, broker, intermediary, auctioneer, buyer, guarantor or representative of any user of this site in relation to any Offer, Good and Service, Transaction or otherwise;</li>
                          <li>are not providing any form of guarantee, warranty or other surety in relation to any user of this site, Offer, Good and Service, Transaction or otherwise and, without limiting the foregoing, we do not guarantee the quality, quantity, legality or safety of any Good and Service or the veracity, accuracy or truth of information or claims made by users on this site regarding Offers, Goods and Services, Transactions or otherwise including, without limitation, the competence, trustworthiness or ability of users of this site to complete Transactions and / or comply with their obligations under Transactions;</li>
                          <li>do not possess, deliver, supply, manage, oversee, track or otherwise undertake any action in relation to Goods and Services;</li>
                          <li>do not verify, check, warrant or make any representations in relation to Offers, Goods and Services, Transactions or any content, claim, listing or description by users on this site;</li>
                          <li>do not represent, warrant or guarantee that any posting, linking, listing or uploading of, or in relation to, any Offer or Good and Service will be available or searchable for any length of time or with any degree of prominence or promotion; and</li>
                          <li>do not mediate, resolve, adjudicate or otherwise assist in any manner whatsoever in relation to disputes or problems in relation to Offers, Goods and Services, Transactions or otherwise.</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  Except as expressly set out under these Terms, we do not provide any terms and conditions between users in relation to any Offer or Transaction and you are responsible for providing and entering into a legally binding agreement in respect of each and every Transaction.
                  </div>  
                  <div className='privacy__para textColor'>
                  Any system on our site providing ratings and / or feedback on users is not guaranteed or verified and you are responsible for reading and understanding any content on our site and the terms of any Offer or Transaction, and for undertaking your own investigation on, and forming you own view at your risk on, the quality, veracity, competence, financial standing, trustworthiness and / or ability of users of this site, or in relation to any Offer or Good and Service.
                  </div>  
                  <div className='privacy__para textColor'>
                  You release and indemnify us to the maximum extent permitted by law in respect of any liability, obligation, cost, expense, damage, action or claim of whatsoever nature connected to your use of our site, any Offer, Good and Service and / or any Transaction.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>How to Offer Goods and Services on our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Any Offer, posting, linking, listing or uploading of, or in relation to, a Good and Service by you must:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>be accurate and otherwise comply with these Terms;</li>
                          <li>be clear and recognisable, and contain a detailed description of the Good and Service and all relevant information including, without limitation, the full all inclusive price;</li>
                          <li>contain prominently displayed or linked Transaction terms and conditions;</li>
                          <li>if you are not the ultimate vendor or supplier of the Good and Service, and / or if you are not fulfilling the delivery or supply of the Good and Service, you must identify that person, giving address and contact details (provided such information must not be a personal physical address, email address or mobile or telephone number); and</li>
                          <li>contain your clear and prominent contact information (provided such information must not be a personal physical address, email address or mobile or telephone number).</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>What you must not do on our site</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Without limiting any other Term, you shall not on or in relation to our site, or in connection with any Offer, Good and Service or Transaction:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>Offer, post, link to, list or upload any Good and Service or other related content or items which breach our <a href="/standards-of-use">Standards of Use</a> or otherwise infringe any applicable laws and regulations, and / or the traditional values, morals and cultural norms of the UAE and other GCC countries;</li>
                          <li>breach any terms and conditions between you and another user of our site in relation to any Offer or Transaction including, without limitation, breach the terms and conditions of payment for any Good and Service that you purchase through our site, or breach the terms and conditions of the supply or delivery of any Good and Service that you sell through our site;</li>
                          <li>Transact on our site or with other users of our site if you are under 21 or otherwise not a competent legal person;</li>
                          <li>Transact on our site with other users who are under 21 or otherwise not a competent legal person;</li>
                          <li>Transact on our site or with other users of our site if you are suspended or terminated in respect of our site;</li>
                          <li>post false, inaccurate, misleading, defamatory, or slanderous content;</li>
                          <li>otherwise Offer or Transact any Good and Service in a misleading, deceptive, false, defamatory or slanderous manner;</li>
                          <li>infringe, circumvent or attempt to circumvent our Terms and any associated systems, processes or procedures including, without limitation, any system on our site to rate and provide feedback on users and / or Goods and Services;</li>
                          <li>interfere with or change any other user&apos;s Goods and Services, Offer, post, link, listing or other content or Transaction;</li>
                          <li>use or permit or suffer our site to be used for the distribution or posting of spam or unsolicited communication or, in relation to any Offer or Transaction, any written material, pictures, videos, media or other content not directly related to any Good and Service that complies with these Terms;</li>
                          <li>in relation to any Transaction, use any communication system on our site, or use contact information of users, for purposes other than directly related to an Offer or Good and Service or, or in a manner which is illegal, political, unwelcome or is, by reference to the traditional values, morals and cultural norms of the UAE and other GCC countries, insulting, impolite, unethical, sacrilegious, immoral, hateful or harassing;</li>
                          <li>collect or harvest any information about users of this site, such as contact details, without their consent;</li>
                          <li>Offer or Transact any Good and Service that is counterfeit or might otherwise infringe the intellectual property rights of any third party (including, without limitation, copyright, design right or trademark);</li>
                          <li>engage in price manipulation or any other anti-competitive behaviour in relation to any Offer or Good and Service;</li>
                          <li>provide to, or permit or suffer access of, a third party to your account for whatsoever reason including, without limitation, the purpose of undertaking any Transaction (except where you are a business and such third party is your principal or employee);</li>
                          <li>engage in any Transaction for or on behalf of any third party (except on behalf of a business in respect of which you are a principal or employee, or to the extent you are engaging in authorised promotional activities for a business); or</li>
                          <li>put an unreasonable computational load or other such demand on our site or its infrastructure.</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Terms and conditions of Offers and Transactions</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  The following terms and conditions apply to all Offers and Transactions on our site and, to the extent you are a party to any such Offer or Transaction you agree to them:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>an Offer may be revoked or modified at any time up to acceptance of the Offer;</li>
                          <li>an Offer is accepted upon payment of the full Offer price to the Payment Gateway (as defined under, and in accordance with, &quot;Payment Gateway&quot; below);</li>
                          <li>upon acceptance of an Offer there exists a legally binding agreement between the seller / supplier and purchaser of the applicable Good and Service;</li>
                          <li>such legally binding agreement obliges:<br />
                            <div className='desc__points'>
                                <ul>
                                    <li>the seller / supplier of the applicable Good and Service to supply or deliver the applicable Good and Service to the purchaser as soon as commercially practicable;</li>
                                    <li>the purchaser of the applicable Good and Service to pay the full Offer price to the seller / supplier prior to supply or delivery; and</li>
                                    <li>the seller / supplier of the applicable Good and Service to provide the purchaser with a proper invoice and receipt as soon as commercially practicable (including, without limitation, any VAT as applicable);</li>
                                </ul>
                            </div> 
                          </li>
                          <li>the applicable Good and Service is supplied on an &quot;as is&quot; basis, subject to the description of the Good and Service on our site, and all warranties and representations are excluded to the maximum extent permitted by law; and</li>
                          <li>the Offer price is non-refundable, except in the case of complete failure of supply or delivery.</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  You and the other party to any Offer or Transaction may enter into your own legally binding terms and conditions which supplement the above including, without limitation, amending:
                  </div>
                  <div className='desc__points'>
                      <ul>
                          <li>the time of supply or delivery of the applicable Good and Service;</li>
                          <li>the warranties given in respect of the applicable Good and Service;</li>
                          <li>the terms under which refunds may be made;</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  provided such supplemental terms and conditions are not otherwise inconsistent with the foregoing terms and conditions applicable to Offers and Transactions or these Terms.  Without limitation, Offers and / or Transactions must not be conducted on the basis of credit, installment or deposit payments, delivery prior to payment or staged delivery.
                  </div>
                  <div className='privacy__para textColor'>
                  Without limiting &quot;Our role in Transactions&quot; above, in no circumstances whatsoever are we party to, bound by or liable under any terms and conditions referred to in this section &quot;Terms and conditions of Offers and Transactions&quot; including, without limitation, the terms and conditions which apply to all Offers and Transactions and / or any supplemental terms and conditions referred to above.  Any such terms and conditions are solely between the users of our site that are party to any Offer and / or Transaction.  We have no obligation in relation to any Offer, Transaction or Good and Service including, without limitation, we have no obligation to ensure full payment is made and / or received, that any delivery or supply is carried out and / or that any Good and Service complies with its description on our site or any warranty or representation of any nature whatsoever.
                  </div>
                  <div className='privacy__para textColor'>
                  To the fullest extent permitted by law you release and indemnify us from any and all liabilities, costs, expenses, claims, demands or suits connected to any Offer, Transaction or Good and Service.
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>Payment Gateway</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Payments made in respect of Offers and Transactions under our site are made through a third party payment gateway (&quot;Payment Gateway&quot;).  When you select the link to pay for a Good and Service on this site, you will be redirected to the Payment Gateway.
                  </div>  
                  <div className='privacy__para textColor'>
                  We are not responsible for any acts or omissions of the Payment Gateway provider or for any errors or technical problems of the Payment Gateway or your payment method.  To the fullest extent permitted by law you release and indemnify us from any and all liabilities, costs, expenses, claims, demands or suits connected to any payments made to or from our site and / or the Payment Gateway.
                  </div>  
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>following payment under the Payment Gateway, such payment may be irrevocably forwarded and paid to the seller or supplier of the applicable Good and Service without further reference to you and neither us, nor the Payment Gateway provider, is obligated or required to halt, delay or refund such payment for any reason whatsoever;</li>
                          <li>neither us, nor the Payment Gateway provider is obliged to accept any payment under the Payment Gateway, and may refuse, block or refund the payment without further reference to you;</li>
                          <li>neither us, nor the Payment Gateway provider is liable for any delay in payment nor the consequences thereof;</li>
                          <li>any communication, claim, demand or suit related to any Offer, Transaction, Good and Service and / or payments related thereto are the responsibility of you and the other user/s of this site with whom you engage in an Offer or Transaction with including, without limitation, in relation to any payment refund or failure, warranty, quality or quantity of any Good and Service or claim concerning the supply, delivery or return of any Good and Service;</li>
                          <li>there may be third party banking or other transaction costs related to payments to or from the Payment Gateway, and we reserve the right to deduct, or authorise the Payment Gateway provider to deduct, such costs from the amount paid or charge you such costs (including in relation to any currency conversion);</li>
                          <li>to the fullest extent permitted by law, we are not providing any financial service, advice or product to you under our site (including, without limitation, in relation to the Payment Gateway);</li>
                          <li>use of the Payment Gateway is also subject to the provider&apos;s terms and conditions which you will comply with; and</li>
                          <li>payment methods, processes timings and records are limited to those offered or available on the Payment Gateway from time to time.</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Compliance</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  You will, at your own expense, comply with all laws and regulations relating to any Offers or Transactions on our site as they may change from time to time including, without limitation, obtaining and holding all licences, registrations, permits and approvals needed to Offer and / or Transact in relation to the relevant Goods and Services, and complying with any conditions binding on you in any applicable licences, registrations, permits and approvals.
                  </div>  
                  <div className='privacy__para textColor'>
                  You will comply with all applicable laws, statutes, regulations and codes relating to anti-bribery, anti-corruption, anti-money laundering, sanctions, prohibited or restricted goods and services and terrorism funding and will:
                  </div>   
                  <div className='desc__points'>
                      <ul>
                          <li>ascertain the identity of any other user of our site with whom you engage in an Offer or Transaction, and ensure that they are not a person subject to restriction, prohibition, sanction or penalty under such laws, statutes, regulations and codes;</li>
                          <li>ensure you do not engage in, assist or facilitate, an Offer or Transaction which contravenes such laws, statutes, regulations and codes;</li>
                          <li>Offer, Transact or otherwise deal in Goods and Services which are restricted or prohibited under such laws, statutes, regulations and codes.</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>What can we do if you breach these Terms?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Without prejudice to any other remedies or actions we may take, if you breach these Terms, or we suspect that you may have breached or are about to breach these Terms, or a credible allegation is made that you have breached these Terms, we may (but are not obliged to):
                  </div>   
                  <div className='desc__points'>
                      <ul>
                          <li>restrict, limit, suspend or terminate your ability to Offer or Transact Goods and Services;</li>
                          <li>restrict, limit, suspend or terminate your account or profile settings;</li>
                          <li>modify, delete, hide or black-out any Offer, post, link, list or content of, or in relation to, any Good and Service including, without limitation, any Good and Service description or data;</li>
                          <li>report your use of, or activity on, our site to any government authority, and fully co-operate with any investigation by a government authority into you or any such use or activities, without reference to you;</li>
                          <li>reverse, stop, block or seize on behalf of any government authority, any payment made under our site and / or pursuant to the Payment Gateway;</li>
                          <li>notify or warn other users of our site, either by direct messaging or publically on our site, of any breaches, issues, problems or suspicions concerning your account, profile or Goods and Services Offered by you;</li>
                          <li>conduct an investigation into your use of our site, your Goods and Services and any Transaction you are involved in or related to (and you will fully co-operate with any such investigation);</li>
                          <li>charge you any and all damages, losses, costs and expenses related to your breach of these Terms or any investigation (and you will pay such charges on demand); and</li>
                          <li>require that you pay any deposit or security amount in respect of any Transaction or Good and Service.</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Format of our site, access and Transactions</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We do not guarantee that:
                  </div>   
                  <div className='desc__points'>
                      <ul>
                          <li>the ability to Offer, Transact and view any Goods and Services on our site will continue in its present form or at all; and</li>
                          <li>our site will continue to be, or be, free of charge for the ability to Offer, Transact and / or view any Goods and Services.</li>
                      </ul>
                  </div> 
                  <div className='privacy__para textColor'>
                  We may charge fees in respect of Transactions, Goods and Services or the ability to Offer, Transact or view on our site.  We may also introduce paid accounts and profiles, premium listings, tiered memberships, and /or offer various services in relation to Offers, Transactions and Goods and Services including, without limitation, advertising, promotional, insurance, warranty and escrow services.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Taxes</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We are not liable for any tax, duty, withholdings, imposts or charges in the nature of a tax of any time (including, without limitation, VAT, import or export charges or income taxes), or any penalties or interests thereon, in connection with any Offer, Transaction or Good and Service (Tax).
                  </div> 
                  <div className='privacy__para textColor'>
                  You are responsible for all Taxes related to your Offers, Transactions and Goods and Services on our site, where applicable, and you agree to pay them by the due date under applicable law.
                  </div>
                  <div className='privacy__para textColor'>
                  You release and indemnify us to the maximum extent permitted by law in respect of any liability, obligation, cost, expense, damage, action or claim of whatsoever nature connected to any Tax arising from your use of our site or any Offer or Transaction by you of any Good and Service.
                  </div> 
                </div>
                <br />
            </div>         
        </div>
    </div>
  );
};

export default SalesAndPurchaseTerm;
