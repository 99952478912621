import React, {useEffect, useState, useRef} from 'react';
import styles from './NotificationBar.module.scss';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {ROLES} from '../../constants/roles';
import close from '../../assets/images/close.svg';
import Notification from '../Notification/Notification';
import {logOut} from '../../features/Login/login';
import useRequest from '../../features/API/request';
import {parseNotificationType} from '../../features/Parsers/parsers';
import {selectNotifications} from '../../features/stores/notificationSlicer';
import {THEMES} from '../../constants/types';
import {NOTIFICATION_MOCK} from '../../constants/mockData';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

type NotificationProps = {
  style?:any;
}

const NotificationBar = ({style}:NotificationProps)=>{
  const user = selectMe(useAppSelector(store.getState));

  const notificationList = selectNotifications(useAppSelector(store.getState));
  // const node = useRef(null);
  // useOutsideAlerter(node, setOpen);
  // const notificationList = NOTIFICATION_MOCK;

  return (
    <div className={`${styles.wrapper} card`}
      style={{
        ...(user.me.theme === THEMES.LIGHT && {boxShadow: '0 30px 60px 0 rgba(168, 176, 191, 0.5)'}),
        ...style}}
    >

      <div className={`${styles.container}`}>
        <div style={{marginTop: '15px', marginBottom: '15px'}}>{notificationList?.length === 0 ? 'You currently do not have any notifications.' : ''}</div>

        <div>
          {notificationList && notificationList.map((notification:any, index:number) => <Notification
            style={{...(index !== notificationList.length - 1 ? {marginBottom: '12px'} : {marginBottom: '30px'})}}
            key={notification.id}
            id={notification.id}
            title={notification.title}
            description={notification.description}
            sent={notification.created_at}
            displayType={parseNotificationType(notification.display_type)}
            icon={notification.icon}
            isSeen={notification.isSeen.isSeen}
          />)}</div>
        <div></div>
      </div>
    </div>);
};

export default NotificationBar;
