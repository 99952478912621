import React from 'react';
import style from './musicCover.module.scss';
import album from '../../assets/images/Album.svg';
import musicNoteBold from '../../assets/images/MusicNoteBold.svg';
import play from '../../assets/images/play.svg';

export type MusicCoverType = 'SIMPLE' | 'DETAILED';
export type MusicCoverVariety = 'ALBUM' | 'SINGLE' | 'EP';

interface IProps{
  type: MusicCoverType;
  variety: MusicCoverVariety;
  image: string;
  title: string;
  year: string;
  duration: string;
  showPlayButton?: boolean;
  selected?: boolean;
  onClick?: any;
}

const MusicCover = (props: IProps) => {
  const detailed = props.type === 'DETAILED';
  const {variety, image, title, year, duration} = props;
  return (
    <div className={style.main}>
      {detailed && <div className={style.header}>
        {(variety === 'SINGLE' || variety === 'EP') && <img src={musicNoteBold}></img>}
        {variety === 'ALBUM' && <img src={album}></img>}
        <span>{variety}</span>
      </div>}
      <div className={`${style.cover} ${(props.selected? style.cover__selected : '')}`} onClick={(e) => props.onClick && props.onClick()}>
        <img src={image} className={style.cover__image}></img>
        <div className={style.overlay}>
          <div className={style.overlay__container}>
            {(variety === 'SINGLE' || variety === 'EP') && <img src={musicNoteBold} className={style.overlay__container__img}>
            </img>}
            {variety === 'ALBUM' && <img src={album} className={style.overlay__container__img}></img>}
            <div className={style.overlay__container__data}>
              <span>{title}</span>
              <span>Year {year} • {duration}</span>
            </div>
          </div>
          {props.showPlayButton && <img src={play} className={style.overlay__play}></img>}
        </div>
        <div className={`${(props.selected? style.cover__selected__frame : '')}`}></div>
      </div>
      {detailed && <div className={style.footer}>
        <div className={style.footer__field}>
          <span>Genre:</span>
          <span>World</span>
        </div>
        <div className={style.footer__field}>
          <span>Sub Genre:</span>
          <span>Arabic</span>
        </div>
      </div>}
    </div>
  );
};

export default MusicCover;
