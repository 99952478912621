import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';
import moment from 'moment';
import "antd/dist/antd.css";
import useRequest from '../../features/API/request';
import style from './Earnings.module.scss';
import './Earning.scss';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import useWindowWidth from '../../hooks/useWindowWidth';
import WhiteCard from '../../components/WhiteCard/WhiteCard';
import {THEMES} from '../../constants/types';
import {MONTH, DATE_RANGE_EARNING} from '../../constants/genericConst';
import PaddedCard from '../../components/PaddedCard/PaddedCard';
import {getIcons, calculateDate} from '../../features/util';
import RechartBar from '../../components/BarChart';
import EarningList from '../../components/EarningList/EarningList';

const colourStyles = {
  control: (styles: any) => ({...styles,
    minWidth: '100px',
    border: '1px solid #d3d6de',
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'}),
  indicatorsContainer: (styles: any) => ({...styles, maxWidth: '50px', justifyContent: 'end'}),
  singleValue: (styles: any) => ({...styles, minHeight: '15px'}),
};
const colourStylesDark = {
  control: (styles: any) => ({...styles,
    minWidth: '100px',
    border: '1px solid #d3d6de',
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    background: '#191919',
    color: '#898989',
    alignItems: 'center'}),
  singleValue: (styles: any) => ({...styles, color: '#fff', minHeight: '15px'}),
  indicatorsContainer: (styles: any) => ({...styles, maxWidth: '50px', justifyContent: 'end'}),
};

const Earnings = () => {
  const earningRequest: any = useRequest({method: 'GET', url: '/earnings', type: 'finance'});
  const earningGraphRequest: any = useRequest({method: 'GET', url: '/earnings/graph', type: 'finance'});

  const me = selectMe(useAppSelector(store.getState)).me;
  // responsiveness
  const mobile = useWindowWidth() <= 890;
  const smallMobile = useWindowWidth() <= 365;

  const [dateSelected, setDateSelected] = useState(moment().format('MM YYYY'));
  const [dateSelectedEnd, setDateSelectedEnd] = useState(moment().format('MM YYYY'));
  const [dateSelectedType, setDateSelectedType] = useState("Last Quater");
  const [graphData, setGraphData] = useState([]);

  const getYearArray = () => {
    const year: any = [];
    for (let i = 2021; i <= moment().year(); i++) {
      year.push({label: i, value: i});
    }
    return year;
  };

  const handleDateChange = (event: any, type: string, category: string) => {
    const categoryValue = category == 'start' ? dateSelected : dateSelectedEnd;
    const year = moment(categoryValue, 'MM YYYY').format('YYYY');
    const month = moment(categoryValue, 'MM YYYY').format('MM');
    const completeDate = type == 'year' ? (month + ' ' + event.value) : (event.value + ' ' + year);
    category == 'start' ? setDateSelected(moment(completeDate, 'MM YYYY').format('MM YYYY')) : setDateSelectedEnd(moment(completeDate, 'MM YYYY').format('MM YYYY'));
  };

  const fetchEarnings = async () => {
    try {
      const {start, end} = calculateDate(dateSelectedType, 'MM YYYY');
      const param: any = {
        startMonth: moment(dateSelectedType == 'Custom' ? dateSelected : start, 'MM YYYY').format('MM'),
        startYear: moment(dateSelectedType == 'Custom' ? dateSelected : start, 'MM YYYY').format('YYYY'),
        endMonth: moment(dateSelectedType == 'Custom' ? dateSelectedEnd : end, 'MM YYYY').format('MM'),
        endYear: moment(dateSelectedType == 'Custom' ? dateSelectedEnd : end, 'MM YYYY').format('YYYY'),
      };
      await earningRequest.doRequest({}, param);
    } catch (error) {
      console.log('something went wrong while submitting music');
    }
  };

  const fetchGraphEarnings = async () => {
    try {
      const {start, end} = calculateDate(dateSelectedType, 'MM YYYY');
      const param: any = {
        startMonth: moment(dateSelectedType == 'Custom' ? dateSelected : start, 'MM YYYY').format('MM'),
        startYear: moment(dateSelectedType == 'Custom' ? dateSelected : start, 'MM YYYY').format('YYYY'),
        endMonth: moment(dateSelectedType == 'Custom' ? dateSelectedEnd : end, 'MM YYYY').format('MM'),
        endYear: moment(dateSelectedType == 'Custom' ? dateSelectedEnd : end, 'MM YYYY').format('YYYY'),
      };
      await earningGraphRequest.doRequest({}, param);
    } catch (error) {
      console.log('something went wrong while submitting music');
    }
  };

  const validateSubmit = () => {
    if (dateSelectedType == 'Custom' && moment(dateSelected, 'MM YYYY').isAfter(moment(dateSelectedEnd, 'MM YYYY'))) {
      toast.error('Select valid date range');
    } else {
      fetchEarnings();
      fetchGraphEarnings();
    }
  };

  const getFormatedString = (value: any) => {
    if (value) {
      const val: any = parseFloat(value).toFixed(2);
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  useEffect(() => {
    if (earningGraphRequest?.data?.graphData) {
      const graphArray: any = [];
      earningGraphRequest?.data?.graphData.map((e: any) => {
        graphArray.push({name: moment(`${e.month} ${e.year}`, 'MM YYYY').format('MMM YYYY'), value: e.total});
      });
      setGraphData(graphArray);
    }
  }, [earningGraphRequest?.data]);

  useEffect(() => {
    if (earningRequest.hasError || earningGraphRequest.hasError) {
      toast.error(earningRequest?.error?.message || 'Something went wrong');
    }
  }, [earningRequest.hasError, earningGraphRequest.hasError]);

  useEffect(() => {
    if (dateSelected != 'Custom') {
      fetchEarnings();
      fetchGraphEarnings();
    }
  }, [dateSelectedType]);

  return (
    <div className={style.wrapper}>
      <WhiteCard style={{...(mobile && {padding: '27px 20px 33px 20px'})}}>
        <div className={style.content} style={{...(mobile && {marginTop: '24px'})}}>
          <div className={style.leftSideContent} >
            <div className={style.dateWrapper}>
              {dateSelectedType != 'Custom' ? <Select value={DATE_RANGE_EARNING.find((e:any) => {
                return e.value == dateSelectedType;
              })} onChange={(e:any) => {
                setDateSelectedType(e.value);
              }} components={{
                IndicatorSeparator: () => null,
              }}
              styles={me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
              options={DATE_RANGE_EARNING} /> : null}
              {dateSelectedType == 'Custom' ? <>
              <div className={style.dateRangeParent}>
                  <div className={style.dateRangeContainer}>
                    <Select value={MONTH.find((e:any) => {
                      return e.value == moment(dateSelected, 'MM YYYY').format('MM');
                    })} onChange={(e:any) => {
                      handleDateChange(e, 'month', 'start');
                    }} components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
                    options={MONTH} />
                    <Select value={getYearArray().find((e:any) => {
                      return e.value == moment(dateSelected, 'MM YYYY').format('YYYY');
                    })} onChange={(e:any) => {
                      handleDateChange(e, 'year', 'start');
                    }} components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
                    options={getYearArray()} />
                  </div>
                  
                  <div style={{alignItems: 'center', display: 'flex'}}> &nbsp; - &nbsp; </div>

                  <div className={style.dateRangeContainer}>
                    <Select value={MONTH.find((e:any) => {
                      return e.value == moment(dateSelectedEnd, 'MM YYYY').format('MM');
                    })} onChange={(e:any) => {
                      handleDateChange(e, 'month', 'end');
                    }} components={{
                      IndicatorSeparator: () => null,
                    }}
                    isOptionDisabled={(option) => parseInt(moment(dateSelected, 'MM YYYY').format('MM')) > parseInt(option.value) && moment(dateSelected, 'MM YYYY').format('YYYY') == moment(dateSelectedEnd, 'MM YYYY').format('YYYY')}
                    styles={me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
                    options={MONTH} />
                    <Select value={getYearArray().find((e:any) => {
                      return e.value == moment(dateSelectedEnd, 'MM YYYY').format('YYYY');
                    })} onChange={(e:any) => {
                      handleDateChange(e, 'year', 'end');
                    }} components={{
                      IndicatorSeparator: () => null,
                    }}
                    isOptionDisabled={(option) => parseInt(moment(dateSelected, 'MM YYYY').format('YYYY')) > parseInt(option.value)}
                    styles={me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
                    options={getYearArray()} />
                  </div>
              </div>
              <div className={style.dateRangeContainer}>
                <button type='button' onClick={validateSubmit}>Search</button>
                <button type='button' onClick={() => setDateSelectedType('Last Month')}>Reset</button>
              </div>
              
              </> : null}
            </div>
            <PaddedCard
              timePeriod={''}
              earningsTimestamp={moment().clone()}
              platformIconList={getIcons(me)}
              previousEarnings={0}
              totalEarnings={getFormatedString(earningGraphRequest?.data?.data?.finalTotal) || 0}
              style={{...(smallMobile && {width: '100%'}), ...(!smallMobile && {paddingLeft: '93px', paddingRight: '93px'}), ...{minWidth: '400px'}}}
              showGrowth={false}
              currency={'USD '}
            />
            <div className={style.chart}>
              {earningGraphRequest.isFetching || (earningGraphRequest.isFetched && !!!graphData.length) ? <div className={style.loaderWrapper}>
                {earningGraphRequest.isFetching && <Loader type="TailSpin" color="#EC1E24" height={100} width={100} />}
                {earningGraphRequest.isFetched && !!!graphData.length && <div>No graph data found.</div>}
              </div> : 
              <RechartBar handleFormater={getFormatedString} currency={'USD'} showPercentage={false} showLegend={false} height={mobile ? 300 : 400} barWidth={mobile ? 12 : 50} tooltip={true} isAnimated={false} data={graphData || []} />
              }
            </div>
          </div>
        </div>
      </WhiteCard>
      <div className={style.transaction}>
      {!earningRequest.hasError && earningRequest.isFetched && !!Object.keys(earningRequest?.data).length ?
       <EarningList currency='USD' data={earningRequest?.data || []} refresh={()=>{}}/> : earningRequest?.isFetching ? <div className={style.wrapper}><div className={style.submitLoader}><Loader type="TailSpin" color="#EC1E24" height={100} width={100} /></div></div> :
       <div style={{padding: '20px', fontSize: '14px', fontStyle: 'italic', textAlign: 'center'}}>
           No data found
       </div>}
      </div>
    </div>
  );
};


export default Earnings;
