import React from 'react';

import {ReactComponent as Overview} from '../assets/images/overview.svg';
import {ReactComponent as Earnings} from '../assets/images/earnings-icon.svg';
import {ReactComponent as Stats} from '../assets/images/Stats-icon.svg';
import {ReactComponent as Audio} from '../assets/images/audio-icon.svg';
import {ReactComponent as Bag} from '../assets/images/Bag.svg';
import {ReactComponent as Media} from '../assets/images/media-icon.svg';
import {ReactComponent as Settings} from '../assets/images/Setting.svg';
import {ReactComponent as Help} from '../assets/images/Help_and_support.svg';

import {ReactComponent as ActiveOverview} from '../assets/images/overview-active.svg';
import {ReactComponent as ActiveEarnings} from '../assets/images/earnings-active.svg';
import {ReactComponent as ActiveStats} from '../assets/images/social-stats-active.svg';
import {ReactComponent as ActiveAudio} from '../assets/images/audio-icon-active.svg';
import {ReactComponent as ActiveBag} from '../assets/images/Bag-active.svg';
import {ReactComponent as ActiveMedia} from '../assets/images/media-icon-active.svg';
import {ReactComponent as ActiveSettings} from '../assets/images/ActiveSettings.svg';
import {ReactComponent as ActiveHelp} from '../assets/images/ActiveHelp.svg';

import {DARKTHEMEMAINGREY, GREYTEXTFIELDCOLOR2, WHITE_COLOR} from './colors';


export const sidebarRoutes = [
  // {
  //   key: 'overview',
  //   route: '/overview',
  //   name: 'Overview',
  //   icon: <Overview stroke={GREYTEXTFIELDCOLOR2} />,
  //   darkIcon: <Overview stroke={DARKTHEMEMAINGREY} />,
  //   activeIcon: <ActiveOverview />,
  // },
  // {
  //   key: 'earnings',
  //   route: '/earnings-old',
  //   label: 'My Business',
  //   name: 'Earnings Legacy',
  //   icon: <Earnings stroke={GREYTEXTFIELDCOLOR2} />,
  //   darkIcon: <Earnings stroke={DARKTHEMEMAINGREY} />,
  //   activeIcon: <ActiveEarnings />,
  // },
  {
    key: 'earnings-new',
    route: '/earnings',
    name: 'Earnings',
    icon: <Earnings stroke={GREYTEXTFIELDCOLOR2} />,
    darkIcon: <Earnings stroke={DARKTHEMEMAINGREY} />,
    activeIcon: <ActiveEarnings />,
  },
  // {
  //   key: 'stats',
  //   route: '/stats',
  //   name: 'Social stats',
  //   icon: <Stats stroke={GREYTEXTFIELDCOLOR2} />,
  //   darkIcon: <Stats stroke={DARKTHEMEMAINGREY} />,
  //   activeIcon: <ActiveStats />,
  // },
  {
    key: 'music',
    route: '/audio-music/upload',
    name: 'Audio/Music',
    icon: <Audio fill={GREYTEXTFIELDCOLOR2} />,
    darkIcon: <Audio fill={DARKTHEMEMAINGREY} />,
    activeIcon: <ActiveAudio />,
  },
  // {
  //   key: 'tools',
  //   route: '/tools',
  //   name: 'Tools',
  //   icon: <Media stroke={GREYTEXTFIELDCOLOR2} />,
  //   darkIcon: <Media stroke={DARKTHEMEMAINGREY} />,
  //   activeIcon: <ActiveMedia />,
  // },
  {
    key: 'settings',
    route: '/settings',
    name: 'Settings',
    label: 'Insights',
    icon: <Settings stroke={GREYTEXTFIELDCOLOR2} />,
    darkIcon: <Settings stroke={DARKTHEMEMAINGREY}/>,
    activeIcon: <ActiveSettings />,
  },
  {
    key: 'help',
    route: '/help',
    name: 'Help & Support',
    icon: <Help stroke={GREYTEXTFIELDCOLOR2} />,
    darkIcon: <Help stroke={DARKTHEMEMAINGREY}/>,
    activeIcon: <ActiveHelp />,
  },
];
