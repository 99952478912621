import React, {useEffect} from 'react';
import moment from 'moment';
import Select from 'react-select';
import 'antd/dist/antd.css';
import {TimePicker} from 'antd';
import Loader from 'react-loader-spinner';
import Datepicker from '../../../components/Datepicker/Datepicker';
import musicTrackStyle from './musicTrack.module.scss';
import TextField from '../../../components/TextField/TextField';
import addPhotoDark from '../../../assets/images/addPhotoDark.svg';
import addSongDark from '../../../assets/images/addSongDark.svg';
import {useHistory} from 'react-router-dom';
import useWindowWidth from '../../../hooks/useWindowWidth';
import {useUploadRequestForMusic} from '../../../features/API/request';
import RadioButton from '../../../components/RadioButton/RadioButton';
import {LANGUAGE} from '../../../constants/language';
import {useAppSelector} from '../../../app/hooks';
import {selectMe} from '../../../features/stores/userSlicer';
import {store} from '../../../app/store';
import {THEMES} from '../../../constants/types';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
     htmlFor?:string;
 }
}

type MusicTraclProps = {
  musicData: any;
  setMusicData: React.Dispatch<React.SetStateAction<any>>;
  setView: React.Dispatch<React.SetStateAction<any>>;
}
const TYPE_OPTIONS = [
  {label: 'Not Explicit', value: 'Not Explicit'},
  {label: 'Explicit', value: 'Explicit'},
  {label: 'Clean', value: 'Clean'},
];
const colourStyles = {
  control: (styles: any) => ({...styles, minHeight: '56px',
    border: '1px solid #d3d6de',
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'}),
  indicatorsContainer: (styles: any) => ({...styles, maxWidth: '50px', justifyContent: 'end'}),
};
const colourStylesDark = {
  control: (styles: any) => ({...styles, minHeight: '56px',
    border: '1px solid #d3d6de',
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    background: '#191919',
    color: '#898989',
    alignItems: 'center'}),
  singleValue: (styles: any) => ({...styles, color: '#fff'}),
  indicatorsContainer: (styles: any) => ({...styles, maxWidth: '50px', justifyContent: 'end'}),
};
const MusicTrack = (props: MusicTraclProps) => {
  const history = useHistory();
  const user = selectMe(useAppSelector(store.getState));
  const {setView, musicData, setMusicData} = props;
  const isMobile = useWindowWidth() <= 890;
  const uploadMusicRequest = useUploadRequestForMusic({
    method: 'POST',
    url: '/upload',
  });
  const uploadMusicSongRequest = useUploadRequestForMusic({
    method: 'POST',
    url: '/upload',
  });
  const back = () => {
    history.push('/audio-music');
  };
  const forward = () => {
    setView('SUBMIT');
  };
  const handleOpenChoseImage = () => {
    document.getElementById('musicPic')?.click();
  };
  const handleOpenChoseSong = () => {
    document.getElementById('musicSong')?.click();
  };
  const handleChange = (value: any, target: string) => {
    setMusicData({...musicData, [target]: value});
  };
  const handleBlur = (value: any, target: string) => {
    if (target == 'youtubeLink' && !/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(value)) {
      setMusicData({...musicData, [target]: ''});
    }
  };
  const handleDateChange = (value: any, target: string) => {
    setMusicData({...musicData, [target]: value});
  };
  const handleTimeChange = (e:any) => {
    if (e) {
      setMusicData({...musicData, consumerReleaseTime: e.format('HH:mm:ss')});
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        await uploadMusicRequest.doRequest({file: e.target.files[0]});
      } catch (error) {
        console.log('something went wrong');
      }
    }
  };

  const handleWaveFile = async (e: any) => {
    const files = e.target.files;
    if (files[0]) {
      try {
        await uploadMusicSongRequest.doRequest({file: files[0]});
      } catch (error) {
        console.log('something went wrong');
      }
    }
  };

  const handleRadioChange = (val: any, param: any) => {
    if (param) {
      setMusicData({...musicData, [param]: val});
    }
  };

  const handleMetadata = (e: any) => {
    setMusicData({...musicData, duration: e.currentTarget.duration});
  };

  const shouldDisable = () => {
    const flowOne = ["title", "song", "artists", "consumerReleaseDate", "consumerReleaseTime", "genre", "subGenre", "artwork", "explicit_content", "language"];
    const flowForPublicRelease = ['youtubeLink', 'originalReleaseDate'];
    let valid = false;
    valid = flowOne.every((e: any) => musicData[e] !== '') && (musicData['previouslyReleased'] == 'Yes' ? musicData[flowForPublicRelease[1]] != '' && (musicData.uploadedOnYoutube == 'Yes' ? musicData[flowForPublicRelease[0]] != '' : true) : true);
    return valid;
  };

  useEffect(()=>{
    if (uploadMusicRequest?.data?.s3_url) {
      setMusicData({...musicData, artwork: uploadMusicRequest?.data?.s3_url});
    }
  }, [uploadMusicRequest.data]);

  useEffect(()=>{
    if (uploadMusicSongRequest?.data?.s3_url) {
      setMusicData({...musicData, song: uploadMusicSongRequest?.data?.s3_url});
    }
  }, [uploadMusicSongRequest.data]);

  return (
    <div className={musicTrackStyle.container}>
      <div className={musicTrackStyle.header}>
        Track Information
      </div>
      <div className={musicTrackStyle.main}>
        <div className={musicTrackStyle.left_section}>
          <div style={{position: 'relative'}}>
            <div onClick={handleOpenChoseImage} className={musicTrackStyle.uploadSegment}>
              <img src={addPhotoDark} />
              <div className={musicTrackStyle.uploadSegment__text_container}>
                {musicData?.artwork ? <img className={musicTrackStyle.uploadArtWork} src={musicData?.artwork} /> : 
                  <>
                    <span>Upload artwork</span>
                    <span>Aspect Ration Square - 1:1 Format JPG, PNG, JPEG 2000</span>
                  </>
                }
              </div>
              <input type='file' id="musicPic" style={{display: 'none'}} accept="image/png, image/jpeg" onChange={(e) => {
                  fileSelectedHandler(e);
                }} />
           </div>
           {uploadMusicRequest.isFetching ? <div className={musicTrackStyle.loader}><Loader type="TailSpin" color="#EC1E24" height={50} width={50} /></div> : null}
          </div>
          <div style={{position: 'relative'}}>
            <div onClick={handleOpenChoseSong} className={musicTrackStyle.uploadSegment}>
              <img src={addSongDark} />
              <div className={musicTrackStyle.uploadSegment__text_container}>
                {musicData?.song ? <><audio onLoadedMetadata={handleMetadata} id="audio" controlsList="nodownload" controls src={musicData.song} /></> :
                  <> 
                    <span>Upload audio</span>
                    <span>File Format: WAV, mp3 Bitrate: above 360Hz</span>
                  </>
                }  
              </div>
              <input type='file' id="musicSong" style={{display: 'none'}} accept="audio/wav" onChange={(e) => {
                  handleWaveFile(e);
                }} />
            </div>
            {uploadMusicSongRequest.isFetching ? <div className={musicTrackStyle.loader}><Loader type="TailSpin" color="#EC1E24" height={50} width={50} /></div> : null}
          </div>
          
        </div>
        <div className={musicTrackStyle.right_section}>
          <div className={musicTrackStyle.right_section__input}>
            <span>Song Title</span>
            <TextField value={musicData.title} type='text' onChange={(val: any) => {
              handleChange(val, 'title');
              }} name='Song Title' placeholder={'Song Title'} styles={{height: 56}}/>
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Artist(s)</span>
            <TextField value={musicData.artists} type='text' onChange={(val: any) => {
              handleChange(val, 'artists');
              }} name='Song Title' placeholder={'Artist(s)'} styles={{height: 56}}/>
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Language</span>
            <Select value={LANGUAGE.find((e:any) => e.value == musicData.language)} onChange={(e:any) => {
              setMusicData({...musicData, language: e.value});
            }} components={{
              IndicatorSeparator: () => null,
            }}
            styles={user.me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
            options={LANGUAGE} />
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Explicit Content</span>
            <Select value={TYPE_OPTIONS.find((e:any) => e.value == musicData.explicit_content)} onChange={(e:any) => {
              setMusicData({...musicData, explicit_content: e.value});
            }} components={{
              IndicatorSeparator: () => null,
            }}
            styles={user.me.theme === THEMES.LIGHT ? colourStyles : colourStylesDark}
            options={TYPE_OPTIONS} />
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Consumer Release Date</span>
            <Datepicker format={'DD/MM/YYYY'}
              style={{
                ...(isMobile ? {fontSize: '13px', width: '100%'}:{fontSize: '15px', width: '100%'}),
                marginTop: '8px', borderRadius: '8px', border: '1px solid #d3d6de', height: '56px',
              }}
              initialDate={moment(musicData.consumerReleaseDate)}
              pickerStyle={{...(isMobile && {left: '50%', marginLeft: '-140px'})}}
              date={musicData.consumerReleaseDate}
              setDate={(val: any)=>{
                handleDateChange(val, 'consumerReleaseDate');
              }}
              allowFuture={true}
              afterClosePicker={()=>setMusicData((prev: any)=>({
                ...prev,
                consumerReleaseDate: musicData.consumerReleaseDate,
              }))}
              minDate={moment().add(1, 'd')}
            />
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Consumer Release Time</span>
            <TimePicker className={musicTrackStyle.timepicker} onChange={handleTimeChange} defaultValue={moment(musicData.consumerReleaseTime, 'HH:mm:ss')} />
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Has this track been released to the public previously?</span>
            <div className={musicTrackStyle.radioButtons}>
              <RadioButton style={{...(isMobile && {transform: 'scale(0.9)'})}} text={'Yes'} setSelectedOption={(e: any) => handleRadioChange(e, 'previouslyReleased')} selectedOption={musicData.previouslyReleased} />
              <RadioButton style={{...(isMobile && {transform: 'scale(0.9)'})}} text={'No'} setSelectedOption={(e: any) => handleRadioChange(e, 'previouslyReleased')} selectedOption={musicData.previouslyReleased} />
            </div>
          </div>
          {musicData.previouslyReleased === 'Yes' ?
          <div className={musicTrackStyle.right_section__input}>
            <span>Original Release Date</span>
            <Datepicker format={'DD/MM/YYYY'}
              style={{
                ...(isMobile ? {fontSize: '13px', width: '100%'}:{fontSize: '15px', width: '100%'}),
                marginTop: '8px', borderRadius: '8px', border: '1px solid #d3d6de', height: '56px',
              }}
              initialDate={moment(musicData.originalReleaseDate)}
              pickerStyle={{...(isMobile && {left: '50%', marginLeft: '-140px'})}}
              date={musicData.originalReleaseDate}
              setDate={(val: any)=>{
                handleDateChange(val, 'originalReleaseDate');
              }}
              allowFuture={true}
              afterClosePicker={()=>setMusicData((prev: any)=>({
                ...prev,
                originalReleaseDate: musicData.originalReleaseDate,
              }))}
            />
          </div> : null}
          {musicData.previouslyReleased === 'Yes' ? <div className={musicTrackStyle.right_section__input}>
            <span>Is this track on YouTube or YouTube music? If so, please enter the URL.</span>
            <div className={musicTrackStyle.radioButtons}>
              <RadioButton style={{...(isMobile && {transform: 'scale(0.9)'})}} id="youtube__yes" text={'Yes'} setSelectedOption={(e: any) => handleRadioChange(e, 'uploadedOnYoutube')} selectedOption={musicData.uploadedOnYoutube} />
              <RadioButton style={{...(isMobile && {transform: 'scale(0.9)'})}} id="youtube__no" text={'No'} setSelectedOption={(e: any) => handleRadioChange(e, 'uploadedOnYoutube')} selectedOption={musicData.uploadedOnYoutube} />
            </div>
          </div> : null}
          {musicData.uploadedOnYoutube === 'Yes' && musicData.previouslyReleased === 'Yes' ?
          <div className={musicTrackStyle.right_section__input}>
            {/* <span>Song Title</span> */}
            <TextField value={musicData.youtubeLink} type='text' onChange={(val: any) => {
              handleChange(val, 'youtubeLink');
              }} onBlur={(val: any) => {
                handleBlur(val, 'youtubeLink');
                }} name='Link' placeholder={'Link'} styles={{height: 56}}/>
          </div> : null}
          {/* <div className={musicTrackStyle.right_section__input}>
            <span>Genre</span>
            <TextField value={musicData.genre} disabled type='text' onChange={(val: any) => {
              handleChange(val, 'genre');
              }} name='Song Title' placeholder={'Genre'} styles={{height: 56}}/>
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Sub Genre</span>
            <TextField value={musicData.subGenre} disabled type='text' onChange={(val: any) => {
              handleChange(val, 'subGenre');
              }} name='Song Title' placeholder={'Sub Genre'} styles={{height: 56}}/>
          </div>
          <div className={musicTrackStyle.right_section__input}>
            <span>Contributor</span>
            <TextField value={musicData.artists} type='text' onChange={(val: any) => {}} disabled={true} name='Song Title' placeholder={'Contributor'} styles={{height: 56}}/>
          </div> */}
          <div className={musicTrackStyle.right_section__buttons}>
            {/* <button onClick={(e) => back()}>Back</button> */}
            {/* <button onClick={(e) => forward()}>Continue</button> */}
            <button disabled={!shouldDisable()} onClick={(e) => forward()}>Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicTrack;
