import React, {useRef, useState} from 'react';
import styles from './EarningList.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import {getDenseCardIcons} from '../DenseCard/DenseCard';
import {THEMES} from '../../constants/types';
import {GREYTEXTFIELDCOLOR2} from '../../constants/colors';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import lightMoney from '../../assets/images/deal.white.svg';
import darkMoney from '../../assets/images/deal.black.svg';
import moment from 'moment';
import Accordion from '../Accordion/Accordion';
import plus from '../../assets/images/Chart.svg';
import darkArrow from '../../assets/images/darkArrow.svg';
import arrow from '../../assets/images/blueArrow.svg';

type TransactionProps = {
    data: any,
    refresh: () => void,
    currency?: string
}

interface ITooltip{
  id: string;
  transaction: any,
  refresh: () => {}
}

const getSortedEarning = (earnings: any, onlySorted: boolean = false) => {
  let sortArray: any = JSON.parse(JSON.stringify(earnings || []));
  if (sortArray && sortArray.length) {
    sortArray = sortArray.sort((a:any, b:any)=>{
      return b?.amount - a?.amount;
    });
    if (onlySorted) {
      return sortArray;
    }
    const arrayWithoutOther = sortArray.filter((e: any) => {
      return e?.revenueStreamId?.name != 'Others';
    });
    const arrayWithOther = sortArray.filter((e: any) => {
      return e?.revenueStreamId?.name == 'Others';
    });
    sortArray = [...arrayWithoutOther, ...arrayWithOther];
  }
  return sortArray;
};

const formatText = (text: any) => {
  return text.replaceAll('_', ' ');
};

const EarningList = ({data, refresh, currency='$'}:TransactionProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  const mobile = useWindowWidth() <= 700;
  return (
    <div className={styles.container}>
      {data && data.length > 0 && data[0] && <div className={styles.header}>
        <span>Platform</span>
        <span>Amount</span>
      </div>}
      {data && data.length > 0 && data[0] ? data?.map((transactions:any)=>
        <div key={transactions?.id} className={styles.wrapper}>
          <div className={styles.date}>{moment(`${transactions.month} ${transactions.year}`, 'MM YYYY').format('MMM YYYY')}</div>
          {getSortedEarning(transactions?.earningsBreakdown)?.map((transaction:any, index:number)=>
            transaction && <div className={styles.transactionContainer} key={transaction.id}>
              <Earning me={me} transaction={transaction} mobile={mobile} refresh={refresh} currency={currency} />
              {index !== transactions.earningsBreakdown.length - 1 ? <div className={styles.separator}/>:<></>}
            </div>)}
        </div>) : 'No transactions available.'}

    </div>);
};

const Earning = ({transaction, me, mobile, refresh, currency}:any)=>{
  const iconName = transaction?.revenueStreamId?.name;
  const icon = iconName.length > 30? iconName : getDenseCardIcons(iconName, me.theme) || me.theme === THEMES.DARK && lightMoney || me.theme === THEMES.LIGHT && darkMoney;
  return (
    transaction?.revenueStreamId?.name == 'Others' ?
    <div style={{marginTop: '20px'}}>
    <Accordion icon={me.theme === THEMES.LIGHT ? arrow : darkArrow} iconPosition='RIGHT' customStyle={{bodyStyle: {paddingLeft: !mobile ? '40px' : '25px', background: '#80808017'}, titleWidth: {width: '100%'}}} title={<div className={styles.transaction} style={{marginTop: '0px'}}>
    <div className={styles.earningIconWrapper}>
        <div className={styles.iconContainer}>
            <img src={icon} className={styles.icon} style={{...(mobile && {width: '32px', height: '32px'})}} />
            <div className={styles.typeContainer}>
                <div className={`${styles.platform} header`} style={{...(mobile && {fontSize: '14px'})}}>{formatText(transaction?.revenueStreamId?.name)}</div>
                <div className={styles.type} style={{
                ...(me.theme === THEMES.LIGHT && {color: GREYTEXTFIELDCOLOR2}),
                ...(mobile && {fontSize: '12px'})}}>
                  {/* {transaction?.revenueStreamId?.name} */}
                </div>
            </div>
        </div>
    </div>
    <div className={styles.options} style={{...(mobile && {width: 'auto', padding: 0, alignItems: 'center', display: 'flex', fontWeight: 500})}}>
      <div className={`${styles.amount} header`} style={{...(mobile && {fontSize: '14px', fontWeight: 500, marginRight: '-10px'}), ...(!mobile && {marginRight: '20px'})}} >{transaction.amount < 0 ? `- ${currency} `+Math.abs(transaction.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : `+ ${currency} `+ Number(transaction.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
    </div>
  </div>
}>
  {getSortedEarning(transaction?.revenueStreamId?.bifurcation, true)?.map((other: any, index: number) => {
    const iconName = other?.revenueStreamId?.name;
    const icon = iconName.length > 30? iconName : getDenseCardIcons(iconName, me.theme) || me.theme === THEMES.DARK && lightMoney || me.theme === THEMES.LIGHT && darkMoney;
    return (<div className={styles.transaction} key={`${other?.revenueStreamId.name}-${index}`}>
      <div className={styles.earningIconWrapper}>
          <div className={styles.iconContainer}>
              <img src={icon} className={styles.icon} style={{...(mobile && {width: '32px', height: '32px'})}} />
              <div className={styles.typeContainer}>
                  <div className={`${styles.platform} header`} style={{...(mobile && {fontSize: '14px'})}}>{formatText(other?.revenueStreamId?.name)}</div>
                  <div className={styles.type} style={{
                  ...(me.theme === THEMES.LIGHT && {color: GREYTEXTFIELDCOLOR2}),
                  ...(mobile && {fontSize: '12px'})}}>
                    {/* {transaction?.revenueStreamId?.name} */}
                  </div>
              </div>
          </div>
      </div>
      <div className={styles.options} style={{...(mobile && {width: 'auto', padding: 0, alignItems: 'center', display: 'flex', fontWeight: 500})}}>
        <div className={`${styles.amount} header`} style={{...(mobile && {fontSize: '14px', fontWeight: 500, marginRight: '16px'})}} >{other.amount < 0 ? `- ${currency} `+Math.abs(other.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : `+ ${currency} `+ Number(other.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
      </div>
    </div>);
  })}
  </Accordion></div> : <div className={styles.transaction}>
    <div className={styles.earningIconWrapper}>
        <div className={styles.iconContainer}>
            <img src={icon} className={styles.icon} style={{...(mobile && {width: '32px', height: '32px'})}} />
            <div className={styles.typeContainer}>
                <div className={`${styles.platform} header`} style={{...(mobile && {fontSize: '14px'})}}>{formatText(transaction?.revenueStreamId?.name)}</div>
                <div className={styles.type} style={{
                ...(me.theme === THEMES.LIGHT && {color: GREYTEXTFIELDCOLOR2}),
                ...(mobile && {fontSize: '12px'})}}>
                  {/* {transaction?.revenueStreamId?.name} */}
                </div>
            </div>
        </div>
    </div>
    <div className={styles.options} style={{...(mobile && {width: 'auto', padding: 0, alignItems: 'center', display: 'flex', fontWeight: 500})}}>
      <div className={`${styles.amount} header`} style={{...(mobile && {fontSize: '14px', fontWeight: 500, marginRight: '16px'})}} >{transaction.amount < 0 ? `- ${currency} `+Math.abs(transaction.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : `+ ${currency} `+ Number(transaction.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
    </div>
  </div>);
};
export default EarningList;
