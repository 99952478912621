/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './TermsAndConditionsCreator.scss';

const TermsAndConditionsCreator = () => {
  return (
    <>
      <header className='header'>
        <div className='header__content'>
          <h2>Alfan Link</h2>
          <p>Creator Terms and Conditions</p>
        </div>
      </header>
      <div className="terms-and-conditions">
        <section>
          <p>
          Welcome to Alfan (
            <a href="https://alfangroup.com/">https://alfangroup.com/</a>): The
          Alfan platform (Platform) is owned and operated by Alfan Digital Media
          Group FZ-LLC, which has license number 94312 and has its registered
          office at Dubai Media City, Building 2 (Alfan, we, us and our). These
          platform terms of use (Terms) apply to you as a user of the Platform
          (you, your) and us, including the products, services, tools, and
          information made available on the Platform.
          </p>
          <p>
          Please read these Terms carefully before agreeing to use the Platform.
          By using the Platform in any capacity, you agree that: (i) you have
          read and familiarised yourself with these Terms; (ii) you understand
          these Terms; and (iii) you agree to be bound by these Terms when using
          the Platform. If you do not agree to these Terms, you should
          immediately stop using the Platform and inform Alfan. We reserve the
          right to modify or amend these Terms, the Platform or any content on
          the Platform from time to time, including for security, legal or
          regulatory reasons, or to reflect updates or changes to the services
          or functionality of the Platform.
          </p>
          <p>
          Through the use of the Platform, you can, in compliance with these
          Terms:
          </p>
          <ul>
            <li>set up online stores as part of your account profile;</li>
            <li>
            offer, promote, market and display various goods and / or services
            on your online stores including, without limitation, products,
            digital products, consulting services, advertising and promotional
            services and courses, whether on your own behalf or as a promotional
            activity for others (Goods and Services);
            </li>
            <li>
            gain access to potential buyers and consumers, including leading
            brands and high-profile partners (each a Buyer) that wish to
            purchase your Goods and Services or gain access to content generated
            by you (Content) for their own consumption or to promote their
            brands and events; and
            </li>
            <li>
            grant access to Goods and Services available from your affiliates.
            </li>
          </ul>
          <p>
          In order to become an approved vendor (Vendor) on the Platform and be
          eligible to upload Content, offer Goods and Services for sale and/or
          accept an engagement from a Buyer, you must comply with these Terms
          and the terms of any agreement that you enter into with such Buyer.
          These Terms include and make reference to Our Privacy Policy.
          </p>
        </section>

        <h2>1. The Platform</h2>
        <section>
          <p>
          The Platform is provided by us to enable you to upload Content and
          offer Goods and Services and to directly engage with Buyers for the
          purposes of providing Content, offering Goods and Services, or
          promoting and selling their Goods and Services (a Transaction) on
          terms mutually agreeable between you and the relevant Buyer, subject
          to these Terms.
          </p>
        </section>

        <h2>2. Who sets the terms of the Transactions</h2>
        <section>
          <p>
          2.1. You are responsible for negotiating the terms of any Transaction
          with any Buyer with whom you transact with via the Platform. You may
          use your standard terms and the Buyers may use their standard
          engagement terms for each Transaction by uploading them to the
          Platform.
          </p>
          <p>
          2.2. Any Transaction agreed between you and a Buyer represents a
          contract exclusively between you and the relevant Buyer. It is a
          condition for the use of the Platform that you represent and warrant
          that you have satisfied yourself as to the completeness and adequacy
          of the terms of any Transaction that you execute with a Buyer and that
          you expressly release Alfan, its officers, directors, employees and
          affiliates from any liability regarding the completeness, adequacy or
          enforceability of the terms of any Transaction or the Buyer’s
          performance in respect of any Transaction.
          </p>
        </section>

        <h2>3. What is Alfan’s role in Transactions</h2>
        <section>
          <p>
          3.1. Transactions on the Platform are strictly and directly between
          you and the Buyer and we have no role in any Transaction, except as
          may be expressly described on the Platform. Without limiting the
          foregoing, we:
          </p>
          <ul>
            <li>
            3.1.1. are not party to any Transaction formed between you and a
            Buyer;
            </li>
            <li>
            3.1.2. are not an agent, partner, broker, intermediary, auctioneer,
            buyer, guarantor or representative of any user of the Platform or
            any party that access the Platform;
            </li>
            <li>
            3.1.3. are not providing any form of guarantee, warranty or other
            surety in relation to any Content or Goods and Services available
            through the Platform, your conduct or the conduct of any Buyer or
            any Transaction executed between you and any Buyer, and, without
            limiting the foregoing, we do not guarantee the quality, quantity,
            legality or safety of any Content or Goods and Services, or the
            veracity, accuracy or truth of information or claims made by users
            on the Platform regarding Content or Goods and Services,
            Transactions or otherwise including, without limitation, the
            competence, trustworthiness or ability of users of the Platform to
            complete Transactions and / or comply with their obligations under
            Transactions;
            </li>
            <li>
            3.1.4. do not possess, deliver, supply, manage, oversee, track or
            otherwise undertake any action in relation to Goods and Services;
            </li>
            <li>
            3.1.5. do not verify, check, warrant or make any representations in
            relation to Content, Goods and Services, Transactions or any
            Content, claim, listing or description by you or Buyers on the
            Platform;
            </li>
            <li>
            3.1.6. do not represent, warrant or guarantee that any posting,
            linking, listing or uploading of, or in relation to, any Content or
            Goods and Services will be available or searchable for any length of
            time or with any degree of prominence or promotion; and
            </li>
            <li>
            3.1.7. do not mediate, resolve, adjudicate or otherwise assist in
            any manner whatsoever in relation to disputes or problems in
            relation to Content, Goods and Services, Transactions or otherwise.
            </li>
          </ul>
          <p>
          3.2. Any system on the Platform providing ratings and/or feedback on
          you or any other Vendors, or any Buyers is not guaranteed or verified.
          You are responsible for reading and understanding any content on the
          Platform and the terms of any Transaction, and for undertaking your
          own investigation on, and forming your own view at your risk on, the
          quality, veracity, competence, financial standing, trustworthiness
          and/or ability of any counterparty involved in any Transaction for
          Content or Goods and Services.
          </p>
          <p>
          3.3. You release and indemnify us to the maximum extent permitted by
          law in respect of any liability, obligation, cost, expense, damage,
          action or claim of whatsoever nature connected to your use of the
          Platform, any Content, Goods and Services and/or any Transaction.
          </p>
        </section>

        <h2>4. How are fees paid and refunds handled</h2>
        <section>
          <p>
          4.1. On execution of a Transaction, the Buyer will pay the full amount
          of any fees you have agreed with the Buyer (Fees), together with all
          relevant taxes and VAT amounts, into our escrow account.
          </p>
          <p>
          4.2. Payments made in respect of Transactions are made through a
          third-party payment gateway (Payment Gateway). When a Buyer selects
          the link to pay for Content or Goods and Services on the Platform,
          they will be redirected to the Payment Gateway.
          </p>
          <p>
          4.3. You will be notified by SMS and/or email when we receive the
          payment of the Fees and when we release the Fees (less our commission
          and payment processing fees) to you.
          </p>
          <p>
          4.4. We will release the Fees (less our commission and payment
          processing fees) to you 10 (ten) working days after the fees have been
          received from the Buyer.
          </p>
          <p>
          4.5. In the event of a dispute, the Buyer will contact us via{' '}
            <a href="mailto:transactiondispute@alfangroup.com">
            transactiondispute@alfangroup.com
            </a>
          . We will provide details of the dispute to you together with the
          Buyer’s contact details. We will not act as an intermediary or
          arbitrate in any dispute between you and any Buyer. Any communication,
          claim, demand or suit related to any Transaction, Content or Goods and
          Services and/or payments related thereto are the responsibility of you
          and the Buyer, including, without limitation, in relation to any
          payment refund or failure, warranty, quality or quantity of any
          Content or Goods and Services or claim concerning the supply, delivery
          or return of any Goods and Services except as provided herein.
          </p>
          <p>
          4.6. Where you agree to refund the Fee to the Buyer, you will notify
          us via{' '}
            <a href="mailto:refund@alfangroup.com">refund@alfangroup.com</a>{' '}
          requesting a refund to the relevant Buyer. Any amounts refunded by us
          to a Buyer shall be net of our commission and any applicable banking
          charges.
          </p>
          <p>
          4.7. We are not responsible for any acts or omissions of the Payment
          Gateway provider or for any errors or technical problems of the
          Payment Gateway or the payment method you select or the means the
          Buyer selects to receive such refund. To the fullest extent permitted
          by law, you release and indemnify us from any and all liabilities,
          costs, expenses, claims, demands or suits connected to any payments
          made to or from the Platform and/or the Payment Gateway.
          </p>
          <p>4.8. You acknowledge and agree that:</p>
          <ul>
            <li>
            4.8.1. neither us, nor the Payment Gateway provider is obliged to
            accept any payment under the Payment Gateway, and may refuse, block
            or refund the payment without further reference to you;
            </li>
            <li>
            4.8.2. neither us, nor the Payment Gateway provider is liable for
            any delay in payment nor the consequences thereof;
            </li>
            <li>
            4.8.3. any communication, claim, demand or suit related to any
            Transaction, Content or Goods and Services and/or payments related
            thereto are the responsibility of you and the Buyer with whom you
            engage in a Transaction with including, without limitation, in
            relation to any payment refund or failure, warranty, quality or
            quantity of any Content or Goods and Services or claim concerning
            the supply, delivery or return of any Goods and Services;
            </li>
            <li>
            4.8.4. there may be third-party banking or other transaction costs
            related to payments to or from the Payment Gateway, and we reserve
            the right to deduct, or authorise the Payment Gateway provider to
            deduct, such costs from the amount paid to you or charge you such
            costs (including in relation to any currency conversion);
            </li>
            <li>
            4.8.5. to the fullest extent permitted by law, we are not providing
            any financial service, advice or product to you through the Platform
            (including, without limitation, in relation to the Payment Gateway);
            </li>
            <li>
            4.8.6. use of the Payment Gateway is also subject to the Payment
            Gateway provider's terms and conditions which you will comply with;
            and
            </li>
            <li>
            4.8.7. payment methods, processes timings and records are limited to
            those offered or available on the Payment Gateway from time to time.
            </li>
          </ul>
        </section>

        <h2>5. How to offer Content and Goods and Services on the Platform</h2>
        <section>
          <p>
          5.1. Any posting, linking, listing or uploading of any Content or
          Goods and Services by you on the Platform must:
          </p>
          <ul>
            <li>5.1.1. be accurate and otherwise comply with these Terms;</li>
            <li>
            5.1.2. be clear and recognisable, and contain a detailed description
            of the Content and/or Goods and Services and all relevant
            information including, without limitation, the full all-inclusive
            price;
            </li>
            <li>
            5.1.3. contain prominently displayed or linked Transaction terms and
            conditions if you intend to rely on such terms and conditions;
            </li>
            <li>
            5.1.4. if you are not the ultimate vendor or supplier of the Goods
            and Services, and/or if you are not fulfilling the delivery or
            supply of the Goods and Services, you must identify that person,
            giving address and contact details (provided such information must
            not be a personal physical address, email address or mobile or
            telephone number); and
            </li>
            <li>
            5.1.5. contain your clear and prominent contact information
            (provided such information must not be a personal physical address,
            email address or mobile or telephone number).
            </li>
          </ul>
          <p>
          5.2. The following terms and conditions apply to all Transactions on
          the Platform:
          </p>
          <ul>
            <li>
            5.2.1. the terms of a Transaction may be amended or modified at any
            time up to payment for the Transaction is made by the Buyer;
            </li>
            <li>
            5.2.2. on payment by the Buyer, the Transaction constitutes a
            legally binding agreement between you and the Buyer;
            </li>
            <li>
            5.2.3. such legally binding agreement obliges you to supply the
            relevant Content or Goods and Services, to the Buyer or to the
            Buyer’s direction as soon as commercially practicable;
            </li>
            <li>
            5.2.4. you must provide the Buyer with a proper receipt as soon as
            commercially practicable (including, without limitation, any VAT as
            applicable);
            </li>
            <li>
            5.2.5. all Content and Goods and Services supplied pursuant to a
            Transaction are supplied on an "as is" basis, subject to the terms
            of the relevant Transaction and all warranties and representations
            are excluded to the maximum extent permitted by law; and
            </li>
            <li>
            5.2.6. amounts paid by the Buyer in respect of a Transaction are
            non-refundable, except in the case of complete failure of supply or
            delivery.
            </li>
          </ul>
          <p>
          5.3. You and Buyer are free to negotiate bespoke terms for a
          Transaction, including:
          </p>
          <ul>
            <li>
            5.3.1. the time of supply or delivery of the applicable Content or
            Goods and Services;
            </li>
            <li>
            5.3.2. the warranties given in respect of the applicable Content or
            Goods and Services; and
            </li>
            <li>5.3.3. the terms under which refunds may be made,</li>
          </ul>
          <p>
          provided such supplemental terms and conditions are not otherwise
          inconsistent with these Terms or provide for credit, instalment or
          deposit payments, delivery prior to payment or staged delivery.
          </p>
        </section>

        <h2>6. The limits and restrictions on Alfan’s warranties</h2>
        <section>
          <p>
          6.1. The Platform is provided “as is,” without any express or implied
          warranties of any kind. We and our affiliates, agents, and licensors
          cannot and do not warrant the accuracy, reliability, quality or
          completeness of the information provided on the Platform nor that the
          products or events offered by any Buyer that may engage you to promote
          or sell will be timely, non-infringing, of merchantable quality or fit
          for a particular purpose, and we hereby disclaim any and all such
          express or implied warranties, except those that cannot be lawfully
          excluded.
          </p>
          <p>
          6.2. Although we make every effort to ensure that the services offered
          on the Platform are available consistently, the nature of the Internet
          means that we cannot guarantee the Platform’s availability. We do not
          warrant that the provision of the Platform or any part of it will be
          available, uninterrupted or error free, that defects in the Platform
          will be corrected, or that the Platform will be free of viruses or
          other harmful components. We will not be liable for any loss, damage,
          expense, costs, delays or other liability whatsoever (including
          without limitation any financial losses) which you may incur as a
          result of using the Platform, or as a result of the Platform or any
          part of it being unavailable at any time for whatever reason.
          </p>
          <p>
          6.3. Although we make every effort to ensure that the Buyers presented
          on the Platform are professional and reliable, we cannot guarantee
          their performance in respect of the terms of any Transaction. We do
          not warrant that any Buyer will complete the terms of any Transaction
          satisfactorily, or at all. We will not be liable for any loss, damage,
          expense, costs, delays or other liability whatsoever (including
          without limitation any financial losses) which you may incur as a
          result of entering into any Transaction with any Buyer introduced
          through the Platform.
          </p>
          <p>
          6.4. You are solely responsible for obtaining telecommunications
          access to the Platform and for any fees (such as internet service
          provider or airtime charges) relating to such access. Similarly, you
          are solely responsible for all equipment necessary for you to access
          the Platform and for implementing and maintaining antivirus software
          on your computer.
          </p>
          <p>
          6.5. Information transmitted via the Platform will pass over public
          telecommunications networks. We accept no liability if communications
          sent via the Platform are intercepted by third parties, delayed or
          incorrectly delivered or not delivered.
          </p>
          <p>
          6.6. We are unable to deal with or provide services to any OFAC
          (Office of Foreign Assets Control) sanctioned countries in accordance
          with the law of the United Arab Emirates.
          </p>
          <p>
          6.7. You will, at your own expense, comply with all laws and
          regulations relating to any Content you provide, Goods and Services
          you offer for sale or promote, and any Transactions you execute
          through the Platform including, without limitation, obtaining and
          holding all licences, registrations, permits and approvals as may be
          needed.
          </p>
          <p>
          6.8. You will comply with all applicable laws, statutes, regulations
          and codes relating to anti-bribery, anti-corruption, anti-money
          laundering, sanctions, prohibited or restricted goods and services and
          terrorism funding.
          </p>
        </section>

        <h2>7. The Representations and Warranties that you make to us</h2>
        <section>
          <p>7.1. By accepting the terms of any Transaction, you:</p>
          <ul>
            <li>
            7.1.1. confirm that you understand the terms of such Transaction and
            will comply with the terms of such Transaction (including, but not
            limited to, any specific guidelines regarding the scope, material
            details and requirements for any promotional campaign applicable to
            such Transaction); and
            </li>
            <li>
            7.1.2. when a Transaction involves promoting a Buyer’s Goods and
            Services (including events or other activities) you shall not, for
            the duration of such Transaction (unless otherwise permitted under
            the terms of such Transaction), accept an engagement or contract
            with any party that is in direct or indirect competition with the
            relevant Buyer or undertake to promote, advertise or otherwise
            endorse goods and Services that are in direct or indirect
            competition with any Goods or Services the subject of such
            Transaction.
            </li>
          </ul>
          <p>7.2. You undertake to:</p>
          <ul>
            <li>
            7.2.1. inform us immediately of any criminal prosecution or other
            complaint brought against you and of any actual or likely press
            speculation or inquiry into you, your personal or business affairs,
            or publication in relation to such matters;
            </li>
            <li>
            7.2.2. inform us as promptly as reasonably practicable of any
            material developments or changes in your circumstances or activities
            which could reasonably be expected to adversely affect us or any
            Buyer with whom you have existing, or are in the process of
            negotiating, the terms of a Transaction; and
            </li>
            <li>
            7.2.3. not participate in any political debate, recreational
            activities, sports or drug use (other than for medical purposes) or
            other activity which in our reasonable opinion would jeopardise your
            ability to fulfil the terms of any Transaction or prejudice the
            goodwill or reputation of Alfan, or during the operation of any
            Transaction, any relevant Buyer or the Content or Goods and Services
            the subject of such Transaction.
            </li>
          </ul>
          <p>
          7.3. You warrant, represent and undertake to us, any Buyer mentioned
          in any Transaction for the duration of such Transaction, that:
          </p>
          <ul>
            <li>
            7.3.1. you have the legal capacity and are free contractually to
            enter into and to perform this agreement (and any Transaction) and
            have not entered and will not enter into any professional, legal or
            other commitment which would or might conflict with or prevent their
            doing so;
            </li>
            <li>7.3.2. you do not have any criminal convictions of any kind;</li>
            <li>
            7.3.3. any Content provided by you pursuant to any Transaction will
            be wholly original to you (save to the extent that it incorporates
            material provided by the relevant Buyer in respect of any Goods and
            Services of such Buyer) and will not infringe the copyright or any
            other rights of any third party;
            </li>
            <li>
            7.3.4. you hold the rights to the Content you have granted to the
            Buyer absolutely and you have not previously assigned, licensed or
            in any way encumbered them (save under the terms of use of the
            social media platform where the copyright works are posted) and you
            agree not to do so in the future;
            </li>
            <li>
            7.3.5. the Content you produce will not contain any defamatory
            matter nor breach any contract or law nor breach any duty of
            confidentiality, infringe any copyright or data protection rights,
            nor constitute contempt of court or obscenity;
            </li>
            <li>
            7.3.6. you hold all required licences to sell any Goods and Services
            you offer for sale through the Platform;
            </li>
            <li>
            7.3.7. you have and shall maintain throughout the term of any
            relevant Transaction, product and public liability insurance against
            losses arising from any claims, actions or damages which may arise
            as a direct or indirect result of any use of any Goods and Services
            and shall provide a copy of the policy to us on request;
            </li>
            <li>
            7.3.8. all of the Goods and Services you offer through the Platform
            are, to the best of your knowledge, safe and fit for their intended
            use;
            </li>
            <li>
            7.3.9. you have disclosed in writing to us all material facts that
            are relevant to your engagement with us as a Vendor, including the
            nature and duration of past and existing endorsement agreements
            between you and third parties and endorsement agreements that are
            likely to be concluded during the term of this agreement;
            </li>
            <li>
            7.3.10. you will act professionally and with good faith at all
            times;
            </li>
          </ul>
          <p>
          7.4. Where you accept a Transaction to promote a Buyer’s Goods and
          Services, you represent and warrant to us that:
          </p>
          <ul>
            <li>
            7.4.1. you understand fully the nature, function and brand image of
            the relevant Goods and Services;
            </li>
            <li>
            7.4.2. you will not knowingly or negligently take any action or omit
            to take any action that would be detrimental to the goodwill
            associated with Alfan, any Buyer or the Platform or create
            unfavourable publicity, or bring into disrepute Alfan, any Buyer or
            the Platform;
            </li>
            <li>
            7.4.3. you will notify us as soon as practicable if you are asked to
            be involved in any activity or other work which, in your reasonable
            opinion, is demeaning, obscene, offensive or racist, or which would
            otherwise impugn your, work or career;
            </li>
            <li>
            7.4.4. you will not engage or otherwise contract with any Buyer
            introduced to you, or of whom you otherwise become aware, by virtue
            of your access to or use of the Platform, to promote, advertise or
            in any way support such Buyer’s Goods and Services for a period of
            twelve (12) months following the termination of this agreement; and
            </li>
            <li>
            7.4.5. you shall comply with the obligations applicable to
            advertisers and brands in the ISBA Influencer Marketing Code of
            Conduct in your dealing any Buyer, provided that the relevant
            Transaction shall take precedence if it conflicts with that code.
            </li>
          </ul>
        </section>

        <h2>8. Conflicts of interest</h2>
        <section>
          <p>
          8.1. You acknowledge and agree that we, one of our affiliated
          companies, officers, employees or some other person connected with us
          may have an interest, relationship or arrangement with one or more
          Buyers that is material in relation to the terms of any Transaction or
          transaction you enter into via the Platform and which could give rise
          to a conflict of interest. You agree that we shall not be required to
          disclose such conflict of interest to you and that there will be no
          obligation for us to account to you for any such interest,
          relationship or arrangement, unless required by the applicable law.
          The services provided via the Platform are unbiased and provided
          independently and with no regard to any such interest, relationship or
          arrangement.
          </p>
        </section>

        <h2>9. Limitation of Liability and Indemnity</h2>
        <section>
          <p>
          9.1. Your use of the Platform, the offering for sale or promotion of
          any Goods and Services, the generation of any Content, and any
          engagement with a Buyer is entirely at your own risk. Neither we nor
          any of our affiliates, agents, officers, employees, suppliers or
          licensors will be liable to you or anyone else for any:
          </p>
          <ul>
            <li>
            9.1.1. inaccuracy, delay, interruption in service, error or
            omission, regardless of cause or for any damages as a result;
            </li>
            <li>
            9.1.2. decision made or action taken or not taken by you in reliance
            on the Platform or its content;
            </li>
            <li>
            9.1.3. damages, including, without limitation, any direct, indirect,
            incidental, consequential (including loss of profits or business),
            special, punitive, exemplary or similar damages, regardless of the
            theory of liability and even if advised of the possibility of such
            damages in advance;
            </li>
            <li>
            9.1.4. loss or injury resulting from use of the content or the
            Platform, in whole or part, whether caused by negligence or
            contingencies beyond its control in procuring, compiling,
            interpreting, reporting or delivering the Platform and its content
            or otherwise;
            </li>
            <li>
            9.1.5. losses, damages, liabilities, claims or expenses (including
            without limitation to legal costs and defence or settlement costs)
            whatsoever, whether direct, indirect or consequential, arising out
            of or referable to your use of the Platform, its content or the
            terms of any Transaction, howsoever caused, whether in contract,
            tort (including negligence), statute or otherwise; or
            </li>
            <li>
            9.1.6. loss resulting from a cause over which we do not have
            control.
            </li>
          </ul>
          <p>
          9.2. Our total liability to you in connection with these Terms, any
          Transaction and any other terms you may subscribe to in connection
          with your use of the Platform or engagement with any Buyer, shall not
          exceed AED1.
          </p>
          <p>
          9.3. All exclusion and limitations of liability set out in these Terms
          shall apply to the fullest extent permissible under the applicable
          law.
          </p>
          <p>
          9.4. You shall indemnify us in full in respect of any liability
          incurred by us for any loss, cost, damage, or expense howsoever
          caused, or suffered by us as a result of your breach of these Terms or
          terms of any Transaction. Where you use the Platform inappropriately
          or maliciously or breach the terms of terms of any Transaction, you
          agree that you will indemnify us against any losses suffered by us or
          any third parties as a result of or in connection with such use.
          </p>
        </section>

        <h2>10. Your eligibility for registration on the Platform</h2>
        <section>
          <p>
          10.1. In order to access the Platform, you must be a minimum of
          twenty-one (21) years old (measured according to the Gregorian
          calendar) and have full legal capacity. You warrant, represent and
          undertake that you possess the legal right and experience, knowledge
          and ability to access and use the Platform in accordance with these
          Terms.
          </p>
          <p>
          10.2. In order to access the Platform and become a Vendor, you will be
          required to follow a sign-up process. Firstly, you will be required to
          submit some detailed information about yourself and your social media
          accounts, and the products or services that you have previously
          endorsed or promoted. You shall be given a unique username and initial
          password. You will be prompted to change the initial password given to
          you when you first access the Platform. The username is the method
          used by us to identify you and so you must keep the username and
          password secure at all times.
          </p>
          <p>
          10.3. We will also require you to provide proof of identity and proof
          of current address in order to comply with anti-money laundering
          obligations. Applications to become a Vendor will be subject to an
          approval process and may be accepted or rejected by us at our sole
          discretion.
          </p>
          <p>
          10.4. You warrant, represent and undertake that the information you
          provide during the registration process about you is accurate, current
          and complete and that you will promptly notify us of any changes to
          the information you provide to us, including changes in address and
          contact details.
          </p>
        </section>

        <h2>11. How you can access the Platform</h2>
        <section>
          <p>
          11.1. The Platform is generally intended to be viewed by a
          conventional web browser with a screen resolution of 1024 by 768
          pixels or greater. Although you may use other means to access the
          Platform, be aware that the Platform may not appear accurately through
          other access methods.
          </p>
          <p>
          11.2. You must not access the Platform through devices or services
          that are designed to provide high-speed, automated, repeated access.
          </p>
          <p>
          11.3. The Platform is protected by a password and requires a login.
          You must not obtain or attempt to obtain unauthorised access to the
          Platform, or to any other protected materials or information, through
          any means not intentionally made available by us for your specific
          use.
          </p>
        </section>

        <h2>12. Your Log in Details</h2>
        <section>
          <p>
          12.1. Each time you log onto the Platform you will need to enter your
          username and password and you may be required to enter a password.
          Your username and password are unique to you and are not transferable.
          </p>
          <p>
          12.2. You are responsible for all information and activity on the
          Platform by anyone using your username and password. Accordingly, you
          must take steps to protect the confidentiality of your user’s name and
          password and you must notify us immediately if you become aware of any
          disclosure, loss, theft or unauthorised use of the same.
          Notwithstanding the above, you:
          </p>
          <ul>
            <li>
            12.2.1. may not transfer or resell your username or password to any
            third party; and
            </li>
            <li>
            12.2.2. agree to notify us at{' '}
              <a href="mailto:support@aflangroup.com">support@aflangroup.com</a>{' '}
            if you become aware of any possible unauthorised use(s) of your
            username or password or any possible breach of security, including
            loss, theft, or unauthorised disclosure of your username or
            password.
            </li>
          </ul>
          <p>
          12.3. If you authorise an employee, sub-contractor or agent to use
          your login details you will be responsible for their activity on the
          Platform. We reserve the right not to act on your instructions where
          we suspect that the person logged onto the Platform via your username
          is not you or we suspect illegal or fraudulent activity or
          unauthorised use.
          </p>
        </section>

        <h2>13. How you may link to the Platform</h2>
        <section>
          <p>
          13.1. You may link to the Platform’s home page, provided you do so in
          a way that is fair and legal and does not damage our reputation or
          take advantage of it. You must not establish a link in such a way as
          to suggest any form of association, approval or endorsement on our
          part where none exists.
          </p>
          <p>
          13.2. You must not establish a link to the Platform in any website
          that is not owned by you. The Platform must not be framed on any other
          site, nor may you create a link to any part of the Platform other than
          the home page.
          </p>
          <p>
          13.3. We reserve the right to withdraw linking permission without
          notice.
          </p>
          <p>
          13.4. We assume no responsibility for the use of, or inability to use,
          any third-party software or other materials on the Platform and shall
          have no liability whatsoever to any person or entity for their use of,
          or inability to use, any such third-party software or materials. We do
          not have access to, or control over, the information these third
          parties may collect. The information practices of these third parties
          are not covered by our privacy policy.
          </p>
          <p>
          13.5. We are not responsible for the privacy policies of websites to
          which the Platform links. If you provide any information to such third
          parties different rules regarding the collection and use of your
          personal information may apply. You should contact these entities
          directly if you have any questions about their use of the information
          that they collect.
          </p>
          <p>
          13.6. If you wish to link to or make any use of content on the
          Platform other than that set out in these Terms, please contact{' '}
            <a href="mailto:info@alfangroup.com">info@alfangroup.com</a>.
          </p>
        </section>

        <h2>14. How we make the Platform available</h2>
        <section>
          <p>
          14.1. As electronic services are subject to interruption or breakdown,
          access to the Platform is offered on an “as is” and “as available”
          basis only.
          </p>
          <p>
          14.2. We reserve the right to limit the availability of the Platform
          to any person, geographic area or jurisdiction we so desire and/or to
          terminate your access to and use of the Platform, at any time and in
          our sole discretion.
          </p>
          <p>
          14.3. We may, at our sole discretion, impose limits or restrictions on
          the use you may make of the Platform. Further, for commercial,
          security, technical, maintenance, legal or regulatory reasons, or due
          to any breach of these Terms, we may withdraw the Platform or your
          access to the Platform, or suspend such access, at any time and
          without notice to you.
          </p>
        </section>

        <h2>15. Your restrictions on use of the Platform</h2>
        <section>
          <p>
          15.1. You will comply with all applicable domestic and international
          laws, statutes, ordinances and regulations applicable to your use of
          the Platform and will not use the Platform or its content for any
          unlawful purpose.
          </p>
          <p>
          15.2. You agree that you will not (either yourself or through any
          third party):
          </p>
          <ul>
            <li>
            15.2.1. use the Platform in any manner that could, or could
            reasonably be expected to, damage or overburden any of our servers,
            or any network connected to any of our servers;
            </li>
            <li>
            15.2.2. use the Platform in any manner that would, or could
            reasonably be expected to, interfere with any other party’s use of
            the Platform or which is, or which is likely to be, in contravention
            of any applicable laws or any party’s rights or legal entitlements
            under any applicable laws;
            </li>
            <li>
            15.2.3. use any robot, spider, screen scraper, data aggregation tool
            or other automatic device, routine or process (Automated Process) to
            process, monitor, copy or extract any web pages on the Platform, or
            any of the information, content or data contained within or
            accessible through the Platform, without our prior written
            permission;
            </li>
            <li>
            15.2.4. use any Automated Process to aggregate or combine
            information, content or data contained within or accessible through
            the Platform with information, content or data accessible via or
            sourced from any third party;
            </li>
            <li>
            15.2.5. use any Automated Process to interfere or attempt to
            interfere with the proper working of the Platform or any transaction
            or process being conducted on or through the Platform;
            </li>
            <li>
            15.2.6. post, upload to, transmit, distribute, store, create or
            otherwise publish or make available through the Platform any content
            that is false, misleading, erroneous, fraudulent or content which is
            unlawful, libellous, defamatory, offensive to any religious or moral
            groups, obscene, pornographic, indecent, lewd, suggestive,
            harassing, threatening, invasive of privacy or publicity rights,
            abusive, inflammatory, or otherwise objectionable;
            </li>
            <li>
            15.2.7. misuse the Platform by knowingly introducing viruses,
            trojans, worms, logic bombs or other material which may be malicious
            or technologically harmful;
            </li>
            <li>
            15.2.8. take any action that imposes an unreasonable or
            disproportionately large load on the infrastructure of or bandwidth
            connecting to the Platform;
            </li>
            <li>
            15.2.9. attempt to gain unauthorised access to the Platform, the
            server on which the Platform is stored or any server, computer or
            database connected to the Platform;
            </li>
            <li>
            15.2.10. attempt to attack the Platform via a denial-of-service
            attack or a distributed denial-of-service attack;
            </li>
            <li>
            15.2.11. reverse engineer, reverse assemble, decompile, or otherwise
            attempt to discover source code or other arithmetical formula or
            processes in respect of the software underlying the infrastructure
            and processes associated with the Platform; or
            </li>
            <li>
            15.2.12. copy, reproduce, alter, modify, create derivative works
            from, or publicly display, any part of any content from the Platform
            without our prior written permission.
            </li>
          </ul>
          <p>
          15.3. We may, in our absolute discretion, remove, screen or edit
          without notice any information or data posted or stored on the
          Platform at any time and for any reason, and you are solely
          responsible for creating backup copies of and replacing any content
          you require or that you post or store on the Platform at your sole
          cost and expense.
          </p>
          <p>
          15.4. Your use of the Platform in violation of these Terms may result
          in, among other things, termination or suspension of your rights to
          use the Platform and we may, in our sole discretion, report a breach
          of any such obligation which is also a breach of the applicable law to
          the relevant law enforcement authorities and will co-operate with
          those authorities by disclosing your identity to them. In the event of
          such a breach, your right to use the Platform will cease immediately.
          </p>
          <p>
          15.5. Content generated by you, other Vendors or Buyers is not
          approved by us. The Platform may include profiles, information,
          services, products, materials and/or other data or whatsoever nature
          uploaded by other users of Platform, including without limitation
          links to stores, bulletin boards and chat rooms. These items have not
          been verified or approved by us. We are not responsible or liable for
          these items or your use or reliance thereon. The views expressed by
          other users on the Platform do not represent our views or values.
          </p>
        </section>

        <h2>16. How we deal with intellectual property rights</h2>
        <section>
          <p>
          16.1. All remarks, suggestions, ideas, materials or other information
          provided by you through the Platform will forever be our property. You
          acknowledge that you are responsible for any submissions provided
          through your username or password, and you, not us, have full
          responsibility for such submissions, including their accuracy,
          legality, reliability, appropriateness, originality and copyright.
          Notwithstanding the foregoing, we have the right to refuse to remove,
          edit or abridge any data submission for any reason and to disclose any
          submission as we deem necessary, including without limitation to
          achieve compliance with any applicable law, regulation, legal process
          or governmental request, all in our sole discretion.
          </p>
          <p>
          16.2. We own all present and future copyright, registered and
          unregistered trademarks, design rights, unregistered designs, database
          rights and all other present and future intellectual property rights
          and rights in the nature of intellectual property rights existing in
          or in relation to the Platform.
          </p>
          <p>
          16.3. If and to the extent that any such intellectual property rights
          vest in you by operation of law or otherwise, you agree to do any and
          all such acts and execute any and all such documents, now or in the
          future, as we may reasonably request in order to assign such
          intellectual property rights back to us.
          </p>
          <p>
          16.4. You shall retain ownership of all copyright in your Content and
          your name, image, video, voice, photograph, likeness and your other
          personal attributes of whatsoever nature uploaded on to the Platform
          or other sites, platforms or social media or any other media channel
          of whatsoever nature, whether for the purposes of the Platform or such
          other similar or related purposes including, without limitation,
          entertainment, advertising, promotional or monetization purposes
          (collectively Personal IP). By registering an account with us as a
          Vendor, you grant us a world-wide, perpetual, exclusive, royalty-free,
          non-terminable license to use, reproduce, copy, show, share, display
          in public, distribute, deal in and transact; and modify, adapt and
          create derivative works in relation to your Personal IP.
          </p>
          <p>
          16.5. We may transfer, assign or sub-licence the above licence to our
          affiliates, partners or advertisers.
          </p>
          <p>
          16.6. Without limiting the foregoing, we are not required to pay any
          fee or other consideration in respect of the licence granted to us
          above, or any transferring, assignment or sub-licensing thereof,
          subject to any written agreement between us to the contrary.
          </p>
          <p>
          16.7. You represent and warrant that you hold all rights, title and
          interests in your Personal IP necessary (including, without
          limitation, third party rights) in order to grant us the licence
          above.
          </p>
          <p>
          16.8. Unless otherwise expressly excluded in the terms of any
          Transaction, you grant to any Buyer with whom you enter into a
          Transaction, a world-wide, perpetual, exclusive, royalty-free,
          non-terminable license to use, copy, distribute, publish and transmit
          any Content created pursuant to such Transaction.
          </p>
          <p>
          16.9. We do not warrant or represent that the content of the Platform
          does not infringe the rights of any third party.
          </p>
        </section>

        <h2>
        17. How we deal with copyright and trade marks associated with the
        Platform
        </h2>
        <section>
          <p>
          17.1. The Platform and its content are our property or the property of
          our licensors and are protected by copyright, trademark, patent, and
          other applicable laws.
          </p>
          <p>
          17.2. You are permitted to download and print content from the
          Platform solely for your business use to the extent required to use
          the services provided on the Platform. Platform content must not be
          copied or reproduced, modified, redistributed, used or otherwise dealt
          with for any other reason without our express written consent.
          </p>
          <p>
          17.3. Except where necessary for and incidental to personally viewing
          the Platform through your web browser, or as permitted by these Terms,
          no part of the Platform may be reproduced, stored (for any period of
          time) in an electronic or any other retrieval system, modified,
          adapted, uploaded to a third-party location, framed, performed in
          public, or transmitted, in any form by any process whatsoever, without
          our specific prior written consent.
          </p>
        </section>

        <h2>18. You must keep all confidential information protected</h2>
        <section>
          <p>
          18.1. You undertake that you shall not at any time after you have been
          provided a unique username by us, divulge or communicate to any person
          (except to professional representatives or advisers or as may be
          required by law or any legal or regulatory authority) any Confidential
          Information, and will use best endeavours to prevent the unauthorised
          publication or disclosure of any Confidential Information, and will
          only use such Confidential Information for the purposes of proposing,
          considering or making transactions through the Platform. Confidential
          Information for these purposes of this clause shall include all
          information in whatever form (including in visual, oral or electronic
          form) relating to us or your business.
          </p>
          <p>
          18.2. All payment details you provide to us and personally
          identifiable information will not be stored, sold, shared, rented or
          leased to any third parties. If you are asked to make a payment for
          Fees or any other purpose, the details you are asked to submit will be
          provided directly to our payment provided via a secured connection. We
          will not pass any debit/credit card details to third parties.
          </p>
          <p>
          18.3. We take all appropriate steps to ensure data privacy and
          security including through various hardware and software
          methodologies. However, we cannot guarantee the security of any
          information that is disclosed online. You agree that you shall, at
          your own expense, ensure that you comply with and assist us to comply
          with the requirements of all legislation and regulatory requirements
          in force from time to time relating to the use of personal data,
          including (without limitation) any data protection legislation from
          time to time in force, including the UAE Federal Decree-law No. 45 of
          2021 and the Data Protection Law Dubai International Financial Centre
          Law No. 5 of 2020.
          </p>
        </section>

        <h2>
        19. There is no partnership or agency or employment agreement between us
        </h2>
        <section>
          <p>
          19.1. Nothing in these Terms is intended to, or shall be deemed to,
          establish any partnership or joint venture between you and Alfan,
          constitute either party the agent of the other party, or authorise
          either party to make or enter into any commitments for or on behalf of
          the other party.
          </p>
          <p>
          19.2. You hereby confirm that you are self-employed and solely liable
          for all tax due in respect of benefits you receive under these Terms
          or the relevant terms of any Transaction and shall indemnify Alfan and
          keep Alfan indemnified against any proceeding in respect of any
          non-payment by you in respect of any such tax.
          </p>
        </section>

        <h2>20. How we may terminate your access to the Platform</h2>
        <section>
          <p>
          20.1. We may terminate either or both of your log-in details and your
          access to the Platform for any or no reason, at any time, with or
          without notice to you and without incurring any liability to you,
          however that liability may arise.
          </p>
          <p>
          20.2. All restrictions and all disclaimers and exclusions and
          limitations of our liability will continue to apply during suspension
          and will survive any termination. Upon suspension or termination, you
          must not directly or indirectly access or use the Platform.
          </p>
        </section>

        <h2>21. General provisions that apply to these Terms</h2>
        <section>
          <p>
          21.1. We may perform any of our obligations, and exercise any of the
          rights granted to us under these Terms, through a third party. We may
          novate and assign any or all our rights and obligations under these
          Terms to any third party.
          </p>
          <p>
          21.2. If any clause or part of any clause of these Terms is found to
          be void, unenforceable or invalid, then it will be severed from these
          Terms, leaving the remainder in full force and effect, provided that
          the severance has not altered the basic nature of these Terms.
          </p>
          <p>
          21.3. No single or partial exercise, or failure or delay in exercising
          any right, power or remedy by us shall constitute a waiver by us of,
          or impair or preclude any further exercise of, that or any right,
          power or remedy arising under these terms and conditions or otherwise.
          </p>
          <p>
          21.4. If any of the provisions in these Terms are found to be illegal,
          invalid or unenforceable by any court of competent jurisdiction, the
          remainder shall continue in full force and effect.
          </p>
          <p>
          21.5. All disclaimers, indemnities and exclusions in these Terms shall
          survive termination of the Terms, and shall continue to apply during
          any suspension or any period during which the Platform is not
          available for you to use for any reason whatsoever.
          </p>
          <p>
          21.6. These Terms and the documents referred to in them set out the
          entire agreement between you and us with respect to your use of the
          Platform and the services provided via the Platform and supersede any
          and all prior or contemporaneous representations, communications or
          agreements (written or oral) made between you or us.
          </p>
          <p>
          21.7. These Terms together with the documents referred to in them, and
          any non-contractual obligations arising out of or in connection with
          them, shall be governed by the laws of the Dubai International
          Financial Centre (DIFC). You irrevocably submit to the exclusive
          jurisdiction of the DIFC Courts over any claim or matter arising under
          or in connection with these Terms and we submit to the non-exclusive
          jurisdiction of the DIFC Courts in connection with the same.
          </p>
        </section>

        <h2>22. How you may contact us</h2>
        <section>
          <p>
          22.1. Should you have any questions about these Terms, or wish to
          contact us for any reason whatsoever, please contact us at{' '}
            <a href="mailto:support@aflangroup.com">support@aflangroup.com</a>.
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditionsCreator;
