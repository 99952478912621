import React, {useEffect} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {BLUE_COLOR, DARKTHEMEMAINWHITE} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './RadioButton.module.scss';

type RadioProps = {
    text: string;
    selectedOption: string;
    setSelectedOption: Function;
    style?: any;
    id?: string;
}

const RadioButton = ({text, selectedOption, setSelectedOption, style, id}:RadioProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (<p style={style}>
    <input id={id || text} type='radio'
      style={{
        ...(me.theme === THEMES.DARK && !(selectedOption === text) && {
          color: DARKTHEMEMAINWHITE,
        }),
        ...(me.theme === THEMES.LIGHT && !(selectedOption === text) && {
          color: BLUE_COLOR,
        }),
      }}
      value={text} checked={selectedOption === text}
      onChange={(e)=>{
        if (e.target.checked) setSelectedOption(text);
      }} />
    <label htmlFor={id || text} className={styles.label}>{text}</label>
  </p>);
};

export default RadioButton;
