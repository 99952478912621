import React from 'react';
import {ReactComponent as Instagram} from '../assets/images/Instagram-color.svg';
import {ReactComponent as Youtube} from '../assets/images/youtube-color.svg';
import {ReactComponent as Tiktok} from '../assets/images/tiktok-logo.svg';
import {ReactComponent as Twitter} from '../assets/images/TwitterLogoBlue.svg';

export const GREEN_COLOR = '#00DC4D';
export const RED_COLOR = '#ff5a5e';
export const WHITE_COLOR='#ffffff';
export const BLUE_COLOR='#091d60';
export const PURPLE_COLOR = '#918ef7';
export const LIGHT_BLUE_COLOR = '#69BBF8';
export const PINK_COLOR = '#f2acd4';
export const ACTIVEGREEN = '#11ee5f';

export const ACTIVERED = '#ec1e24';
export const GREYTEXTFIELDCOLOR2='#293f62';
export const DARKTHEMEMAINGREY = '#9c9c9c';
export const DARKTHEMEMAINWHITE = '#fafafa';
export const DARKTHEME_BLACK = '#222222';
export const DARKTHEME_BLACK_1 ='#333333';
export const DARKTHEME_BLACK_2='#111111';
export const DARKTHEME_GREY_1 = '#313131';
export const DARKTHEME_GREY_2 = '#3d3d3d';
export const LIGHTGREYCOLOR = '#adb2d0';
export const GREY_COLOR_2 = '#bbbfd2';
export const GREY_COLOR_5 = '#EAF1F8';
export const GREY_COLOR_7 = '#808191';
export const GREY_COLOR_9 = '#8C92B2';
export const GREY_COLOR_10 = '#eaf1f8';
export const GREY_COLOR_11 = '#E6E8F1';
export const GREY_COLOR_13 = '#d3d6de';
export const GREY_COLOR_14 = '#f2f3f5';
export const GREY_COLOR_17 ='#cacccd';
export const BLACK_COLOR_3='rgba(0,0,0,0.3)';

export const USER_ICON_COLORS = ['#00DC4D', '#918ef7', '#5f75ee', '#FF5D5F', '#A5D8FF', '#ec1e24', '#c13584'];
export const IG_GRADIENT = 'igGradient';

export const determinePlatformOptions = (platform)=>{
  switch (platform) {
    case 'youtube':
    case 'YT':
      return {
        icon: <Youtube fill={ACTIVERED} width={'13px'} />,
        color: ACTIVERED,
      };
    case 'instagram':
    case 'IG':
      return {
        icon: <Instagram fill={`url(#${IG_GRADIENT})`} width={'13px'}/>,
        color: `url(#${IG_GRADIENT})`,
      };
    case 'tiktok':
    case 'TT':
      return {
        icon: <Tiktok width={'13px'} height={'13px'}/>,
        color: '#000000',
      };
    case 'twitter':
    case 'TW':
      return {
        icon: <Twitter width={'13px'} />,
        color: LIGHT_BLUE_COLOR,
      };
    case 'SP':
      return {
        icon: <></>,
        color: ACTIVEGREEN,
      };
    case 'male':
      return {
        icon: null,
        color: LIGHT_BLUE_COLOR,
      };
    case 'female':
      return {
        icon: null,
        color: PINK_COLOR,
      };
    default:
      return {
        icon: null,
        color: PINK_COLOR,
      };
  }
}
;
