import React, {useEffect, useState, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import backArrow from '../../assets/images/back-arrow.svg';
import {ReactComponent as BackArrow} from '../../assets/images/back-arrow.svg';
import {ReactComponent as HamburgerIcon} from '../../assets/images/hamburger-icon.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';

import logo from '../../assets/images/logo.svg';
import search from '../../assets/images/search-icon.svg';
import {sidebarRoutes} from '../../constants/routes';
import s from './MobileSidebar.module.scss';
import {store} from '../../app/store';
import {useAppSelector} from '../../app/hooks';
import {selectMe} from '../../features/stores/userSlicer';
import {checkIsActive, determineIcon} from '../Sidebar/Sidebar';
import useWindowWidth from '../../hooks/useWindowWidth';
import NotificationButton from '../NotificationButton/NotificationButton';
import WhiteRoundButton from '../WhiteRoundButton/WhiteRoundButton';
import {THEMES} from '../../constants/types';
import {BLUE_COLOR, DARKTHEMEMAINGREY, GREYTEXTFIELDCOLOR2, WHITE_COLOR} from '../../constants/colors';
import NotificationBar from '../NotificationBar/NotificationBar';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';


const MobileSidebar = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState('');
  const isMonetized = selectMe(useAppSelector(store.getState)).isChannelMonetized;
  const mobile = useWindowWidth() <= 890;
  const smallMobile = useWindowWidth() <= 375;
  const me = selectMe(useAppSelector(store.getState)).me;

  const handleClick = (e:any, route:any)=>{
    if (!isMonetized && route.route === '/earnings') {
      e.preventDefault();
    } else setOpen(!open);
  };
  const [openNotifications, setOpenNotifications] = useState(false);
  const node = useRef(null);
  useOutsideAlerter(node, setOpenNotifications);

  return (
    window.location.pathname !== '/onboarding' ?
    <div>
      <div className={s.mobile_nav} style={{
        ...(smallMobile && {paddingBottom: '16px'}),
        ...(mobile && window.location.pathname === '/settings' && {width: 'calc(50% + 60px)'}),
      }}>
        {open ? (
          <div className={s.back_container} onClick={() => setOpen(!open)}>
            <BackArrow fill={me.theme === THEMES.LIGHT ? BLUE_COLOR : DARKTHEMEMAINGREY} />
            <span style={{marginLeft: '5px'}}>Back</span>
          </div>
        ) : (
          <div className={s.hamburger_container} onClick={() => setOpen(!open)}>
            <HamburgerIcon fill={me.theme === THEMES.LIGHT ? GREYTEXTFIELDCOLOR2 : DARKTHEMEMAINGREY} />
          </div>
        )}
        {window.location.pathname !== '/settings' ? <div className={s.avatar_container}>
          <WhiteRoundButton style={{marginRight: '20px', width: '42px', height: '42px', ...(me.theme === THEMES.DARK && {boxShadow: 'none'})}}>
            <img src={search} alt="search" style={{width: '17px', height: '17px'}} />
          </WhiteRoundButton>
          <div ref={node}>
            <NotificationButton style={{width: '42px', height: '42px', position: 'relative'}} onClick={()=>setOpenNotifications(!openNotifications)} />
            {openNotifications &&<NotificationBar />}
          </div>
        </div> : <div className={s.header}>Account Settings</div> }


      </div>

      <div className={s.divider}></div>
      {open && (
        <div className={`${s.sidebar_container} card`}>
          <Logo fill={me.theme === THEMES.LIGHT ? BLUE_COLOR : WHITE_COLOR} className={s.logo} />
          {sidebarRoutes.map((route) => {
            return (
              <>
                {route.label && (
                  <p className={s.category_label}>{route.label}</p>
                )}
                <NavLink
                  to={route.route}
                  key={route.key}
                  className={!isMonetized && route.route ==='/earnings' ? s.disabled : s.sidebar_item}
                  activeClassName={!isMonetized && route.route === '/earnings' ? s.disabled : s.sidebar_active_item}
                  onClick={(e) => handleClick(e, route)}
                  isActive={(match, location) => checkIsActive(match, route, setActive, location)}
                >
                  {determineIcon(route, active, isMonetized, me.theme)}
                  <p>{route.name}</p>
                </NavLink>
              </>
            );
          })}


        </div>
      )}
    </div> : <></>
  );
};

export default MobileSidebar;
