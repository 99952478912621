import React from 'react';
import Loader from 'react-loader-spinner';

const PageSpinner = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '95vw', height: '95vh', boxSizing: 'border-box'}}>
      <Loader type="TailSpin" color="#EC1E24" height={80} width={80} />
    </div>
  );
};

export default PageSpinner;
