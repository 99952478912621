import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {stat} from 'fs';
import {RootState} from '../../app/store';
import {THEMES} from '../../constants/types';
import {refreshMe} from '../Login/login';

export interface UserState {
  me: any;
  loginStatus: 'LOGGED_OUT' | 'LOGGED_IN' | 'ERROR' | 'FETCHING' | 'REFRESHING' | 'EMAIL_UNCONFIRMED',
  linkAccountModalOpen: boolean,
  isChannelMonetized: boolean,
  hasDemographics: boolean,
  hasTopCountries: boolean,
  navigateToAfterLinking: string,
  isThemeChanged: boolean,
  isCounterClicked: boolean,
  navigatedFromSettings: boolean,
}

const initialState: UserState = {
  me: null,
  loginStatus: 'LOGGED_OUT',
  linkAccountModalOpen: false,
  isChannelMonetized: true, // was false
  hasDemographics: false,
  hasTopCountries: false,
  navigateToAfterLinking: '/',
  isThemeChanged: false,
  isCounterClicked: false,
  navigatedFromSettings: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeMe: (state) => {
      state.me = null;
      state.loginStatus = 'LOGGED_OUT';
      state.linkAccountModalOpen = false;
      state.isChannelMonetized = false;
    },
    setMe: (state, action: PayloadAction<any>) => {
      state.me = action.payload.me;
      if (action.payload.loginStatus) state.loginStatus = action.payload.loginStatus;
      if (action.payload.linkAccountModalOpen) state.linkAccountModalOpen = action.payload.linkAccountModalOpen;
    },
    updateChannelMonetization: (state, action: PayloadAction<any>)=>{
      state.isChannelMonetized = action.payload.isChannelMonetized;
    },
    updateDemographics: (state, action:PayloadAction<any>)=>{
      state.hasDemographics = action.payload.hasDemographics;
    },
    updateTopCountries: (state, action:PayloadAction<any>)=>{
      state.hasTopCountries = action.payload.hasTopCountries;
    },
    updateLinkAccountModal: (state, action:PayloadAction<any>)=>{
      state.linkAccountModalOpen = action.payload.linkAccountModalOpen;
    },
    navigateTo: (state, action: PayloadAction<any>)=>{
      state.navigateToAfterLinking = action.payload.navigateToAfterLinking;
    },
    setIsThemeChanged: (state, action: PayloadAction<any>)=>{
      state.isThemeChanged = action.payload.theme;
    },
    setIsCounterClicked: (state, action: PayloadAction<any>)=>{
      state.isCounterClicked = action.payload.counter;
    },
    setNavigatedFromSettings: (state, action: PayloadAction<any>)=>{
      state.navigatedFromSettings = action.payload.navigatedFromSettings;
    },
  },
});

export const {removeMe, setMe, setIsCounterClicked, navigateTo, setIsThemeChanged, updateChannelMonetization,
  updateDemographics, updateTopCountries, updateLinkAccountModal, setNavigatedFromSettings} = userSlice.actions;

export const selectMe = (state: RootState) => state.user;

export default userSlice.reducer;
