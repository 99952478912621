import React, {useEffect, useState, useRef} from 'react';
import {Auth} from 'aws-amplify';
import {toast} from 'react-toastify';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {baseUrl} from '../../features/EnvironmentVariables/enviromentVariables';
import useRequest from '../../features/API/request';
import styles from './PublicLogin.module.scss';
import loginImg from '../../assets/images/LoginImages2x.png';
import logo from '../../assets/images/logo.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import Wave from '../../assets/images/wavingHand.svg';
import google from '../../assets/images/google.svg';
import send from '../../assets/images/Sendsend.svg';
import TextField from '../../components/TextField/TextField';
import CheckBox from '../../components/CheckBox/CheckBox';
import facebook from '../../assets/images/facebookF.svg';
import Button from '../../components/Button/Button';
import {fetchMe, ILogin, logIn, loginCognito} from '../../features/Login/login';
import PopUp from '../../components/PopUp/PopUp';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as bcrypt from 'bcryptjs';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import useWindowWidth from '../../hooks/useWindowWidth';
import {ACTIVERED, BLUE_COLOR, USER_ICON_COLORS} from '../../constants/colors';
import ComingSoon from '../../components/ComingSoon/ComingSoon';
import PublicFooter from '../../components/PublicFooter/PublicFooter';

const PublicLogin = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const request = useRequest({method: 'POST', url: '/auth/login'});
  const register = useRequest({method: 'POST', url: '/auth/register'});
  const [validationMessage, setValidationMessage] = useState<string>('');
  const isMobile = useWindowWidth() <= 767;
  const socialButtonStyle = {height: '48px', ...(isMobile ? {width: '100%'} : {minWidth: '219px'}),
    backgroundColor: 'transparent', border: 'solid 1px #d9d9d9', color: '#091d60', fontSize: '14px'};

  const doLogin = async () => {
    // const result = await request.doRequest({...credentials}) as ILogin;
    // if (result) {
    //   // logIn(result, {to: '/overview', emailCallback: ()=>setEmailUnconfirmed(true)});
    //   logIn(result, {});
    // }
    const result = await loginCognito(credentials);
    if (result.error) {
      const splitData = result.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (result.error) {
        case 'UserNotConfirmedException':
          toast.error('Please verify your email by login through alfan link or contact support.');
          break;
        case 'NotAuthorizedException':
          toast.error(<span>{msg || result.message || 'Something went wrong!'}</span>);
          break;
        case 'UserNotFoundException':
          toast.error(<span>{msg || 'User not found.'}</span>);
          break;
        default:
          console.log('no error matched');  
      }
    } else {
      const tokens: any = await Auth.currentSession();
      if (tokens && tokens.idToken) {
        const me = await logIn({Bearer: tokens.idToken.jwtToken}, {});
      }
    }
  };

  useEffect(() => {
    setValidationMessage(request.error?.toString() || '');
  }, [request.hasError, register.hasError]);

  useEffect(() => {
    setValidationMessage('');
  }, [credentials]);

  const handleKeyPress = (e: any) => {
    if (e.charCode === 13) {
      doLogin();
    }
  };

  return (
    <section className={styles.loginSection}>
      <Helmet>
        <title>Login</title>

      </Helmet>
      <div className={styles.loginLeft}>
        <div className={styles.loginLeftInner}>
          <div className={styles.loginImg}>
            <img src={loginImg} alt="" />
          </div>
          <div className={styles.loginContent}>
            <h4>Track everything you need <span>in your creator business </span>all in one place.</h4>
            <p>{'Manage your financials, track your audience & '}<span>discover opportunities</span> </p>
          </div>
        </div>
      </div>

      <div className={styles.loginRight} style={{position: 'relative'}}>
        <div className={styles.loginRightInner} style={{
        }}>
          <div className={styles.logoSection}>

            <Logo fill={BLUE_COLOR} className={styles.logo} />
          </div>
          <div className={styles.welcome}>
      Welcome Back
            <img src={Wave} />
          </div>
          <p className={styles.welcomeSubtext}>{'Track and manage your creator business'}</p>

          <form>

            <LoginForm handleKeyPress={handleKeyPress} validationMessage={validationMessage} setCredentials={setCredentials} credentials={credentials} chosenOption={'login'} />
            <div className={styles.rememberContainer}>

            </div>

            <div className={styles.buttonContainer}>
              <Button style={{...(isMobile && {height: '56px'})}} onClick={doLogin}
              ><span>Login</span></Button>
            </div>
          </form>

          <i className={styles.or}>Or</i>
          <div className={styles.socialsContainer}>

            <Button disabled onClick={()=>console.log()} style={socialButtonStyle} >
              <div className={styles.googleBtn}>
                <img className={styles.googleLogo} src={google} alt=""/>
                <span>Continue with Google</span>
                <ComingSoon />
              </div>
            </Button>
            <Button disabled onClick={()=>console.log()} style={{...(isMobile ? {marginTop: '20px'}: {marginLeft: '17px'}), ...socialButtonStyle}} >
              <div className={styles.googleBtn}>
                <img className={styles.googleLogo} src={facebook} alt=""/>
                <span>Continue with Facebook</span>
                <ComingSoon />
              </div>
            </Button>
          </div>
        </div>


      </div>
      <PublicFooter />  
    </section>
  );
};


const LoginForm = ({credentials, setCredentials, validationMessage, chosenOption, isMobile, registerMobileTextfieldLabelStyle, handleKeyPress}:any)=>{
  return (
    <>
      <div className={styles.formSection}>
        <div className={styles.formLabels}>Email Address</div>
        <TextField value={credentials.email} type='text' object={credentials} setObject={setCredentials} name='email' placeholder={'Registered email'}/>
      </div>

      <div className={styles.formSection}>
        <div className={styles.formLabels}>Password</div>
        <TextField onKeyPress={handleKeyPress} value={credentials.password} type='password' object={credentials} setObject={setCredentials} name='password' placeholder={'Password' }/>
      </div>
      {validationMessage && <div style={{color: ACTIVERED}} >{validationMessage}</div>}
    </>
  );
};

export default PublicLogin;
