import React from 'react';
import './../PrivacyPolicy/PrivacyPolicy.scss';

const StandardOfUse = () => {
  return (
    <div className='main__wrapper'>
        <div className='header'>
            <div className='header__content'>
              <h2>Alfan.Link</h2>
              <p>Standards of use</p>
            </div>
        </div>
        <div className='content'>
            <div className='content__wrapper'>
            <div className='privacy__policy'><h1>STANDARDS OF USE</h1></div>
                <br />
                <div className='privacy__description'><h5>UPDATED: 20 SEPTEMBER 2022</h5></div>
                <br />
                <div className='privacy__description'><h5>PLEASE READ THESE STANDARDS OF USE CAREFULLY BEFORE USING THIS SITE</h5></div>
                <br />

                <div className='privacy__description'><h3>What are these Standards of Use?</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  All defined terms under the &quot;Alfan.Link Terms and Conditions&quot; have the same meaning hereunder.
                  </div>  
                  <div className='privacy__para textColor'>
                  These Standards of Use:
                  </div>  
                  <div className='desc__points'>
                      <ul>
                          <li>are our Standards of Use as set out under &quot;There are other terms that apply to you&quot; in the &quot;Alfan.Link Terms and Conditions&quot;;</li>
                          <li>also apply to your use of our site and you agree with them and agree to comply with them; and</li>
                          <li>are part of the Terms.</li>
                      </ul>
                  </div>  
                  <div className='privacy__para textColor'>
                  These Standards of Use set out the content standards that apply when you upload content to our site, make contact with other users on our site, link to our site or Offer or Transact on or in relation to our site, or otherwise interact with our site in any other way.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>By using our site you accept these terms</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  By using our site, you confirm that you accept these Standards of Use and that you agree to comply with them.
                  </div>  
                  <div className='privacy__para textColor'>
                  If you do not agree to these Standards of Use, you must not use our site.
                  </div>  
                  <div className='privacy__para textColor'>
                  We recommend that each time you use our site, you check these Standards of Use for updates.
                  </div>  
                </div>
                <br />
                <div className='privacy__description'><h3>Prohibited uses</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  Without limiting any other Terms, you may not use our site:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                          <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                          <li>To Offer or Transact, or otherwise refer to any Good or Service, in respect of which any Contribution (as defined below) would be a breach of these Standards of Use.</li>
                          <li>Without limiting the foregoing, to Offer or Transact any counterfeits, embargoed goods, endangered or protected species or parts thereof, fireworks, explosives, hazardous / toxic materials, ID documents, personal information, mailing lists, recalled items, stolen items, adult products, content or services or products or services offered through multi-level marketing schemes (including, without limitation, &quot;pyramid schemes&quot;).</li>
                          <li>To promote, facilitate or engage in gambling.</li>
                          <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
                          <li>For the purpose of harming or attempting to harm third parties in any way.</li>
                          <li>To bully, insult, intimidate or humiliate any person.</li>
                          <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the content standards set out herein.</li>
                          <li>To deceive or spread rumours or misinformation.</li>
                          <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                          <li>To secure a username or other name, url or symbol without the intention of using it, or to prevent someone else from using it or to profit from reselling it (including, without limitation, to &quot;domain squat&quot;).</li>
                          <li>To upload terrorist or extremist content.</li>
                      </ul>
                  </div>
                </div>
                <br />
                <div className='privacy__description'><h3>Interactive services</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  We may from time to time provide interactive services on our site, including, without limitation:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                          <li>Systems to rate, and provide feedback on, other users of our site and / or Goods and Services on our site.</li>
                          <li>Comment and chat facilities.</li>
                          <li>Video and other content-sharing facilities.</li>
                          <li>Bulletin boards.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  We may, but are not obliged to, restrict, moderate or take any other steps to mitigate any possible risks for users from third parties.  Without limiting the foregoing, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the interactive service is moderated or not. 
                  </div> 
                  <div className='privacy__para textColor'>
                  The use of any of our interactive services by a person under the age of 18 is not permitted, unless such use is undertaken with the consent and supervision of their parent or guardian. If you are a parent or guardian you agree to undertake such supervision. 
                  </div> 
                  <div className='privacy__para textColor'>
                  You acknowledge and agree that the interactive services may not be moderated, other users are not screened or monitored and there are potential risks to interactions with other users on our site.
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Content standards</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  These content standards apply to any and all information, content or other material of whatsoever nature which you contribute to our site including, without limitation, text, video, audio, data, information or other material of whatsoever nature, related to profiles, comments, passwords and other security processes, Offers, Transactions and Goods and Services (Contribution), and to any interactive services associated with it. 
                  </div> 
                  <div className='privacy__para textColor'>
                  These content standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.
                  </div> 
                  <div className='privacy__para textColor'>
                  We will determine, in our absolute discretion, whether a Contribution breaches the Content Standards.
                  </div>
                  <div className='privacy__para textColor'>
                  A Contribution must:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                          <li>Be accurate (where it states facts).</li>
                          <li>Be genuinely held (where it states opinions).</li>
                          <li>Comply with the law applicable in the UAE and in any country from which it is posted.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  A Contribution must not:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                          <li>Promote, be associated with or related to, any matter, thing or circumstance that would breach the &quot;Prohibited uses&quot; set out above or these Terms generally.</li>
                          <li>Be slanderous or defamatory.</li>
                          <li>Be obscene, offensive, hateful or inflammatory.</li>
                          <li>Bully, insult, intimidate or humiliate.</li>
                          <li>Prejudice respect for human dignity.</li>
                          <li>Promote or include reference to human trafficking, slavery or prostitution.</li>
                          <li>Promote or include reference to gambling.</li>
                          <li>Include any pornography, sexually explicit material or other bodily display or activity which is not permitted in public in the UAE.</li>
                          <li>Include any reference to, or promote, the use or consumption of any alcohol, drug or other illegal, prescription-only or restricted substance.</li>
                          <li>Include any reference to, or promote, the use of smoking, shisha, e-cigarette, vaping or other tobacco related product. </li>
                          <li>Promote or include a reference to the incorrect, illegal or unsafe use of any material, substance, equipment or plant including, without limitation, for the purpose of producing a stupefying or other psycho-active effect.</li>
                          <li>Promote or include a reference to treason, insurrection, riot, protest, strike or industrial action.</li>
                          <li>Promote or include a reference to weapons or military hardware, equipment or activities including without limitation, plans, schematics or instructions for the same.</li>
                          <li>Promotes or idealises suicide, self-harm, eating disorders, or potentially harmful alternatives to medical treatment.</li>
                          <li>Encourage behaviour prejudicial to health or safety or otherwise unreasonably show persons in dangerous situations.</li>
                          <li>Promote or include a reference to adoption or surrogacy.</li>
                          <li>Impair the physical, mental or moral development of persons under the age of 18.</li>
                          <li>Include child sexual abuse material.</li>
                          <li>Promote or include violence, abuse, gore, horror or any material intended to shock, disturb, sicken or frighten.</li>
                          <li>Promote or include any political policy, stance, ideology or view-point.</li>
                          <li>Be sacrilegious or otherwise promote or include any matter, activity or thing which is immoral, unethical or against religious doctrine (regardless of faith, sect or denomination).</li>
                          <li>Promote or include hate speech, xenophobia or discrimination including, without limitation, discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                          <li>Infringe any copyright, database right or trade mark of any other person.</li>
                          <li>Be likely to deceive any person.</li>
                          <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                          <li>Promote any illegal content or activity.</li>
                          <li>Be in contempt of court.</li>
                          <li>Include any personal information including, without limitation, full names, ID documents and any personal addresses, phone numbers, email addresses or social media profiles, or which would prejudice any person&apos;s privacy.</li>
                          <li>Be threatening, abuse or invade another&apos;s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                          <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                          <li>Impersonate any person or misrepresent your identity or affiliation with any person.</li>
                          <li>Give the impression that the Contribution emanates from us, if this is not the case.</li>
                          <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.</li>
                          <li>Promote terrorism or extremism, or otherwise contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.</li>
                          <li>Promote any services or web links to other sites (except as expressly consented to by us in writing).</li>
                          <li>Offend, slander, defame, insult, criticise or otherwise diminish the standing of any past or present Ruler, Royal Family Member, Minister or other Government Official within the UAE and other GCC countries, or any GGC country or UAE Emirate (including, without limitation, their flags, notable persons or national symbols).</li>
                          <li>Otherwise offend or be inconsistent with the traditions, morals and cultural norms of the UAE and other GCC countries.</li>
                      </ul>
                  </div> 
                </div>
                <br />
                <div className='privacy__description'><h3>Breach of these terms</h3></div>
                <div className='privacy__description__point textColor'>
                  <div className='privacy__para textColor'>
                  When we consider that a breach of these Standards of Use has occurred, we may take such action as we deem appropriate.  
                  </div>
                  <div className='privacy__para textColor'>
                  Failure to comply with these Standards of Use constitutes a material breach of these Terms and may result in our taking all or any of the following actions:
                  </div> 
                  <div className='desc__points'>
                      <ul>
                          <li>Immediate, temporary or permanent withdrawal of your right to use our site.</li>
                          <li>Immediate, temporary or permanent removal of any Contribution uploaded by you to our site.</li>
                          <li>Issue of a warning to you.</li>
                          <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                          <li>Further legal action against you.</li>
                          <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</li>
                          <li>Any other action which is set out in these Terms.</li>
                      </ul>
                  </div>
                  <div className='privacy__para textColor'>
                  We exclude our liability for all action we may take in response to breaches of these Standards of Use. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.
                  </div> 
                </div>
                <br />
            </div>         
        </div>
    </div>
  );
};

export default StandardOfUse;
