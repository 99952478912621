import React from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './WhiteCard.module.scss';

const WhiteCard = ({children, style}:any)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (<div className={styles.container} style={{...(me.theme === THEMES.DARK && {
    boxShadow: 'none',
    backgroundColor: '#222222',
  }), ...style}}>

    {children}
  </div>);
};

export default WhiteCard;
