import React from 'react';
import ImageCrop from '../ImageCrop/ImageCrop';
import styles from '../ModalCrop/ModalCrop.module.scss';

interface IProps{
    setIsOpen: (arg:boolean) => void,
    imgSrc: any,
    setImageUrl?: (arg:any) => void,
    type: string
}

const ModalCrop = ({setIsOpen, imgSrc, type, setImageUrl}: IProps) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <ImageCrop imgSrc={imgSrc} setIsOpen={setIsOpen} type={type} setImageUrl={setImageUrl}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCrop;
