import React from 'react';
import styles from './Help.module.scss';

const Help = ()=>{
  return (<div style={{fontStyle: 'italic', padding: '20px', fontSize: '20px'}}>
    For support contact us at info@alfangroup.com.
  </div>);
};

export default Help;
