import React, {useState} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {DARKTHEMEMAINGREY, GREY_COLOR_13, GREY_COLOR_9} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './OutlineButton.module.scss';

type OutlineButtonProps = {
    onClick?: ()=>void;
    text?: string;
    icon?: string;
    style?: object;
    disable?: boolean;
    className?: string;
    textStyle?: any;
    children?: JSX.Element | JSX.Element[];
}

const OutlineButton = ({onClick, text, icon, style, disable, className, children, textStyle}:OutlineButtonProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (<div className={`${styles.wrapper}`}>
    {disable ?
    <div className={styles.disabled} style={{...style}}>
      <span style={textStyle}>{text}</span>
      {icon ? <img className={styles.icon} src={icon} /> : <></>}
    </div>:
   <div className={`${styles.container} ${className}`} onClick={onClick}
     style={{
       ...(me.theme === THEMES.LIGHT ?
       {borderColor: GREY_COLOR_13, color: GREY_COLOR_9}:
       {border: '1px solid'+DARKTHEMEMAINGREY, color: DARKTHEMEMAINGREY}), ...style}}>
     <span style={textStyle}>{text}</span>
     {icon ? <img className={styles.icon} src={icon} /> : <></>}
     {children}
   </div>}

  </div>);
};

export default OutlineButton;
