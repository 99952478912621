import React from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {BLUE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './OptionsButton.module.scss';

type OptionsButtonProps = {
    onClick: ()=>void;
    style?:object;
}

const OptionsButton = ({onClick, style}:OptionsButtonProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (<div onClick={onClick} className={styles.container}>
    <div className={styles.circle} style={{...style}} ></div>
    <div className={styles.circle} style={{...(me.theme === THEMES.LIGHT && {borderColor: BLUE_COLOR}), ...style}} ></div>
  </div>);
};

export default OptionsButton;
