import React, {useState, useRef, useEffect} from 'react';
import s from './Sidebar.module.scss';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as ArrowList} from '../../assets/images/arrow-list.svg';
import {sidebarRoutes} from '../../constants/routes';
import {NavLink, Route} from 'react-router-dom';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {ROLES} from '../../constants/roles';
import admin from '../../assets/images/profile-icon.svg';
import {logOut} from '../../features/Login/login';
import DefaultIcon from '../DefaultIcon/DefaultIcon';
import {THEMES} from '../../constants/types';
import {BLACK_COLOR_3, BLUE_COLOR, DARKTHEMEMAINGREY, DARKTHEMEMAINWHITE, DARKTHEME_BLACK, DARKTHEME_GREY_1, GREY_COLOR_14, GREY_COLOR_5, GREY_COLOR_7, GREY_COLOR_9, WHITE_COLOR} from '../../constants/colors';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';


export const determineIcon = (route:any, active: string, isMonetized: boolean, theme: string)=>{
  if (route.route === '/earnings' && !isMonetized) {
    if (theme === THEMES.DARK) return route.darkIcon;
    return route.icon;
  }

  if (active === route.route || route.route === '/overview' && location.pathname === '/') {
    return route.activeIcon;
  } else {
    if (theme === THEMES.DARK) return route.darkIcon;
    return route.icon;
  }
};

export const checkIsActive = (match:any, route: any, setActive: Function, location: any)=>{
  if (location.pathname === '/' && route.route === '/earnings') {
    setActive('/earnings');
    return true;
  }
  if (location.pathname.includes('/upload') && route.route === '/audio-music') {
    setActive('/audio-music');
    return true;
  }
  if (!match) {
    setActive(location.pathname);
    return false;
  }
  if (match.url === route.route) {
    setActive(location.pathname);
    return true;
  }
  setActive('');
  return true;
};

const Sidebar = () => {
  const [active, setActive] = useState('');
  const isMonetized = selectMe(useAppSelector(store.getState)).isChannelMonetized;
  const user = selectMe(useAppSelector(store.getState));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const node = useRef(null);
  useOutsideAlerter(node, setIsMenuOpen);

  const handleClick = (e:any, route:any)=>{
    if (!isMonetized && route.route === '/earnings') e.preventDefault();
  };

  return (
    window.location.pathname !== '/onboarding' ?

      <div className={s.sidebar_container} style={{borderRightColor: user.me.theme === THEMES.LIGHT ? GREY_COLOR_14 : DARKTHEMEMAINGREY}}>
        <Logo fill={user.me.theme === THEMES.LIGHT ? BLUE_COLOR : WHITE_COLOR } className={s.logo} />
        {sidebarRoutes.map((route) => {
          return window.location.host == "app.al.fan" && route.key == 'music' ? null : (
            <div
              key={route.key}>
              {route.label &&
              <p className={s.category_label}>{route.label}</p>
              }

              <NavLink
                to={route.route}
                onClick={(e)=>handleClick(e, route)}
                className={!isMonetized && route.route ==='/earnings' ? s.disabled : s.sidebar_item}
                style={{...(user.me.theme === THEMES.LIGHT && !isMonetized && route.route ==='/earnings' && {
                  backgroundColor: GREY_COLOR_5,
                  color: BLACK_COLOR_3,
                }),
                ...(user.me.theme === THEMES.DARK && !isMonetized && route.route ==='/earnings' &&{
                  backgroundColor: DARKTHEME_BLACK,
                  color: DARKTHEME_GREY_1,
                }),
                }}
                activeClassName={!isMonetized && route.route === '/earnings' ? s.disabled : s.sidebar_active_item}
                isActive={(match, location)=>checkIsActive(match, route, setActive, location)}
              >
                <div className={s.sidebar_item_icon}>
                  {determineIcon(route, active, isMonetized, user.me.theme)}
                </div>

                <p>{route.name}</p>
              </NavLink>
            </div>
          );
        })}

        <div className={s.loggedUser} ref={node}>
          <div style={{display: 'flex'}}>
            <div className={s.iconContainer}>
              {user.me.avatar ? <img src={user.me.avatar} style={{width: '42px', height: '42px', borderRadius: '50%'}} /> : <DefaultIcon name={user.me.firstName} surname={user.me.lastName} color={user.me.defaultAvatar} style={{width: '42px', height: '42px', fontSize: '18px'}} />}
              {user.me.role === ROLES.ADMIN && <img src={admin} className={s.admin} />}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '140px'}}>
              <div className={s.name}>{user.me.firstName} {user.me.lastName}</div>
              <div className={s.role} style={{color: user.me.theme === THEMES.LIGHT ? GREY_COLOR_9 : DARKTHEMEMAINGREY}}>{user.me.role}</div>
            </div>
          </div>
          <div className={s.arrowBtn} onClick={()=>setIsMenuOpen(!isMenuOpen)}>

            <ArrowList stroke={user.me.theme === THEMES.LIGHT ? BLUE_COLOR : DARKTHEMEMAINWHITE}/>
            {isMenuOpen && <div className={s.menu} onClick={()=>{
              logOut();
              window.location.href='/';
            }}>Log Out</div>}
          </div>

        </div>
      </div> : <></>
  );
};

export default Sidebar;
