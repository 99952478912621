import React from 'react';
import style from './music.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import ShadowCard from '../../components/ShadowCard/ShadowCard';
import youtube from '../../assets/images/youtube.svg';
import cloudGroup from '../../assets/images/cloudGroup.svg';
import dots from '../../assets/images/Dots.svg';
import musicStyle from './musicTable.module.scss';
import {toK} from '../../components/AreaChart/CustomAreaChart';
import dollarCircle from '../../assets/images/dollarCircle.svg';
import playCircle from '../../assets/images/playCircle.svg';
import timeCircle from '../../assets/images/timeCircle.svg';
import {NavLink, Route} from 'react-router-dom';

const MUSIC_MOCK_DATA_PROGRESS = [
  {
    title: 'Speaking with myself #1',
    date: 'July 15, 2021',
    length: 243,
    plays: null,
    earnings: 'PROGRESS' as MusicStatus,
  },
  {
    title: 'Speaking with myself #2',
    date: 'July 10, 2021',
    length: 185,
    plays: null,
    earnings: 'ERROR' as MusicStatus,
  },
];

const MUSIC_MOCK_DATA_FINISHED = [
  {
    title: 'Speaking with myself #3',
    date: 'April 15, 2021',
    length: 2640,
    plays: 192759,
    earnings: 100,
  },
  {
    title: 'Speaking with myself #4',
    date: 'Feb 02, 2021',
    length: 1935,
    plays: 250000,
    earnings: 250,
  },
];

export type MusicStatus = 'PROGRESS' | 'ERROR'

interface IMusic{
  title: string;
  date: Date | string;
  length: number;
  plays: number | null;
  earnings: number | 'PROGRESS' | 'ERROR'
}

interface IProps{
  music: IMusic[];
  title: string;
  headers?: string[];
  desktop?: boolean;
}

const MusicTable = (props: IProps) => {
  const {music, title, headers, desktop} = props;
  const convertSeconds = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = Number(date.toISOString().substr(11, 2)) > 0? date.toISOString().substr(11, 8) : date.toISOString().substr(14, 5);
    return timeString;
  };

  return (
    <div>
      <div className={musicStyle.header}>
        <p>{title}</p>
      </div>
      <div className={musicStyle.container}>
        {music.map((el: IMusic, key: number) => {
          return (
            desktop?
            <>
              {headers && key === 0 && headers?.length > 0 &&
            <div className={`${musicStyle.table} ${musicStyle.tableHeader}`}>
              {headers.map((e: string, key2: number) => {
                return <span key={key2}>{e}</span>;
              })}
            </div>
              }
              <div key={key} className={musicStyle.table}>
                <div className={musicStyle.imageSection}>
                  <img src={youtube}></img>
                  <div className={musicStyle.imageSection__data}>
                    <span>{el.title}</span>
                    <span>{el.date}</span>
                  </div>
                </div>
                <span className={musicStyle.table__span}>{convertSeconds(el.length)}</span>
                <span className={musicStyle.table__span}>{el.plays? toK(el.plays) : '-'}</span>
                <span className={musicStyle.table__span}>{typeof(el.earnings) === 'number' && '$'}{el.earnings}</span>
              </div>
            </> :
            <>
              <div key={key} className={musicStyle.tableMobile}>
                <img src={youtube}></img>
                <div className={musicStyle.containerMobile}>
                  <div className={musicStyle.imageSection__data}>
                    <span>{el.title}</span>
                    <span>{el.date}</span>
                  </div>
                  <div className={musicStyle.iconSectionMobile}>
                    <div className={musicStyle.iconSectionMobile__container}>
                      <img src={timeCircle} className={musicStyle.iconSectionMobile__icon}/>
                      <span className={musicStyle.spanMobile}>{convertSeconds(el.length)}</span>
                    </div>
                    <div className={musicStyle.iconSectionMobile__container}>
                      <img src={playCircle} className={musicStyle.iconSectionMobile__icon}/>
                      <span className={musicStyle.spanMobile}>{el.plays? toK(el.plays) : '-'}</span>
                    </div>
                    <div className={musicStyle.iconSectionMobile__container}>
                      <img src={dollarCircle} className={musicStyle.iconSectionMobile__icon}/>
                      <span className={musicStyle.spanMobile}>{typeof(el.earnings) === 'number' && '$'}{el.earnings}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

// const Music = () => {
//   const size = useWindowWidth(); // <= 365;
//   const mobile = useWindowWidth() <= 890;

//   return (
//     <div className={style.main} style={{...(mobile && {paddingTop: 0})}}>
//       <ShadowCard className={style.uploadSections} key={`first-section-${size > 890}`}>
//         <div>
//           <div className={`${style.uploadSections__title} musicTitle`}>
//             Upload a new song
//           </div>
//           <div className={`${style.uploadSections__content} musicTitle`}>
//           Everyone realizes why a new common language would be desirable one could refuse to pay expensive translators.
//           </div>
//           <NavLink to={'/audio-music/upload/track'} className={style.uploadSections__button}>
//             Upload song
//           </NavLink>
//         </div>
//         <div className={style.uploadSections__images}>
//           {size > 890 &&
//         <>
//           <img className={style.uploadSections__images__cloud} src={cloudGroup}></img>
//         </>}
//           <img className={style.uploadSections__images__dots} src={dots}></img>
//         </div>
//       </ShadowCard>
//       <ShadowCard>
//         <MusicTable music={MUSIC_MOCK_DATA_PROGRESS}
//           title={'In progress'}
//           headers={(size > 890? ['Name', 'Length', 'Plays', 'Earnings'] : [])}
//           desktop={size > 890}></MusicTable>
//       </ShadowCard>
//       <ShadowCard>
//         <MusicTable music={MUSIC_MOCK_DATA_FINISHED}
//           title={'My Tracks'}
//           headers={(size > 890? ['Name', 'Length', 'Plays', 'Earnings'] : [])}
//           desktop={size > 890}></MusicTable>
//       </ShadowCard>
//     </div>
//   );
// };


const Music = ()=>{
  return (<div style={{fontStyle: 'italic', padding: '20px', fontSize: '20px'}}>
    For support contact us at info@alfangroup.com.
  </div>);
};

export default Music;
