import React, {useEffect} from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {DARKTHEME_GREY_1, WHITE_COLOR} from '../../constants/colors';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './CheckBox.module.scss';
interface CheckBoxProps {
    object: any;
    setObject: Function;
    name: string;
    round?: boolean;
    postChange?: Function;
    style?: any;
    labelStyle?: any;
    customChecked?: boolean;
    customOnChange?: (e:any)=>void;
}
const CheckBox = ({object, setObject, name, round, postChange, style, labelStyle, customOnChange, customChecked}:CheckBoxProps)=>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (
    <div className={styles.container}>
      <div className={styles.round} style={{
        ...(me ? me.theme === THEMES.DARK ? {backgroundColor: DARKTHEME_GREY_1} : {backgroundColor: WHITE_COLOR} : {backgroundColor: WHITE_COLOR}),
        ...style}}>
        <input type="checkbox"
          id={name}
          checked={object[name]}
          defaultChecked={customChecked}
          name={name}
          onChange={customOnChange ? customOnChange : (e)=>setObject((prev:any) => ({
            ...prev,
            [e.target.name]: e.target.checked,
          }))}/>
        <label htmlFor={name} style={{
          ...(me ? me.theme === THEMES.DARK ? {backgroundColor: DARKTHEME_GREY_1} : {backgroundColor: WHITE_COLOR} : {backgroundColor: WHITE_COLOR}),
          ...(round && {borderRadius: '50%'}), ...labelStyle}}></label>
      </div>
    </div>
  );
};

export default CheckBox;
