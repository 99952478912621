import axios, {Method} from 'axios';
import {baseUrl} from '../EnvironmentVariables/enviromentVariables';

interface IUseRequest {
    url: string;
    method: Method;
    body?: any;
  }

const makeUrl = ({url}: any) => {
  const baseUrlBackend = baseUrl();
  return baseUrlBackend + url;
};

const nonHookRequest = async ({url, method = 'GET', body}: IUseRequest) => {
  try {
    const token = localStorage.getItem('token');
    const config = {
      headers: {Authorization: `Bearer ${token}`},
    };
    const requestOptions = {method, data: body};
    const {data} = await axios(makeUrl({url}), {...requestOptions, ...(token? config : {})});
    return data;
  } catch (error) {
    console.warn('Error fetching - ', error);
    return;
  }
};

export default nonHookRequest;
