import React from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {THEMES} from '../../constants/types';
import {selectMe} from '../../features/stores/userSlicer';
import styles from './OutlinedIcon.module.scss';

type IconProps = {
  icon: string;
  style?: any;
  children?: any;
}

const OutlinedIcon = ({icon, style, children}:IconProps) =>{
  const me = selectMe(useAppSelector(store.getState)).me;
  return (<div className={styles.outlinedIcon} style={{...(me.theme === THEMES.DARK ?
  {borderColor: '#222222', backgroundColor: '#222222'} :
  {borderColor: '#e4eaf0'}), ...style}}>
    <img src={icon} />
    {children}
  </div>);
};

export default OutlinedIcon;
