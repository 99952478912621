import React from 'react';
import {useState} from 'react';
import style from './accordion.module.scss';
import redArrow from '../../assets/images/Group 901.svg';

interface IProps {
  icon: string | 'DEFAULT' | 'NONE';
  title?: string | any;
  children?: React.ReactNode;
  iconPosition: 'LEFT' | 'RIGHT';
  customStyle?: any;
}

const Accordion = (props: IProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={style.main}>
      <div className={`${style.header} ${props.iconPosition === 'RIGHT'? style.header__right : style.header__left}`}>
        <span style={props?.customStyle?.titleWidth ? props.customStyle.titleWidth : ''}>{props.title}</span>
        {props.icon !== 'DEFAULT' && props.icon !== 'NONE' && <span className={style.iconSpan}><img onClick={() => setOpen(!open)}
          src={props.icon}
          style={{...(open && {transform: 'rotate(180deg)'})}}
          className={`${style.icon} ${open && style.icon__open}`}/></span>}
        {props.icon === 'DEFAULT' && <img onClick={() => setOpen(!open)}
          src={redArrow}
          style={{...(!open && {transform: 'rotate(180deg)'})}}
          className={`${style.icon} ${open && style.icon__open}`}/>}
      </div>
      {open && <div className={style.body} style={props?.customStyle?.bodyStyle ? props.customStyle.bodyStyle : ''}>{props.children}</div>}
    </div>
  );
};

export default Accordion;
